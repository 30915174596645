import React, { useState, useEffect } from "react";
import axios from "axios";
import ImageUploadForm from "./Img/ImageUploadForm";
import { FaUpload } from 'react-icons/fa'; // Import FaUpload icon for upload button

const ImageUploader = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [error, setError] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [imageName, setImageName] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchType, setSearchType] = useState("");
  const [filteredImages, setFilteredImages] = useState([]);

  // Load image list on component mount
  useEffect(() => {
    fetchImages();
    fetchInstagramPosts();
  }, []);

  // Filter images when search criteria change
  useEffect(() => {
    const filtered = imageList.filter(image => {
      const nameMatch = image.nombre.toLowerCase().includes(searchName.toLowerCase());
      const typeMatch = searchType === "" || image.tipo === searchType;
      return nameMatch && typeMatch;
    });
    setFilteredImages(filtered);
  }, [searchName, searchType, imageList]);

  // Get image list
  const fetchImages = async () => {
    try {
      const response = await axios.get("/api/Imagenes/imagenes");
      setImageList(response.data);
      setFilteredImages(response.data);
    } catch (err) {
      console.error("Error loading images:", err);
    }
  };

  // Get Instagram posts
  const fetchInstagramPosts = async () => {
    try {
      const response = await axios.get("/api/InstagramPosts");
      console.log("Instagram Posts:", response.data);
    } catch (err) {
      console.error("Error loading Instagram posts:", err);
    }
  };

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadedUrl(null);
    setError(null);
  };

  // Handle name change
  const handleNameChange = (e) => {
    setImageName(e.target.value);
  };

  // Upload image
  const handleUpload = async () => {
    if (!file) {
      setError("Please select an image.");
      return;
    }

    if (!imageName) {
      setError("Please enter a name for the image.");
      return;
    }

    setUploading(true);
    setError(null);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("nombre", imageName);

    try {
      const response = await axios.post("/api/Imagenes/upload", formData, {
        params: {
          nombre: imageName
        },
        headers: { 
          "Content-Type": "multipart/form-data"
        },
      });
      setUploadedUrl(response.data.Url);
      setImageName(""); // Clear name after upload
      fetchImages(); // Update list after upload
    } catch (err) {
      setError("Error uploading image. Please try again.");
      console.error("Detailed error:", err);
    } finally {
      setUploading(false);
    }
  };

  // Function to refresh image list
  const refreshImageList = () => {
    fetchImages(); // Call the function that gets the image list
  };

  return (
    <div className="p-4 max-w-6xl mx-auto bg-white shadow-md rounded-lg">
      <ImageUploadForm onUploadSuccess={refreshImageList} /> {/* Pass the update function */}

      <div className="border rounded shadow-md p-4 mt-4">
        <h2 className="text-2xl font-bold mb-4">Uploaded Images</h2>
        
        <div className="mb-4 flex flex-col sm:flex-row gap-4">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            className="p-2 border rounded flex-1"
          />
          
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            className="p-2 border rounded"
          >
            <option value="">All types</option>
            <option value="Icon">Icon</option>
            <option value="Fondos">Background</option>
            <option value="Otros">Other</option>
          </select>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Preview</th>
                <th className="px-4 py-2">Actions</th>
                <th className="px-4 py-2">URL</th>
                <th className="px-4 py-2">Type</th>
                <th className="px-4 py-2">Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredImages.map((image) => (
                <tr key={image.idImagen} className="border-t">
                  <td className="px-4 py-2">{image.nombre}</td>
                  <td className="px-4 py-2 bg-slate-400">
                    <img
                      src={image?.urlImg}
                      alt={image?.nombre}
                      className="w-20 h-20 object-cover"
                    />
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => navigator.clipboard.writeText(image.urlImg)}
                      className="text-green-500 hover:text-green-700"
                    >
                      Copy URL
                    </button>
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type="text"
                      value={image.urlImg}
                      readOnly
                      className="w-full p-1 border rounded"
                    />
                  </td>
                  <td className="px-4 py-2">{image.tipo}</td>
                  <td className="px-4 py-2">{image.fecha}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
