import React, { useState, useEffect } from 'react'
import { SiAmazon } from 'react-icons/si'
import { FaShoppingBag } from 'react-icons/fa'
import AOS from 'aos'
import 'aos/dist/aos.css'

export const RegalosBodaComponent2 = ({datosSeccionRegalos, colores}) => {
  const [showBankDetails, setShowBankDetails] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out'
    })
  }, [])

  const fontFamilyStyle = {
    fontFamily: datosSeccionRegalos.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const textStyle = {
    ...fontFamilyStyle,
    color: datosSeccionRegalos.textColor || '#000000'
  };

  const backgroundStyle = {
    backgroundImage: `url(${datosSeccionRegalos.imgFondo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div className=" bg-[#e8e1d9] sm:h-auto flex items-center justify-center " style={{...backgroundStyle, backgroundColor: colores?.color1 || '#e8e1d9'}}>
      <div className="w-full max-w-md">
        <div className="relative w-full">
          
          <div className="relative z-10 pt-0 pb-6">
            <div 
              data-aos="fade-down"
              className="relative min-w-screen bg-[#b3a394] pt-8 pb-20 rounded-b-[100%] mb-12"
              style={{backgroundColor: colores?.color2 || '#b3a394'}}
            >
              <h1 className="text-4xl font-serif text-white text-center" style={textStyle}>Mesa de Regalos</h1>
            </div>
            
            {datosSeccionRegalos.descripcion && (
              <div 
                data-aos="fade-up"
                className="text-center px-10 mb-6"
              >
                <p className="" style={textStyle}>{datosSeccionRegalos.descripcion}</p>
              </div>
            )}

            <div className="space-y-6 px-10">
              {/* Amazon Section */}
              {datosSeccionRegalos.cantidadRegalos >= 1 && (
                <div 
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="text-center"
                >
                  <div className="w-20 h-20 mx-auto rounded-full border-4 border-[#c1b7ad] flex items-center justify-center mb-3" 
                    style={{backgroundColor: colores?.color1 || '#d5cbc1'}}
                  >
                    {datosSeccionRegalos.imgLink1 ? (
                      <img 
                        src={datosSeccionRegalos.imgLink1}
                        alt="Amazon"
                        className="w-10 h-10 object-contain"
                      />
                    ) : (
                      <SiAmazon className="text-4xl" style={textStyle} />
                    )}
                  </div>
                  {datosSeccionRegalos.link1Enable && (
                    <a 
                      href={datosSeccionRegalos.link1}
                    target="_blank"
                    rel="noopener noreferrer" 
                    className="block w-full py-2 px-4  rounded-md hover:bg-[#b3a99f] transition-colors"
                    style={{...textStyle, backgroundColor: colores?.color1 || '#d5cbc1'}}
                  >
                      Ir a Amazon
                    </a>
                  )}
                </div>
              )}

              {/* Liverpool Section */}
              {datosSeccionRegalos.cantidadRegalos >= 2 && (
                <div 
                  data-aos="fade-up"
                  data-aos-delay="400"
                  className="text-center"
                >
                  <div className="w-20 h-20 mx-auto rounded-full border-4 border-[#c1b7ad] flex items-center justify-center mb-3" 
                    style={{backgroundColor: colores?.color1 || '#d5cbc1'}}
                  >
                    {datosSeccionRegalos.imgLink2 ? (
                      <img 
                        src={datosSeccionRegalos.imgLink2}
                        alt="Liverpool"
                        className="w-10 h-10 object-contain"
                      />
                    ) : (
                      <FaShoppingBag className=" text-4xl" style={textStyle} />
                    )}
                  </div>  
                  {datosSeccionRegalos.link2Enable && (
                    <a
                      href={datosSeccionRegalos.link2}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full py-2 px-4  rounded-md hover:bg-[#b3a99f] transition-colors"
                    style={{...textStyle, backgroundColor: colores?.color1 || '#d5cbc1'}}
                  >
                    Ir a Liverpool
                    </a>
                  )}
                </div>
              )}

              {/* Banking Details Section */}
              {datosSeccionRegalos.cantidadRegalos >= 3 && (
                <div 
                  data-aos="fade-up"
                  data-aos-delay="600"
                  className="text-center"
                >
                  <div className="w-20 h-20 mx-auto rounded-full border-4 border-[#c1b7ad] flex items-center justify-center mb-3" 
                    style={{backgroundColor: colores?.color1 || '#d5cbc1'}}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" style={textStyle} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                    </svg>
                  </div>
                  <div>
                    {!showBankDetails ? (
                      <button 
                        onClick={() => setShowBankDetails(true)}
                        className="w-full py-2 px-4  rounded-md hover:bg-[#b3a99f] transition-colors"
                        style={{...textStyle, backgroundColor: colores?.color1 || '#d5cbc1'}}
                      >
                        Ver datos Bancarios
                      </button>
                    ) : (
                      <div 
                        onClick={() => setShowBankDetails(false)}
                        className="w-full py-2 px-4 text-[#8b7355] rounded-md cursor-pointer"
                        style={{...textStyle, backgroundColor: colores?.color1 || '#d5cbc1'}}
                      >
                        <p className="text-sm">
                          {datosSeccionRegalos.link3}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
