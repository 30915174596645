import React, { useEffect } from "react";
import { FaInstagram } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';

export const InstagramBodaComponent2 = ({datosSeccionInstagram}) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out'
    });
  }, []);

  const fontFamilyStyle = {
    fontFamily: datosSeccionInstagram?.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const renderFormattedText = (text) => {
    if (!text) return null;
    return text.split("\\n").map((linea, index) => (
      <React.Fragment key={index}>
        {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
          parte.startsWith("/") && parte.endsWith("/") ? (
            <strong key={subIndex}>{parte.slice(1, -1)}</strong>
          ) : (
            <span key={subIndex}>{parte}</span>
          )
        ))}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="min-h-screen relative flex items-center justify-center p-4">
      <div className="absolute inset-0">
        <img
          src={datosSeccionInstagram?.imgFondo || "https://img.freepik.com/foto-gratis/retrato-feliz-pareja-casada-guapo-novio-elegante-traje-gris-abrazando-novia-vestido-novia-ramo-mientras-posa-jardin-ceremonia-dia-boda_8353-11963.jpg"}
          alt="Wedding background"
          className="w-full h-full object-cover"
        />
      </div>
      <div 
        data-aos="fade-up"
        className="bg-[#4f4f4f3b] backdrop-blur-md text-white text-center p-6 rounded-lg shadow-lg w-full max-w-md relative z-10"
        style={fontFamilyStyle}
      >
        {/* Título */}
        <h2 
          data-aos="fade-down"
          data-aos-delay="200"
          className="text-2xl font-bold mb-4"
          style={fontFamilyStyle}
        >
          {renderFormattedText(datosSeccionInstagram?.titulo) || "Comparte con nosotros"}
        </h2>
        
        {/* Icono de Instagram */}
        <div 
          data-aos="zoom-in"
          data-aos-delay="400"
          className="flex justify-center items-center mb-6"
        >
          <FaInstagram className="w-16 h-16 text-white" />
        </div>

        {/* Hashtag */}
        <h3 
          data-aos="fade-up"
          data-aos-delay="600"
          className="text-2xl font-semibold mb-4"
          style={fontFamilyStyle}
        >
          {renderFormattedText(datosSeccionInstagram?.hashtag) || "#Emma&Mateo"}
        </h3>

        {/* Descripción */}
        <p 
          data-aos="fade-up"
          data-aos-delay="800"
          className="text-sm leading-relaxed"
          style={fontFamilyStyle}
        >
          {renderFormattedText(datosSeccionInstagram?.descripcion) || "Comparte con nosotros todas tus fotografías del evento, usando el siguiente #hashtag en todas tus publicaciones de Facebook e Instagram."}
        </p>
      </div>
    </div>
  );
};
