import React from 'react';
import { Input, Label } from "reactstrap";
import { SelectIcons } from "../../../../../components/functions/SelectIcons";

export const ProgramForm = ({ values, handleChange, handleInputChange, errors, touched }) => {
  const sections = [
    {
      id: 1,
      bg: "bg-gray-100",
      title: "Sección 1",
      iconName: "PceremoniaReligiosaIcon",
      titleName: "PceremoniaReligiosaTitulo",
      descName: "PceremoniaReligiosa"
    },
    {
      id: 2,
      bg: "bg-blue-50",
      title: "Sección 2",
      iconName: "PrecepcionIcon",
      titleName: "PrecepcionTitulo",
      descName: "Precepcion"
    },
    {
      id: 3,
      bg: "bg-green-50",
      title: "Sección 3",
      iconName: "PcivilIcon",
      titleName: "PcivilTitulo",
      descName: "Pcivil"
    },
    {
      id: 4,
      bg: "bg-yellow-50",
      title: "Sección 4",
      iconName: "PcenaIcon",
      titleName: "PcenaTitulo",
      descName: "Pcena"
    },
    {
      id: 5,
      bg: "bg-pink-50",
      title: "Sección 5",
      iconName: "PcoctelIcon",
      titleName: "PcoctelTitulo",
      descName: "Pcoctel"
    },
    {
      id: 6,
      bg: "bg-purple-50",
      title: "Sección 6",
      iconName: "PbaileIcon",
      titleName: "PbaileTitulo",
      descName: "Pbaile"
    }
  ];

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-8 max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">PROGRAMA</h2>
      <div className="grid grid-cols-1 gap-8">
        {sections.map(section => (
          <div key={section.id} className={`${section.bg} p-6 rounded-lg shadow-md`}>
            <h3 className="text-xl font-semibold mb-4">{section.title}</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <SelectIcons
                  label={`Selecciona un icono para la ${section.title.toLowerCase()}`}
                  name={section.iconName}
                  onChange={handleChange}
                  value={values[section.iconName]}
                  className={`w-full ${errors[section.iconName] ? "border-red-500" : "border-gray-300"}`}
                />
              </div>
              <div>
                <Label htmlFor={section.titleName} className="block text-sm font-medium text-gray-700 mb-1">
                  Título
                </Label>
                <Input
                  id={section.titleName}
                  type="text"
                  name={section.titleName}
                  onChange={(e) => { handleChange(e); handleInputChange(e); }}
                  value={values[section.titleName]}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors[section.titleName] ? "border-red-500" : ""}`}
                />
                {touched[section.titleName] && errors[section.titleName] && (
                  <p className="mt-2 text-sm text-red-600">{errors[section.titleName]}</p>
                )}
              </div>
              <div>
                <Label htmlFor={section.descName} className="block text-sm font-medium text-gray-700 mb-1">
                  Descripción
                </Label>
                <textarea
                  id={section.descName}
                  name={section.descName}
                  rows="4"
                  onChange={(e) => { handleChange(e); handleInputChange(e); }}
                  value={values[section.descName]}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors[section.descName] ? "border-red-500" : ""}`}
                />
                {touched[section.descName] && errors[section.descName] && (
                  <p className="mt-2 text-sm text-red-600">{errors[section.descName]}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}; 

export const ProgramInvForm = ({ values, handleChange, handleInputChange, errors, touched }) => {
  const sections = [
    {
      id: 1,
      bg: "bg-gray-100",
      title: "Sección 1",
      iconName: "PceremoniaReligiosaIcon",
      titleName: "PceremoniaReligiosaTitulo",
      descName: "PceremoniaReligiosa"
    },
    {
      id: 2,
      bg: "bg-blue-50",
      title: "Sección 2",
      iconName: "PrecepcionIcon",
      titleName: "PrecepcionTitulo",
      descName: "Precepcion"
    },
    {
      id: 3,
      bg: "bg-green-50",
      title: "Sección 3",
      iconName: "PbaileXvIcon",
      titleName: "PbaileXvTitulo",
      descName: "PbaileXv"
    },
    {
      id: 4,
      bg: "bg-yellow-50",
      title: "Sección 4",
      iconName: "PcenaIcon",
      titleName: "PcenaTitulo",
      descName: "Pcena"
    },
    {
      id: 5,
      bg: "bg-pink-50",
      title: "Sección 5",
      iconName: "PcoctelIcon",
      titleName: "PcoctelTitulo",
      descName: "Pcoctel"
    },
    {
      id: 6,
      bg: "bg-purple-50",
      title: "Sección 6",
      iconName: "PbaileIcon",
      titleName: "PbaileTitulo",
      descName: "Pbaile"
    }
  ];

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-8 max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">PROGRAMA</h2>
      <div className="grid grid-cols-1 gap-8">
        {sections.map(section => (
          <div key={section.id} className={`${section.bg} p-6 rounded-lg shadow-md`}>
            <h3 className="text-xl font-semibold mb-4">{section.title}</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <SelectIcons
                  label={`Selecciona un icono para la ${section.title.toLowerCase()}`}
                  name={section.iconName}
                  onChange={handleChange}
                  value={values[section.iconName]}
                  className={`w-full ${errors[section.iconName] ? "border-red-500" : "border-gray-300"}`}
                />
              </div>
              <div>
                <Label htmlFor={section.titleName} className="block text-sm font-medium text-gray-700 mb-1">
                  Título
                </Label>
                <Input
                  id={section.titleName}
                  type="text"
                  name={section.titleName}
                  onChange={(e) => { handleChange(e); handleInputChange(e); }}
                  value={values[section.titleName]}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors[section.titleName] ? "border-red-500" : ""}`}
                />
                {touched[section.titleName] && errors[section.titleName] && (
                  <p className="mt-2 text-sm text-red-600">{errors[section.titleName]}</p>
                )}
              </div>
              <div>
                <Label htmlFor={section.descName} className="block text-sm font-medium text-gray-700 mb-1">
                  Descripción
                </Label>
                <textarea
                  id={section.descName}
                  name={section.descName}
                  rows="4"
                  onChange={(e) => { handleChange(e); handleInputChange(e); }}
                  value={values[section.descName]}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors[section.descName] ? "border-red-500" : ""}`}
                />
                {touched[section.descName] && errors[section.descName] && (
                  <p className="mt-2 text-sm text-red-600">{errors[section.descName]}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}; 