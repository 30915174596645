'use client'

import React, { useState, useEffect, useRef } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { FaChurch, FaGavel, FaGlassCheers, FaGlassMartiniAlt, FaUtensils, FaMusic } from 'react-icons/fa'

export const ProgramaXvComponent2 = ({ datosSeccionPrograma, colores }) => {
    const [scrollPosition, setScrollPosition] = useState(0)
    const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]

    const fontFamilyStyle = {
        fontFamily: datosSeccionPrograma?.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
        color: datosSeccionPrograma?.textColor || '#000000'
    };

    const renderFormattedText = (text) => {
        if (!text) return null;
        return text.split("\\n").map((linea, index) => (
          <React.Fragment key={index}>
            {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
              parte.startsWith("/") && parte.endsWith("/") ? (
                <strong key={subIndex}>{parte.slice(1, -1)}</strong>
              ) : (
                <span key={subIndex}>{parte}</span>
              )
            ))}
            <br />
          </React.Fragment>
        ));
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY)
        }

        window.addEventListener('scroll', handleScroll, { passive: true })

        AOS.init({
            duration: 1000,
            once: true,
            easing: 'ease-out'
        })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const getColor = (start, end, progress) => {
        const startRGB = parseInt(start.slice(1), 16)
        const endRGB = parseInt(end.slice(1), 16)
        const r = Math.round(((endRGB >> 16) - (startRGB >> 16)) * progress + (startRGB >> 16)).toString(16).padStart(2, '0')
        const g = Math.round((((endRGB >> 8) & 0xff) - ((startRGB >> 8) & 0xff)) * progress + ((startRGB >> 8) & 0xff)).toString(16).padStart(2, '0')
        const b = Math.round(((endRGB & 0xff) - (startRGB & 0xff)) * progress + (startRGB & 0xff)).toString(16).padStart(2, '0')
        return `#${r}${g}${b}`
    }

    const getSectionColor = (sectionRef) => {
        if (sectionRef.current) {
            const rect = sectionRef.current.getBoundingClientRect()
            const sectionTop = rect.top
            const sectionHeight = rect.height
            const viewportHeight = window.innerHeight

            if (sectionTop < viewportHeight && sectionTop > -sectionHeight) {
                const progress = 1 - (sectionTop + sectionHeight) / (viewportHeight + sectionHeight)
                return getColor('#FFFFFF', datosSeccionPrograma?.textColor || '#000000', Math.min(1, Math.max(0, progress)))
            }
        }
        return '#FFFFFF'
    }

    const iconBackgroundStyle = {
        backgroundColor: '#F5F5F5' // Gris claro
    }

    return (
        <div className="relative min-h-screen w-full overflow-hidden"
        style={{backgroundColor: colores?.color1 || '#C2B6AA'}}
        >
            {/* Background Image */}
            {datosSeccionPrograma?.imgFondo && (
                <div className="absolute inset-0">
                    <img
                        src={datosSeccionPrograma.imgFondo}
                        alt="Background"
                        className="h-full w-full object-cover"
                    />
                </div>
            )}
            {/* Content Container */}
            <div className="relative w-full min-h-screen rounded-b-[50%]">
                {/* Content */}
                <div className="pt-12 px-8 text-center">
                    <h1
                        data-aos="fade-down"
                        style={fontFamilyStyle}
                        className="text-4xl mb-10"
                    >
                        {renderFormattedText(datosSeccionPrograma?.titulo || "Itinerario")}
                    </h1>

                    <div className="max-w-xs mx-auto space-y-8 pb-16">
                        {/* Timeline */}
                        <div className="relative">
                            {/* Vertical Line */}
                            <div className="absolute left-1/2 top-0 h-full w-px -translate-x-1/2" style={{backgroundColor: datosSeccionPrograma?.textColor || '#000000', opacity: 0.4}} />

                            {/* Ceremonia Religiosa */}
                            {datosSeccionPrograma?.ceremoniaReligiosa && (
                                <div
                                    data-aos="fade-right"
                                    ref={sectionRefs[0]}
                                    className="relative flex items-center justify-between mb-8"
                                    style={{ color: getSectionColor(sectionRefs[0]) }}
                                >
                                    <div className="text-left w-24">
                                        <p style={fontFamilyStyle} className="text-xl">{renderFormattedText(datosSeccionPrograma?.ceremoniaReligiosaTitulo)}</p>
                                        <p style={fontFamilyStyle} className="text-lg">{renderFormattedText(datosSeccionPrograma?.ceremoniaReligiosa)}</p>
                                    </div>
                                    <div className="w-12 h-12 rounded-full flex items-center justify-center relative z-10" style={iconBackgroundStyle}>
                                        {datosSeccionPrograma?.ceremoniaReligiosaImg ? (
                                            <img
                                                src={datosSeccionPrograma?.ceremoniaReligiosaImg}
                                                alt="Ceremonia Religiosa"
                                                className="w-6 h-6 object-cover"
                                            />
                                        ) : (
                                            <FaChurch className="w-6 h-6" />
                                        )}
                                    </div>
                                    <div className="w-24" />
                                </div>
                            )}

                            {/* Civil */}
                            {datosSeccionPrograma?.baileXv && (
                                <div
                                    data-aos="fade-left"
                                    ref={sectionRefs[1]}
                                    className="relative flex items-center justify-between mb-8"
                                    style={{ color: getSectionColor(sectionRefs[1]) }}
                                >
                                    <div className="w-24" />
                                    <div className="w-12 h-12 rounded-full flex items-center justify-center relative z-10" style={iconBackgroundStyle}>
                                        {datosSeccionPrograma?.baileXvImg ? (
                                            <img
                                                src={datosSeccionPrograma?.baileXvImg}
                                                alt="baileXv"
                                                className="w-6 h-6 object-cover"
                                            />
                                        ) : (
                                            <FaGavel className="w-6 h-6" />
                                        )}
                                    </div>
                                    <div className="text-right w-24">
                                        <p style={fontFamilyStyle} className="text-xl">{renderFormattedText(datosSeccionPrograma?.baileXvTitulo)}</p>
                                        <p style={fontFamilyStyle} className="text-lg">{renderFormattedText(datosSeccionPrograma?.baileXv)}</p>
                                    </div>
                                </div>
                            )}

                            {/* Recepción */}
                            {datosSeccionPrograma?.recepcion && (
                                <div
                                    data-aos="fade-right"
                                    ref={sectionRefs[2]}
                                    className="relative flex items-center justify-between mb-8"
                                    style={{ color: getSectionColor(sectionRefs[2]) }}
                                >
                                    <div className="text-left w-24">
                                        <p style={fontFamilyStyle} className="text-xl">{renderFormattedText(datosSeccionPrograma?.recepcionTitulo)}</p>
                                        <p style={fontFamilyStyle} className="text-lg">{renderFormattedText(datosSeccionPrograma?.recepcion)}</p>
                                    </div>
                                    <div className="w-12 h-12 rounded-full flex items-center justify-center relative z-10" style={iconBackgroundStyle}>
                                        {datosSeccionPrograma?.recepcionImg ? (
                                            <img
                                                src={datosSeccionPrograma?.recepcionImg}
                                                alt="Recepción"
                                                className="w-6 h-6 object-cover"
                                            />
                                        ) : (
                                            <FaGlassCheers className="w-6 h-6" />
                                        )}
                                    </div>
                                    <div className="w-24" />
                                </div>
                            )}

                            {/* Coctel */}
                            {datosSeccionPrograma?.coctel && (
                                <div
                                    data-aos="fade-left"
                                    ref={sectionRefs[3]}
                                    className="relative flex items-center justify-between mb-8"
                                    style={{ color: getSectionColor(sectionRefs[3]) }}
                                >
                                    <div className="w-24" />
                                    <div className="w-12 h-12 rounded-full flex items-center justify-center relative z-10" style={iconBackgroundStyle}>
                                        {datosSeccionPrograma?.coctelImg ? (
                                            <img
                                                src={datosSeccionPrograma?.coctelImg}
                                                alt="Coctel"
                                                className="w-6 h-6 object-cover"
                                            />
                                        ) : (
                                            <FaGlassMartiniAlt className="w-6 h-6" />
                                        )}
                                    </div>
                                    <div className="text-right w-24">
                                        <p style={fontFamilyStyle} className="text-xl">{renderFormattedText(datosSeccionPrograma?.coctelTitulo)}</p>
                                        <p style={fontFamilyStyle} className="text-lg">{renderFormattedText(datosSeccionPrograma?.coctel)}</p>
                                    </div>
                                </div>
                            )}

                            {/* Cena */}
                            {datosSeccionPrograma?.cena && (
                                <div
                                    data-aos="fade-right"
                                    ref={sectionRefs[4]}
                                    className="relative flex items-center justify-between mb-8"
                                    style={{ color: getSectionColor(sectionRefs[4]) }}
                                >
                                    <div className="text-left w-24">
                                        <p style={fontFamilyStyle} className="text-xl">{renderFormattedText(datosSeccionPrograma?.cenaTitulo)}</p>
                                        <p style={fontFamilyStyle} className="text-lg">{renderFormattedText(datosSeccionPrograma?.cena)}</p>
                                    </div>
                                    <div className="w-12 h-12 rounded-full flex items-center justify-center relative z-10" style={iconBackgroundStyle}>
                                        {datosSeccionPrograma?.cenaImg ? (
                                            <img
                                                src={datosSeccionPrograma?.cenaImg}
                                                alt="Cena"
                                                className="w-6 h-6 object-cover"
                                            />
                                        ) : (
                                            <FaUtensils className="w-6 h-6" />
                                        )}
                                    </div>
                                    <div className="w-24" />
                                </div>
                            )}

                            {/* Baile */}
                            {datosSeccionPrograma?.baile && (
                                <div
                                    data-aos="fade-left"
                                    ref={sectionRefs[5]}
                                    className="relative flex items-center justify-between"
                                    style={{ color: getSectionColor(sectionRefs[5]) }}
                                >
                                    <div className="w-24" />
                                    <div className="w-12 h-12 rounded-full flex items-center justify-center relative z-10" style={iconBackgroundStyle}>
                                        {datosSeccionPrograma?.baileImg ? (
                                            <img
                                                src={datosSeccionPrograma?.baileImg}
                                                alt="Baile"
                                                className="w-6 h-6 object-cover"
                                            />
                                        ) : (
                                            <FaMusic className="w-6 h-6" />
                                        )}
                                    </div>
                                    <div className="text-right w-24">
                                        <p style={fontFamilyStyle} className="text-xl">{renderFormattedText(datosSeccionPrograma?.baileTitulo)}</p>
                                        <p style={fontFamilyStyle} className="text-lg">{renderFormattedText(datosSeccionPrograma?.baile)}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}