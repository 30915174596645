import React from 'react';
import { Input, Label } from "reactstrap";

export const ContactAndFamilyInfoForm = ({ values, handleChange, handleInputChange, errors, touched }) => {
  const familyFields = [
    { label: "Nombre de la madre de la Novia", name: "D3esposaMadre" },
    { label: "Nombre del padre de la Novia", name: "D3esposaPadre" },
    { label: "Nombre de la madre del Novio", name: "D3esposoMadre" },
    { label: "Nombre del padre del Novio", name: "D3esposoPadre" },
    { label: "Nombre de la Madrina", name: "madrina" },
    { label: "Nombre del Padrino", name: "padrino" }
  ];

  return (
    <>
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold text-center mb-6">
          INFORMACIÓN DE CONTACTO
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <Label htmlFor="D1numeroNovia" className="block text-sm font-medium text-gray-700 mb-1">Número Novia</Label>
            <Input
              id="D1numeroNovia"
              type="text"
              name="D1numeroNovia"
              onChange={(e) => { handleChange(e); handleInputChange(e); }}
              value={values.D1numeroNovia}
              className={`w-full rounded-md ${errors.D1numeroNovia ? "border-red-500" : "border-gray-300"}`}
            />
            {touched.D1numeroNovia && errors.D1numeroNovia && (
              <p className="mt-2 text-sm text-red-600">{errors.D1numeroNovia}</p>
            )}
          </div>
          <div>
            <Label htmlFor="D1numeroNovio" className="block text-sm font-medium text-gray-700 mb-1">Número Novio</Label>
            <Input
              id="D1numeroNovio"
              type="text"
              name="D1numeroNovio"
              onChange={(e) => { handleChange(e); handleInputChange(e); }}
              value={values.D1numeroNovio}
              className={`w-full rounded-md ${errors.D1numeroNovio ? "border-red-500" : "border-gray-300"}`}
            />
            {touched.D1numeroNovio && errors.D1numeroNovio && (
              <p className="mt-2 text-sm text-red-600">{errors.D1numeroNovio}</p>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold text-center mb-6">
          INFORMACIÓN FAMILIAR
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {familyFields.map((field) => (
            <div key={field.name}>
              <Label htmlFor={field.name} className="block text-sm font-medium text-gray-700 mb-1">{field.label}</Label>
              <Input
                id={field.name}
                type="text"
                name={field.name}
                onChange={(e) => { handleChange(e); handleInputChange(e); }}
                value={values[field.name]}
                className={`w-full rounded-md ${errors[field.name] ? "border-red-500" : "border-gray-300"}`}
              />
              {touched[field.name] && errors[field.name] && (
                <p className="mt-2 text-sm text-red-600">{errors[field.name]}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}; 

export const ContactAndFamilyInfoInvForm = ({ values, handleChange, handleInputChange, errors, touched }) => {
  const familyFields = [
    { label: "Nombre de la madre del festejado/a", name: "D3QuinceañeraMadre" },
    { label: "Nombre del padre del festejado/a", name: "D3QuinceañeraPadre" },
    { label: "Nombre de la Madrina del festejado/a", name: "D3QuinceañeraMadrina" },
    { label: "Nombre del Padrino del festejado/a", name: "D3QuinceañeraPadrino" },
    { label: "Nombre de la Segunda Madrina", name: "madrina" },
    { label: "Nombre del Segundo Padrino", name: "padrino" }
  ];

  return (
    <>
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold text-center mb-6">
          INFORMACIÓN DE CONTACTO
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <Label htmlFor="D1numeroWiddingAdmin" className="block text-sm font-medium text-gray-700 mb-1">Número donde llegaran las confirmaciones</Label>
            <Input
              id="D1numeroWiddingAdmin"
              type="text"
              name="D1numeroWiddingAdmin"
              onChange={(e) => { handleChange(e); handleInputChange(e); }}
              value={values.D1numeroWiddingAdmin}
              className={`w-full rounded-md ${errors.D1numeroWiddingAdmin ? "border-red-500" : "border-gray-300"}`}
            />
            {touched.D1numeroWiddingAdmin && errors.D1numeroWiddingAdmin && (
              <p className="mt-2 text-sm text-red-600">{errors.D1numeroWiddingAdmin}</p>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold text-center mb-6">
          INFORMACIÓN FAMILIAR
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {familyFields.map((field) => (
            <div key={field.name}>
              <Label htmlFor={field.name} className="block text-sm font-medium text-gray-700 mb-1">{field.label}</Label>
              <Input
                id={field.name}
                type="text"
                name={field.name}
                onChange={(e) => { handleChange(e); handleInputChange(e); }}
                value={values[field.name]}
                className={`w-full rounded-md ${errors[field.name] ? "border-red-500" : "border-gray-300"}`}
              />
              {touched[field.name] && errors[field.name] && (
                <p className="mt-2 text-sm text-red-600">{errors[field.name]}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}; 