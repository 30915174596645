import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Input, Label } from "reactstrap";
import { useLocation } from "react-router-dom";
import { Vestimenta } from "../../../components/Vestimenta";
import axios from "axios";
import toast from "react-hot-toast";
import { ImageFormComponent } from "./Images/ImageFormComponent";
import { CarouselComponent } from "./Images/CarouselComponent";
import { ImageFondo } from "./Images/ImageFondo";
import ModalFormularioPre from "./ModalFormularioPre";
import { LoadingComponent } from "../../../components/LoadingComponent";
import SelectTipoLetra from "../../../components/functions/SelectTipoLetra";
import { SelectIcons } from "../../../components/functions/SelectIcons";
import { SelectSobre } from "../../../components/functions/SelectSobre";
import Swal from "sweetalert2";

export const FormularioBodaAuto = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idEvento = searchParams.get("id");
  const [dataEvento, setDataEvento] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [isAlreadySet, setAlreadySet] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Obtener los datos actuales del localStorage o crear un nuevo objeto si no existen
    const formData = JSON.parse(localStorage.getItem("formData")) || {};

    // Actualizar el campo correspondiente en el objeto
    formData[name] = value;

    // Guardar el objeto actualizado en el localStorage
    localStorage.setItem("formData", JSON.stringify(formData));
  };


  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/Eventos/${idEvento}`);
      let data = response.data;
      setDataEvento({ ...data });
      if (data.enablePortada == true || data.enablePortadaBoda == true) {
        setAlreadySet(true);
      }
      console.log(response.data);
      console.log(dataEvento);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Algo salio mal, intenta de nuevo");
    }
  };

  const capitalizeWords = (str) => {
    const exceptions = new Set(["del", "de", "la", "y"]);
    return str
      .toLocaleLowerCase("es")
      .replace(/(?:^|\s)\p{L}/gu, (match) => match.toLocaleUpperCase("es"))
      .split(" ")
      .map((word) =>
        exceptions.has(word.toLowerCase()) ? word.toLowerCase() : word
      )
      .join(" ");
  };

   // Obtener formData de localStorage si existe
   const savedFormData = JSON.parse(localStorage.getItem("formData")) || {};

  const initialValues = {
    cancion: savedFormData.cancion || "",
    color1: savedFormData.color1 || "#e7e3e3",
    color2: savedFormData.color2 || "#f8f2f2",
    D1esposo: savedFormData.D1esposo || "",
    D1esposa: savedFormData.D1esposa || "",
    D1fechaDeBoda: savedFormData.D1fechaDeBoda || "",
    D1numeroNovia: savedFormData.D1numeroNovia || "",
    D1numeroNovio: savedFormData.D1numeroNovio || "",
    D1numeroWiddingAdmin: savedFormData.D1numeroWiddingAdmin || "",
    D1compradorInv: savedFormData.D1compradorInv || "",
    D2descipcion: savedFormData.D2descipcion ||
      "Después  savedFormData.Después ||de 4 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
    D3esposaMadre: savedFormData.D3esposaMadre || "",
    D3esposaPadre: savedFormData.D3esposaPadre || "",
    D3esposoMadre: savedFormData.D3esposoMadre || "",
    D3esposoPadre: savedFormData.D3esposoPadre || "",
    madrina: savedFormData.madrina || "",
    padrino: savedFormData.padrino || "",
    D4ceremoniaFecha: savedFormData.D4ceremoniaFecha || "",
    D4ceremoniaNombreLugar: savedFormData.D4ceremoniaNombreLugar || "",
    D4ceremoniaUrl: savedFormData.D4ceremoniaUrl || "",
    D4recepcionFecha: savedFormData.D4recepcionFecha || "",
    D4recepcionNombreLugar: savedFormData.D4recepcionNombreLugar || "",
    D4recepcionUrl: savedFormData.D4recepcionUrl || "",
    // D5ceremonia: "",
    // D5ceremoniaCivil: "",
    // D5cena: "",
    // D5recepcion: "",
    // D5coctel: "",
    // D5baile: "",
    D7hotel1lugar:savedFormData.D7hotel1lugar || "",
    D7hotel1url:savedFormData.D7hotel1url || "",
    D7hotel2lugar:savedFormData.D7hotel2lugar || "",
    D7hotel2url:savedFormData.D7hotel2url || "",
    D8linkRegalo1:savedFormData.D8linkRegalo1 || "",
    D8linkRegalo2:savedFormData.D8linkRegalo2 || "",
    D8linkRegalo3:savedFormData.D8linkRegalo3 || "",
    D8linkRegalo4:savedFormData.D8linkRegalo4 || "",
    Vestimenta:savedFormData.Vestimenta || "",
    VestimentaAviso:savedFormData.VestimentaAviso || "",
    Detalles:savedFormData.Detalles || "",
    PceremoniaReligiosaIcon:savedFormData.PceremoniaReligiosaIcon || "",
    PceremoniaReligiosa:savedFormData.PceremoniaReligiosa || "Acompáñanos a recibir la bendición de Dios",
    PceremoniaReligiosaTitulo:savedFormData.PceremoniaReligiosaTitulo || "CEREMONIA RELIGIOSA",
    PrecepcionIcon:savedFormData.PrecepcionIcon || "",
    Precepcion:savedFormData.Precepcion || "Tómate fotos con nosotros",
    PrecepcionTitulo:savedFormData.PrecepcionTitulo || "RECEPCIÓN",
    PcivilIcon:savedFormData.PcivilIcon || "",
    Pcivil:savedFormData.Pcivil || "No te pierdas nuestro intercambio de votos",
    PcivilTitulo:savedFormData.PcivilTitulo || "CEREMONIA CIVIL",
    PcenaIcon:savedFormData.PcenaIcon || "",
    Pcena:savedFormData.Pcena || "Disfruta de una deliciosa comida",
    PcenaTitulo:savedFormData.PcenaTitulo || "COMIDA",
    PcoctelIcon:savedFormData.PcoctelIcon || "",
    Pcoctel:savedFormData.Pcoctel || "Disfruta de un refrescante cóctel",
    PcoctelTitulo:savedFormData.PcoctelTitulo || "COCTEL",
    PbaileIcon:savedFormData.PbaileIcon || "",
    Pbaile:savedFormData.Pbaile || "Sacale brillo a la pista",
    PbaileTitulo:savedFormData.PbaileTitulo || "BAILE",
    imgFondo:savedFormData.imgFondo || "",
    tipoLetra:savedFormData.tipoLetra || "",
    mail:savedFormData.mail || "",
    sobre:savedFormData.sobre || 0,
  };

  const onSubmit = async (values) => {
    const confirmed = window.confirm(
      "Solo puedes enviar este formulario una vez. ¿Deseas continuar?"
    );

    if (!confirmed) {
      console.log("Envío del formulario cancelado");
      return;
    }
    toast.loading(`Enviando Formulario...`);
    setLoadingSend(true);
    console.log("Datos del formulario:", values);
    // Puedes agregar lógica para enviar los datos a través de un servicio, como emailjs.
    const portada = {
      textColor: values.textColor,
      cancion: values.cancion,
      esposo: capitalizeWords(values.D1esposo),
      esposa: capitalizeWords(values.D1esposa),
      fechaDeBoda: values.D1fechaDeBoda,
      imgPortada: "",
      titulo: "NO FALTES A NUESTRA BODA",
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
      sobre: values.sobre
    };

    const contador = {
      titulo: "¡Nos Casamos!",
      descripcion: values.D2descipcion,
      fechaNewDate:
        values.D1fechaDeBoda + " = " + "December 12, 2024 00:00:00 GMT+00:00",
      fotoIzquierda: "",
      fotoDerecha: "",
      marcoEnable: true,
      textColor: null,
      imgFondo: values.imgFondo,
      contador: true,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    let cantidad;

    if (values.D3esposaMadre || values.D3esposaPadre) {
      cantidad = 1;

      if (values.D3esposoMadre || values.D3esposoPadre) {
        cantidad = 2;

        if (values.madrina || values.padrino) {
          cantidad = 3;
        } else {
          cantidad = 2; // O cualquier valor predeterminado si no se cumple ninguno de los casos
        }
      } else {
        cantidad = 1; // O cualquier valor predeterminado si no se cumple ninguno de los casos
      }
    } else {
      cantidad = 1; // O cualquier valor predeterminado si no se cumple ninguno de los casos
    }

    const padres = {
      cantidad: cantidad,
      titulo: "¡Celebra con nosotros este día tan maravilloso!",
      descripcion: null,
      titulo1: "Padres de la Novia",
      madre: capitalizeWords(values.D3esposaMadre),
      padre: capitalizeWords(values.D3esposaPadre),
      titulo2: "Padres del Novio",
      madrina: capitalizeWords(values.D3esposoMadre),
      padrino: capitalizeWords(values.D3esposoPadre),
      titulo3: "Padrinos",
      madrina2: capitalizeWords(values.madrina),
      padrino2: capitalizeWords(values.padrino),
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    const ceremonia = {
      dobleEnable: false,
      ceremoniaImg: "",
      ceremoniaTitulo: "",
      ceremoniaEnable: values.D4ceremoniaFecha ? true : false,
      ceremoniaFecha: "Sábado, 25 de Mayo de 2024.",
      ceremoniaHora: values.D4ceremoniaFecha,
      ceremoniaNombreLugar: capitalizeWords(values.D4ceremoniaNombreLugar),
      ceremoniaLugarMap: "",
      ceremoniaUrl: values.D4ceremoniaUrl,
      recepcionEnable: values.D4recepcionFecha ? true : false,
      recepcionImg: "",
      recepcionTitulo: "",
      recepcionFecha: "Sábado, 25 de Mayo de 2024.",
      recepcionHora: values.D4recepcionFecha,
      recepcionNombreLugar: capitalizeWords(values.D4recepcionNombreLugar),
      recepcionLugarMap: "",
      recepcionUrl: values.D4recepcionUrl,
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    const vestimenta = {
      vestimenta: values.Vestimenta,
      messageVestimenta: values.VestimentaAviso,
      urlVestimentaMujer: null,
      urlVestimentaHombre: null,
      imgFondo:
        "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
      textHombre: "",
      textMujer: "",
      colores: "",
    };

    const confirmacion = {
      tituloConfirmacion:
        "Espero puedan confirmarnos su asistencia. \\n Agradecemos de antemano su apreciable compañía.",
      aviso: values.Detalles,
      numeroConfirmacion: `+52${values.D1numeroNovia} ${" "}${values.D1numeroWiddingAdmin
        }`,
      textoConfirmacion: "a la Boda",
      textWhatsApp: null,
      textColor: null,
      imgFondo: values.imgFondo,
      numeroNovia: `+52${values.D1numeroNovia}`,
      numeroNovio: `+52${values.D1numeroNovio}`,
      enableNum: false,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
      confetti: false,
    };

    const programa = {
      ceremoniaReligiosa: values.PceremoniaReligiosa,
      ceremoniaReligiosaImg: values.PceremoniaReligiosaIcon,
      ceremoniaReligiosaTitulo: values.PceremoniaReligiosaTitulo,
      recepcion: values.Precepcion,
      recepcionImg: values.PrecepcionIcon,
      recepcionTitulo: values.PrecepcionTitulo,
      civil: values.Pcivil,
      civilImg: values.PcivilIcon,
      civilTitulo: values.PcivilTitulo,
      cena: values.Pcena,
      cenaImg: values.PcenaIcon,
      cenaTitulo: values.PcenaTitulo,
      coctel: values.Pcoctel,
      coctelImg: values.PcoctelIcon,
      coctelTitulo: values.PcoctelTitulo,
      baile: values.Pbaile,
      baileImg: values.PbaileIcon,
      baileTitulo: values.PbaileTitulo,
      titulo: "",
      descripcion: "",
      idEvento: idEvento,
      textColor: "",
      imgFondo: values.imgFondo,
      tipoLetra: values.tipoLetra,
    };

    let cantidadRegalos;

    if (values.link1) {
      cantidadRegalos = 1;

      if (values.D8linkRegalo2) {
        cantidadRegalos = 2;

        if (values.D8linkRegalo3) {
          cantidadRegalos = 3;
        } else {
          cantidadRegalos = 2; // O cualquier valor predeterminado si no se cumple ninguno de los casos
        }
      } else {
        cantidadRegalos = 1; // O cualquier valor predeterminado si no se cumple ninguno de los casos
      }
    } else {
      cantidadRegalos = 1; // O cualquier valor predeterminado si no se cumple ninguno de los casos
    }

    const regalos = {
      cantidadRegalos: (values.D8linkRegalo1 ? 1 : 0) + (values.D8linkRegalo2 ? 1 : 0) + (values.D8linkRegalo3 ? 1 : 0) || 1,
      titulo: "Mesa de Regalos",
      descripcion:
        "Su presencia en mi fiesta de quince años es el mejor regalo que puedo recibir. Sin embargo, si desean hacerme un obsequio adicional, les agradezco de antemano por su generosidad. ¡Gracias por ser parte de este día tan especial!",
      link1: values.D8linkRegalo1,
      imgLink1: values.D8linkRegalo1
        ? (values.D8linkRegalo1.includes('liverpool.com')
          ? "https://assetspwa.liverpool.com.mx/assets/images/logos/liverpool-logo.svg"
          : values.D8linkRegalo1.includes('amazon.com')
            ? "https://i.postimg.cc/xTDxmzMZ/IMG_5645.png"
            : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png")
        : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
      link1Enable: values.D8linkRegalo1 ? !/^[a-zA-Z0-9\s]+$/.test(values.D8linkRegalo1) : false,
      link2: values.D8linkRegalo2,
      imgLink2: values.D8linkRegalo2
        ? (values.D8linkRegalo2.includes('liverpool.com')
          ? "https://assetspwa.liverpool.com.mx/assets/images/logos/liverpool-logo.svg"
          : values.D8linkRegalo2.includes('amazon.com')
            ? "https://i.postimg.cc/xTDxmzMZ/IMG_5645.png"
            : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png")
        : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
      link2Enable: values.D8linkRegalo2 ? !/^[a-zA-Z0-9\s]+$/.test(values.D8linkRegalo2) : false,
      link3: values.D8linkRegalo3,
      imgLink3: values.D8linkRegalo3
        ? (values.D8linkRegalo3.includes('liverpool.com')
          ? "https://assetspwa.liverpool.com.mx/assets/images/logos/liverpool-logo.svg"
          : values.D8linkRegalo3.includes('amazon.com')
            ? "https://i.postimg.cc/xTDxmzMZ/IMG_5645.png"
            : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png")
        : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
      link3Enable: values.D8linkRegalo3 ? !/^[a-zA-Z0-9\s]+$/.test(values.D8linkRegalo3) : false,
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra
    };

    const hospedaje = {
      disponibleDos: true,
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "...",
      hotel1lugar: values.D7hotel1lugar,
      hotel1url: values.D7hotel1url,
      hotel2Titulo: "...",
      hotel2lugar: values.D7hotel2lugar,
      hotel2url: values.D7hotel2url,
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    const colores = {
      color1: values.color1,
      color2: values.color2,
      idEvento: idEvento,
    };

    const invitados = {
      imgFondo: values.imgFondo,
      titulo: "",
      entradasText: "la Boda.",
      colorFondoName: "",
      eventoTitulo: "",
      limiteAccesos: 150,
      IdEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    let requiredImageKeys = [
      "eventId",
      "weddingCoverBackgroundImg",
      "weddingCoverBackgroundFile",
      "counterLeftImg",
      "counterLeftFile",
      "counterRightImg",
      "counterRightFile",
      "carousel",
    ];

    let data = new FormData();
    requiredImageKeys.forEach((value) => {
      switch (value) {
        case "carousel":
          let items = values[value];
          if (typeof items == "undefined") {
            break;
          }

          items.forEach((x, index) => {
            data.append(`${value}[${index}].Id`, x.id);
            data.append(`${value}[${index}].Url`, x.url);
            data.append(`${value}[${index}].Replacement`, x.replacement);
            data.append(`${value}[${index}].Deleted`, x.deleted);
          });
          break;
        case "eventId":
          console.log(idEvento);
          data.append(value, idEvento);
          break;
        default:
          data.append(value, values[value]);
          break;
      }
    });

    // Petición para el objeto hospedaje
    dataEvento.fechaFin = "Formulario";
    dataEvento.mail = values.mail;

    const eventData = { ...dataEvento, enablePortadaBoda: true };

    try {
      // Petición para el objeto portada
      await axios.post("/api/Seccionportadabodum", portada);

      // // Petición para el objeto hospedaje
      await axios.post("/api/Colores", colores);

      // // Petición para el objeto contador
      await axios.post("/api/Seccioncontador", contador);

      // // Petición para el objeto padres
      await axios.post("/api/Seccionpadre", padres);

      // // Petición para el objeto ceremonia
      await axios.post("/api/Seccionceremonium", ceremonia);

      // // Petición para el objeto vestimenta
      await axios.post("/api/Seccionvestimentum", vestimenta);

      // // Petición para el objeto confirmacion
      await axios.post("/api/Seccionconfirmacion", confirmacion);

      // // Petición para el objeto programa
      await axios.post("/api/Seccionprogramabodum", programa);

      // // Petición para el objeto regalos
      await axios.post("/api/Seccionregalo", regalos);

      // // Petición para el objeto hospedaje
      await axios.post("/api/Seccionhospedaje", hospedaje);

      // // Petición para el objeto hospedaje
      await axios.post("/api/Seccioninvitado", invitados);

      const updateImages = await axios.post(`/api/Images`, data);
      console.log("Respuesta del servidor:", updateImages.data);

      // Send a PUT request to update the event
      const updateResponse = await axios.put(
        `/api/Eventos/${idEvento}`,
        eventData
      );
      console.log(updateResponse.data);

      console.log("Datos enviados correctamente");
      const fechaActual = new Date();

      // Sumar 5 días para la fecha predeterminada
      const fechaFinal = sumarDias(new Date(fechaActual), 5);

      // Sumar 2 días para la fecha express
      const fechaFinalEx = sumarDias(new Date(fechaActual), 2);

      // Formatear las fechas en el formato mexicano (es-ES) con el huso horario de México
      const opcionesFecha = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'America/Mexico_City' };
      const fechaFormateada = fechaFinal.toLocaleDateString('es-ES', opcionesFecha);
      const fechaFormateadaEx = fechaFinalEx.toLocaleDateString('es-ES', opcionesFecha);

      // Mensaje en la consola
      console.log("Datos enviados correctamente");

      // Mostrar la alerta con la fecha de finalización
      alert(`¡Tu formulario ha sido enviado con éxito! 
Tu invitación estará lista en las siguientes fechas:
- Fecha predeterminada: ${fechaFormateada}
- Fecha express: ${fechaFormateadaEx}`);
Swal.fire({
  title: '¡Tu formulario ha sido enviado con éxito!',
  html: `
    <p>Para dar seguimiento, por favor contacta con la persona que te atendió.</p>
    <p>Este aviso confirma que tu formulario ha sido enviado correctamente.</p>
    <p>Fechas estimadas de entrega:</p>
    <ul>
      <li>Fecha predeterminada: ${fechaFormateada}</li>
      <li>Fecha express: ${fechaFormateadaEx}</li>
    </ul>
  `,
  icon: 'success',
  showCancelButton: true,
  confirmButtonText: 'Enviar mensaje por WhatsApp',
  cancelButtonText: 'Cerrar',
  customClass: {
    confirmButton: 'swal-confirm-btn',
    cancelButton: 'swal-cancel-btn'
  }
}).then((result) => {
  if (result.isConfirmed) {
    setLoadingSend(false);
    toast.dismiss();
    const message = encodeURIComponent("Hola, buen día. Mi formulario ya ha sido enviado.");
    const whatsappUrl = `https://wa.me/4491516931?text=${message}`;
    window.open(whatsappUrl, '_blank');
  }
  toast.dismiss();
  if (window.location.hostname === "yourinvitation.vip") {
    window.location.href = "/es";
  } else {
    window.location.href = "/";
  }
  setLoadingSend(false);
});
    } catch (error) {
      toast.dismiss();
      alert("Este formulario ya ha sido contestado o intentalo mas tarde");
      console.error("Error al enviar los datos:", error);
      setLoadingSend(false);
    }
  };

  const clearFormData = () => {
    const confirmed = window.confirm("¿Quieres reiniciar el formulario y borrar los datos guardados?");


    if (!confirmed) {
      console.log("Reseteo de formulario cancelado");
      return
    }
    localStorage.removeItem("formData");
    // Opcionalmente, podrías recargar la página o resetear los valores
    window.location.reload(); // Para recargar la página y resetear el formulario
  };

  function sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }

  const [useImage, setUseImage] = useState(false);

  const handleCheckboxChange = (e) => {
    setUseImage(e.target.checked);
  };

  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    getData();
    console.log(capitalizeWords("ANGÉL jaimés de la torre"));
  }, []);

  return (
    <div
      className="w-full bg-black md:p-10 sm:p-2"
      style={{
        backgroundImage: `url(https://digitalrsvp.mx/api/Images/1cb8085a-ac04-46ca-b7aa-b1a20dd68a30)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
      }}
    >
      {loading ? (
        <LoadingComponent />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(e) => {
            onSubmit(e);
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            handleReset,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form
              className="modal-form-content  flex flex-col items-center md:p-24 sm:p-4 "
              onSubmit={handleSubmit}
            >
              <br />
              <div className="bg-white shadow-md rounded-lg p-6 mb-8 max-w-4xl mx-auto">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">FORMULARIO PARA BODA</h2>

                {isAlreadySet && (
                  <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
                    <p className="font-bold">¡Atención!</p>
                    <p>Este formulario ya ha sido llenado previamente.</p>
                  </div>
                )}

                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-8" role="alert">
                  <p className="font-bold">¡Atención!</p>
                  <p>Por favor, asegúrate de ESCRIBIR CORRECTAMENTE LOS NOMBRES Y TEXTOS, ya que así es como aparecerán en la invitación. ¡Gracias por tu atención y cuidado!</p>
                </div>
                <div className="w-full pb-4 flex sm:justify-center sm:items-center md:justify-end md:items-end">
                <button
                  type="button"
                  onClick={clearFormData}
                  className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                >
                  Eliminar datos guardados
                </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <Label htmlFor="D1compradorInv" className="block text-sm font-medium text-gray-700 mb-1">Nombre del Comprador</Label>
                    <Input
                      id="D1compradorInv"
                      type="text"
                      maxLength="50"
                      name="D1compradorInv"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D1compradorInv}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1compradorInv ? "border-red-500" : ""}`}
                    />
                    {touched.D1compradorInv && errors.D1compradorInv && (
                      <p className="mt-2 text-sm text-red-600">{errors.D1compradorInv}</p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="mail" className="block text-sm font-medium text-gray-700 mb-1">Correo del Comprador</Label>
                    <Input
                      id="mail"
                      type="email"
                      maxLength="50"
                      name="mail"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.mail}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.mail ? "border-red-500" : ""}`}
                    />
                    {touched.mail && errors.mail && (
                      <p className="mt-2 text-sm text-red-600">{errors.mail}</p>
                    )}
                  </div>

                  <div>
                    <Label htmlFor="D1esposa" className="block text-sm font-medium text-gray-700 mb-1">Nombre Novia</Label>
                    <Input
                      id="D1esposa"
                      type="text"
                      maxLength="50"
                      name="D1esposa"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D1esposa}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1esposa ? "border-red-500" : ""}`}
                    />
                    {touched.D1esposa && errors.D1esposa && (
                      <p className="mt-2 text-sm text-red-600">{errors.D1esposa}</p>
                    )}
                  </div>

                  <div>
                    <Label htmlFor="D1esposo" className="block text-sm font-medium text-gray-700 mb-1">Nombre Novio</Label>
                    <Input
                      id="D1esposo"
                      type="text"
                      maxLength="50"
                      name="D1esposo"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D1esposo}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1esposo ? "border-red-500" : ""}`}
                    />
                    {touched.D1esposo && errors.D1esposo && (
                      <p className="mt-2 text-sm text-red-600">{errors.D1esposo}</p>
                    )}
                  </div>

                  <div>
                    <Label htmlFor="D1fechaDeBoda" className="block text-sm font-medium text-gray-700 mb-1">Fecha y hora de la Boda</Label>
                    <Input
                      id="D1fechaDeBoda"
                      type="datetime-local"
                      name="D1fechaDeBoda"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D1fechaDeBoda}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1fechaDeBoda ? "border-red-500" : ""}`}
                    />
                    {touched.D1fechaDeBoda && errors.D1fechaDeBoda && (
                      <p className="mt-2 text-sm text-red-600">{errors.D1fechaDeBoda}</p>
                    )}
                  </div>

                  <div className="md:col-span-2">
                    <Label htmlFor="D2descipcion" className="block text-sm font-medium text-gray-700 mb-1">Mensaje especial para los Invitados</Label>
                    <textarea
                      id="D2descipcion"
                      name="D2descipcion"
                      rows="4"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D2descipcion}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D2descipcion ? "border-red-500" : ""}`}
                    ></textarea>
                    {touched.D2descipcion && errors.D2descipcion && (
                      <p className="mt-2 text-sm text-red-600">{errors.D2descipcion}</p>
                    )}
                  </div>

                  <ImageFormComponent
                    label="Imagen de Portada, De Preferencia Vertical"
                    field="weddingCoverBackground"
                    element={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />

                  <ImageFormComponent
                    label="Foto Arriba del Contador"
                    field="counterLeft"
                    element={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />

                  <ImageFormComponent
                    label="Foto Abajo del Contador"
                    field="counterRight"
                    element={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <div className="bg-white shadow-md rounded-lg p-6 mb-8">
                <h2 className="text-2xl font-bold text-center mb-6">
                  COLORES DE FONDO
                </h2>
                <div className="flex flex-col md:flex-row items-center justify-between mb-6">
                  <label className="flex items-center mb-4 md:mb-0">
                    <input
                      type="checkbox"
                      checked={useImage}
                      onChange={handleCheckboxChange}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="ml-2 text-gray-700">Usar temática de fondo</span>
                  </label>
                </div>

                {!useImage && (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <Label htmlFor="color1" className="block text-sm font-medium text-gray-700 mb-1">Color 1</Label>
                      <Input
                        id="color1"
                        type="color"
                        name="color1"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.color1}
                        className={`w-full h-10 rounded-md ${errors.color1 ? "border-red-500" : "border-gray-300"}`}
                      />
                      {touched.color1 && errors.color1 && (
                        <p className="mt-2 text-sm text-red-600">{errors.color1}</p>
                      )}
                    </div>
                    <div>
                      <Label htmlFor="color2" className="block text-sm font-medium text-gray-700 mb-1">Color 2</Label>
                      <Input
                        id="color2"
                        type="color"
                        name="color2"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.color2}
                        className={`w-full h-10 rounded-md ${errors.color2 ? "border-red-500" : "border-gray-300"}`}
                      />
                      {touched.color2 && errors.color2 && (
                        <p className="mt-2 text-sm text-red-600">{errors.color2}</p>
                      )}
                    </div>
                  </div>
                )}

                {useImage && (
                  <div className="mt-6">
                    <ImageFondo
                      label="Selecciona un Fondo"
                      name="imgFondo"
                      onChange={handleChange}
                      value={values.imgFondo}
                      className={`w-full ${errors.imgFondo ? "border-red-500" : "border-gray-300"}`}
                    />
                  </div>
                )}
                <SelectSobre values={values} handleChange={handleChange} />
              </div>

              <div className="bg-white shadow-md rounded-lg p-6 mb-8">
                <h2 className="text-2xl font-bold text-center mb-6">
                  INFORMACIÓN DE CONTACTO
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <Label htmlFor="D1numeroNovia" className="block text-sm font-medium text-gray-700 mb-1">Número Novia</Label>
                    <Input
                      id="D1numeroNovia"
                      type="text"
                      name="D1numeroNovia"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D1numeroNovia}
                      className={`w-full rounded-md ${errors.D1numeroNovia ? "border-red-500" : "border-gray-300"}`}
                    />
                    {touched.D1numeroNovia && errors.D1numeroNovia && (
                      <p className="mt-2 text-sm text-red-600">{errors.D1numeroNovia}</p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="D1numeroNovio" className="block text-sm font-medium text-gray-700 mb-1">Número Novio</Label>
                    <Input
                      id="D1numeroNovio"
                      type="text"
                      name="D1numeroNovio"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D1numeroNovio}
                      className={`w-full rounded-md ${errors.D1numeroNovio ? "border-red-500" : "border-gray-300"}`}
                    />
                    {touched.D1numeroNovio && errors.D1numeroNovio && (
                      <p className="mt-2 text-sm text-red-600">{errors.D1numeroNovio}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-white shadow-md rounded-lg p-6 mb-8">
                <h2 className="text-2xl font-bold text-center mb-6">
                  INFORMACIÓN FAMILIAR
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {[
                    { label: "Nombre de la madre de la Novia", name: "D3esposaMadre" },
                    { label: "Nombre del padre de la Novia", name: "D3esposaPadre" },
                    { label: "Nombre de la madre del Novio", name: "D3esposoMadre" },
                    { label: "Nombre del padre del Novio", name: "D3esposoPadre" },
                    { label: "Nombre de la Madrina", name: "madrina" },
                    { label: "Nombre del Padrino", name: "padrino" }
                  ].map((field) => (
                    <div key={field.name}>
                      <Label htmlFor={field.name} className="block text-sm font-medium text-gray-700 mb-1">{field.label}</Label>
                      <Input
                        id={field.name}
                        type="text"
                        name={field.name}
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values[field.name]}
                        className={`w-full rounded-md ${errors[field.name] ? "border-red-500" : "border-gray-300"}`}
                      />
                      {touched[field.name] && errors[field.name] && (
                        <p className="mt-2 text-sm text-red-600">{errors[field.name]}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <br />
              <div className="bg-white shadow-md rounded-lg p-6 mb-8">
                <h2 className="text-2xl font-bold text-center mb-6">
                  INFORMACIÓN DE LA CEREMONIA
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <Label htmlFor="D4ceremoniaFecha" className="block text-sm font-medium text-gray-700 mb-1">Fecha y hora de la Ceremonia</Label>
                    <Input
                      id="D4ceremoniaFecha"
                      type="datetime-local"
                      name="D4ceremoniaFecha"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D4ceremoniaFecha}
                      className={`w-full rounded-md ${errors.D4ceremoniaFecha ? "border-red-500" : "border-gray-300"}`}
                    />
                    {touched.D4ceremoniaFecha && errors.D4ceremoniaFecha && (
                      <p className="mt-2 text-sm text-red-600">{errors.D4ceremoniaFecha}</p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="D4ceremoniaNombreLugar" className="block text-sm font-medium text-gray-700 mb-1">Lugar de la Ceremonia</Label>
                    <Input
                      id="D4ceremoniaNombreLugar"
                      type="text"
                      name="D4ceremoniaNombreLugar"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D4ceremoniaNombreLugar}
                      className={`w-full rounded-md ${errors.D4ceremoniaNombreLugar ? "border-red-500" : "border-gray-300"}`}
                    />
                    {touched.D4ceremoniaNombreLugar && errors.D4ceremoniaNombreLugar && (
                      <p className="mt-2 text-sm text-red-600">{errors.D4ceremoniaNombreLugar}</p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="D4ceremoniaUrl" className="block text-sm font-medium text-gray-700 mb-1">URL del lugar de la Ceremonia</Label>
                    <Input
                      id="D4ceremoniaUrl"
                      type="text"
                      name="D4ceremoniaUrl"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D4ceremoniaUrl}
                      className={`w-full rounded-md ${errors.D4ceremoniaUrl ? "border-red-500" : "border-gray-300"}`}
                    />
                    {touched.D4ceremoniaUrl && errors.D4ceremoniaUrl && (
                      <p className="mt-2 text-sm text-red-600">{errors.D4ceremoniaUrl}</p>
                    )}
                  </div>
                  <div>
                    <Label className="block text-sm font-medium text-gray-700 mb-1">Ejemplo URL</Label>
                    <img
                      src="https://i.postimg.cc/CLz26d5q/Dise-o-sin-t-tulo.gif"
                      alt="Ejemplo URL"
                      className="w-full h-auto rounded-md shadow-sm"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="bg-white shadow-md rounded-lg p-6 mb-8">
                <h2 className="text-2xl font-bold text-center mb-6">
                  RECEPCIÓN
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <Label htmlFor="D4recepcionFecha" className="block text-sm font-medium text-gray-700 mb-1">Fecha y hora de la Recepción</Label>
                    <Input
                      id="D4recepcionFecha"
                      type="datetime-local"
                      name="D4recepcionFecha"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D4recepcionFecha}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D4recepcionFecha ? "border-red-500" : ""}`}
                    />
                    {touched.D4recepcionFecha && errors.D4recepcionFecha && (
                      <p className="mt-2 text-sm text-red-600">{errors.D4recepcionFecha}</p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="D4recepcionNombreLugar" className="block text-sm font-medium text-gray-700 mb-1">Lugar de la Recepción</Label>
                    <Input
                      id="D4recepcionNombreLugar"
                      type="text"
                      name="D4recepcionNombreLugar"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D4recepcionNombreLugar}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D4recepcionNombreLugar ? "border-red-500" : ""}`}
                    />
                    {touched.D4recepcionNombreLugar && errors.D4recepcionNombreLugar && (
                      <p className="mt-2 text-sm text-red-600">{errors.D4recepcionNombreLugar}</p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="D4recepcionUrl" className="block text-sm font-medium text-gray-700 mb-1">URL del lugar de la Recepción</Label>
                    <Input
                      id="D4recepcionUrl"
                      type="text"
                      name="D4recepcionUrl"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D4recepcionUrl}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D4recepcionUrl ? "border-red-500" : ""}`}
                    />
                    {touched.D4recepcionUrl && errors.D4recepcionUrl && (
                      <p className="mt-2 text-sm text-red-600">{errors.D4recepcionUrl}</p>
                    )}
                  </div>
                  <div>
                    <Label className="block text-sm font-medium text-gray-700 mb-1">Ejemplo URL</Label>
                    <img
                      src="https://i.postimg.cc/CLz26d5q/Dise-o-sin-t-tulo.gif"
                      alt="Ejemplo URL"
                      className="w-full h-auto rounded-md shadow-sm"
                    />
                  </div>
                </div>
              </div>
              <br />

              <br />
              {dataEvento?.enableHospedaje && (
                <div className="bg-white shadow-md rounded-lg p-6 mb-8 max-w-4xl mx-auto">
                  <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">HOSPEDAJE</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <Label htmlFor="D7hotel1lugar" className="block text-sm font-medium text-gray-700 mb-1">Dirección del Hotel 1</Label>
                      <Input
                        id="D7hotel1lugar"
                        type="text"
                        name="D7hotel1lugar"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D7hotel1lugar}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D7hotel1lugar ? "border-red-500" : ""}`}
                      />
                      {touched.D7hotel1lugar && errors.D7hotel1lugar && (
                        <p className="mt-2 text-sm text-red-600">{errors.D7hotel1lugar}</p>
                      )}
                    </div>
                    <div>
                      <Label htmlFor="D7hotel1url" className="block text-sm font-medium text-gray-700 mb-1">URL de la Dirección del Hotel 1</Label>
                      <Input
                        id="D7hotel1url"
                        type="text"
                        name="D7hotel1url"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D7hotel1url}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D7hotel1url ? "border-red-500" : ""}`}
                      />
                      {touched.D7hotel1url && errors.D7hotel1url && (
                        <p className="mt-2 text-sm text-red-600">{errors.D7hotel1url}</p>
                      )}
                    </div>
                    <div>
                      <Label htmlFor="D7hotel2lugar" className="block text-sm font-medium text-gray-700 mb-1">Dirección del Hotel 2</Label>
                      <Input
                        id="D7hotel2lugar"
                        type="text"
                        name="D7hotel2lugar"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D7hotel2lugar}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D7hotel2lugar ? "border-red-500" : ""}`}
                      />
                      {touched.D7hotel2lugar && errors.D7hotel2lugar && (
                        <p className="mt-2 text-sm text-red-600">{errors.D7hotel2lugar}</p>
                      )}
                    </div>
                    <div>
                      <Label htmlFor="D7hotel2url" className="block text-sm font-medium text-gray-700 mb-1">URL de la Dirección del Hotel 2</Label>
                      <Input
                        id="D7hotel2url"
                        type="text"
                        name="D7hotel2url"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D7hotel2url}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D7hotel2url ? "border-red-500" : ""}`}
                      />
                      {touched.D7hotel2url && errors.D7hotel2url && (
                        <p className="mt-2 text-sm text-red-600">{errors.D7hotel2url}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {dataEvento?.enableRegalos && (
                <div className="bg-white shadow-md rounded-lg p-6 mb-8 max-w-4xl mx-auto">
                  <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">MESA DE REGALOS</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <Label htmlFor="D8linkRegalo1" className="block text-sm font-medium text-gray-700 mb-1">Link de la Mesa de Regalos 1</Label>
                      <Input
                        id="D8linkRegalo1"
                        type="text"
                        name="D8linkRegalo1"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D8linkRegalo1}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D8linkRegalo1 ? "border-red-500" : ""}`}
                      />
                      {touched.D8linkRegalo1 && errors.D8linkRegalo1 && (
                        <p className="mt-2 text-sm text-red-600">{errors.D8linkRegalo1}</p>
                      )}
                    </div>
                    <div>
                      <Label htmlFor="D8linkRegalo2" className="block text-sm font-medium text-gray-700 mb-1">Link de la Mesa de Regalos 2</Label>
                      <Input
                        id="D8linkRegalo2"
                        type="text"
                        name="D8linkRegalo2"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D8linkRegalo2}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D8linkRegalo2 ? "border-red-500" : ""}`}
                      />
                      {touched.D8linkRegalo2 && errors.D8linkRegalo2 && (
                        <p className="mt-2 text-sm text-red-600">{errors.D8linkRegalo2}</p>
                      )}
                    </div>
                    <div>
                      <Label htmlFor="D8linkRegalo3" className="block text-sm font-medium text-gray-700 mb-1">Link de la Mesa de Regalos 3</Label>
                      <Input
                        id="D8linkRegalo3"
                        type="text"
                        name="D8linkRegalo3"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D8linkRegalo3}
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D8linkRegalo3 ? "border-red-500" : ""}`}
                      />
                      {touched.D8linkRegalo3 && errors.D8linkRegalo3 && (
                        <p className="mt-2 text-sm text-red-600">{errors.D8linkRegalo3}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <br />
              {dataEvento?.enablePrograma && (
                <div className="bg-white shadow-md rounded-lg p-6 mb-8 max-w-4xl mx-auto">
                  <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">PROGRAMA</h2>
                  <div className="grid grid-cols-1 gap-8">
                    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                      <h3 className="text-xl font-semibold mb-4">Sección 1</h3>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <SelectIcons
                            label="Selecciona un icono para la sección 1"
                            name="PceremoniaReligiosaIcon"
                            onChange={handleChange}
                            value={values.PceremoniaReligiosaIcon}
                            className={`w-full ${errors.PceremoniaReligiosaIcon ? "border-red-500" : "border-gray-300"}`}
                          />
                        </div>
                        <div>
                          <Label htmlFor="PceremoniaReligiosaTitulo" className="block text-sm font-medium text-gray-700 mb-1">Título</Label>
                          <Input
                            id="PceremoniaReligiosaTitulo"
                            type="text"
                            name="PceremoniaReligiosaTitulo"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.PceremoniaReligiosaTitulo}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.PceremoniaReligiosaTitulo ? "border-red-500" : ""}`}
                          />
                          {touched.PceremoniaReligiosaTitulo && errors.PceremoniaReligiosaTitulo && (
                            <p className="mt-2 text-sm text-red-600">{errors.PceremoniaReligiosaTitulo}</p>
                          )}
                        </div>
                        <div>
                          <Label htmlFor="PceremoniaReligiosa" className="block text-sm font-medium text-gray-700 mb-1">Descripción</Label>
                          <textarea
                            id="PceremoniaReligiosa"
                            name="PceremoniaReligiosa"
                            rows="4"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.PceremoniaReligiosa}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.PceremoniaReligiosa ? "border-red-500" : ""}`}
                          ></textarea>
                          {touched.PceremoniaReligiosa && errors.PceremoniaReligiosa && (
                            <p className="mt-2 text-sm text-red-600">{errors.PceremoniaReligiosa}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="bg-blue-50 p-6 rounded-lg shadow-md">
                      <h3 className="text-xl font-semibold mb-4">Sección 2</h3>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <SelectIcons
                            label="Selecciona un icono para la sección 2"
                            name="PrecepcionIcon"
                            onChange={handleChange}
                            value={values.PrecepcionIcon}
                            className={`w-full ${errors.PrecepcionIcon ? "border-red-500" : "border-gray-300"}`}
                          />
                        </div>
                        <div>
                          <Label htmlFor="PrecepcionTitulo" className="block text-sm font-medium text-gray-700 mb-1">Título</Label>
                          <Input
                            id="PrecepcionTitulo"
                            type="text"
                            name="PrecepcionTitulo"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.PrecepcionTitulo}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.PrecepcionTitulo ? "border-red-500" : ""}`}
                          />
                          {touched.PrecepcionTitulo && errors.PrecepcionTitulo && (
                            <p className="mt-2 text-sm text-red-600">{errors.PrecepcionTitulo}</p>
                          )}
                        </div>
                        <div>
                          <Label htmlFor="Precepcion" className="block text-sm font-medium text-gray-700 mb-1">Descripción</Label>
                          <textarea
                            id="Precepcion"
                            name="Precepcion"
                            rows="4"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.Precepcion}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.Precepcion ? "border-red-500" : ""}`}
                          ></textarea>
                          {touched.Precepcion && errors.Precepcion && (
                            <p className="mt-2 text-sm text-red-600">{errors.Precepcion}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="bg-green-50 p-6 rounded-lg shadow-md">
                      <h3 className="text-xl font-semibold mb-4">Sección 3</h3>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <SelectIcons
                            label="Selecciona un icono para la sección 3"
                            name="PcivilIcon"
                            onChange={handleChange}
                            value={values.PcivilIcon}
                            className={`w-full ${errors.PcivilIcon ? "border-red-500" : "border-gray-300"}`}
                          />
                        </div>
                        <div>
                          <Label htmlFor="PcivilTitulo" className="block text-sm font-medium text-gray-700 mb-1">Título</Label>
                          <Input
                            id="PcivilTitulo"
                            type="text"
                            name="PcivilTitulo"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.PcivilTitulo}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.PcivilTitulo ? "border-red-500" : ""}`}
                          />
                          {touched.PcivilTitulo && errors.PcivilTitulo && (
                            <p className="mt-2 text-sm text-red-600">{errors.PcivilTitulo}</p>
                          )}
                        </div>
                        <div>
                          <Label htmlFor="Pcivil" className="block text-sm font-medium text-gray-700 mb-1">Descripción</Label>
                          <textarea
                            id="Pcivil"
                            name="Pcivil"
                            rows="4"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.Pcivil}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.Pcivil ? "border-red-500" : ""}`}
                          ></textarea>
                          {touched.Pcivil && errors.Pcivil && (
                            <p className="mt-2 text-sm text-red-600">{errors.Pcivil}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="bg-yellow-50 p-6 rounded-lg shadow-md">
                      <h3 className="text-xl font-semibold mb-4">Sección 4</h3>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <SelectIcons
                            label="Selecciona un icono para la sección 4"
                            name="PcenaIcon"
                            onChange={handleChange}
                            value={values.PcenaIcon}
                            className={`w-full ${errors.PcenaIcon ? "border-red-500" : "border-gray-300"}`}
                          />
                        </div>
                        <div>
                          <Label htmlFor="PcenaTitulo" className="block text-sm font-medium text-gray-700 mb-1">Título</Label>
                          <Input
                            id="PcenaTitulo"
                            type="text"
                            name="PcenaTitulo"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.PcenaTitulo}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.PcenaTitulo ? "border-red-500" : ""}`}
                          />
                          {touched.PcenaTitulo && errors.PcenaTitulo && (
                            <p className="mt-2 text-sm text-red-600">{errors.PcenaTitulo}</p>
                          )}
                        </div>
                        <div>
                          <Label htmlFor="Pcena" className="block text-sm font-medium text-gray-700 mb-1">Descripción</Label>
                          <textarea
                            id="Pcena"
                            name="Pcena"
                            rows="4"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.Pcena}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.Pcena ? "border-red-500" : ""}`}
                          ></textarea>
                          {touched.Pcena && errors.Pcena && (
                            <p className="mt-2 text-sm text-red-600">{errors.Pcena}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="bg-pink-50 p-6 rounded-lg shadow-md">
                      <h3 className="text-xl font-semibold mb-4">Sección 5</h3>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <SelectIcons
                            label="Selecciona un icono para la sección 5"
                            name="PcoctelIcon"
                            onChange={handleChange}
                            value={values.PcoctelIcon}
                            className={`w-full ${errors.PcoctelIcon ? "border-red-500" : "border-gray-300"}`}
                          />
                        </div>
                        <div>
                          <Label htmlFor="PcoctelTitulo" className="block text-sm font-medium text-gray-700 mb-1">Título</Label>
                          <Input
                            id="PcoctelTitulo"
                            type="text"
                            name="PcoctelTitulo"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.PcoctelTitulo}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.PcoctelTitulo ? "border-red-500" : ""}`}
                          />
                          {touched.PcoctelTitulo && errors.PcoctelTitulo && (
                            <p className="mt-2 text-sm text-red-600">{errors.PcoctelTitulo}</p>
                          )}
                        </div>
                        <div>
                          <Label htmlFor="Pcoctel" className="block text-sm font-medium text-gray-700 mb-1">Descripción</Label>
                          <textarea
                            id="Pcoctel"
                            name="Pcoctel"
                            rows="4"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.Pcoctel}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.Pcoctel ? "border-red-500" : ""}`}
                          ></textarea>
                          {touched.Pcoctel && errors.Pcoctel && (
                            <p className="mt-2 text-sm text-red-600">{errors.Pcoctel}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="bg-purple-50 p-6 rounded-lg shadow-md">
                      <h3 className="text-xl font-semibold mb-4">Sección 6</h3>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <SelectIcons
                            label="Selecciona un icono para la sección 6"
                            name="PbaileIcon"
                            onChange={handleChange}
                            value={values.PbaileIcon}
                            className={`w-full ${errors.PbaileIcon ? "border-red-500" : "border-gray-300"}`}
                          />
                        </div>
                        <div>
                          <Label htmlFor="PbaileTitulo" className="block text-sm font-medium text-gray-700 mb-1">Título</Label>
                          <Input
                            id="PbaileTitulo"
                            type="text"
                            name="PbaileTitulo"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.PbaileTitulo}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.PbaileTitulo ? "border-red-500" : ""}`}
                          />
                          {touched.PbaileTitulo && errors.PbaileTitulo && (
                            <p className="mt-2 text-sm text-red-600">{errors.PbaileTitulo}</p>
                          )}
                        </div>
                        <div>
                          <Label htmlFor="Pbaile" className="block text-sm font-medium text-gray-700 mb-1">Descripción</Label>
                          <textarea
                            id="Pbaile"
                            name="Pbaile"
                            rows="4"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.Pbaile}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.Pbaile ? "border-red-500" : ""}`}
                          ></textarea>
                          {touched.Pbaile && errors.Pbaile && (
                            <p className="mt-2 text-sm text-red-600">{errors.Pbaile}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <br />

              {dataEvento?.enableVestimenta && (
                <div className="bg-white shadow-md rounded-lg p-6 mb-8">
                  <h2 className="text-2xl font-bold text-center mb-6">
                    VESTIMENTA
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <Label htmlFor="Vestimenta" className="block text-sm font-medium text-gray-700 mb-1">Vestimenta del evento</Label>
                      <Input
                        id="Vestimenta"
                        type="text"
                        name="Vestimenta"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.Vestimenta}
                        placeholder="Formal.."
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.Vestimenta ? "border-red-500" : ""}`}
                      />
                      {touched.Vestimenta && errors.Vestimenta && (
                        <p className="mt-2 text-sm text-red-600">{errors.Vestimenta}</p>
                      )}
                    </div>
                    <div>
                      <Label htmlFor="VestimentaAviso" className="block text-sm font-medium text-gray-700 mb-1">Aviso de Vestimenta</Label>
                      <Input
                        id="VestimentaAviso"
                        type="text"
                        name="VestimentaAviso"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.VestimentaAviso}
                        placeholder="No llevar color negro.."
                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.VestimentaAviso ? "border-red-500" : ""}`}
                      />
                      {touched.VestimentaAviso && errors.VestimentaAviso && (
                        <p className="mt-2 text-sm text-red-600">{errors.VestimentaAviso}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <br />
              <div className="bg-white shadow-md rounded-lg p-6 mb-8">
                <h2 className="text-2xl font-bold text-center mb-6">
                  CANCIÓN Y TIPO DE LETRA
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <Label htmlFor="cancion" className="block text-sm font-medium text-gray-700 mb-1">Canción</Label>
                    <Input
                      id="cancion"
                      type="text"
                      name="cancion"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.cancion}
                      placeholder="Ingrese el nombre de la canción"
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.cancion ? "border-red-500" : ""}`}
                    />
                    {touched.cancion && errors.cancion && (
                      <p className="mt-2 text-sm text-red-600">{errors.cancion}</p>
                    )}
                  </div>

                    <div>
                      <SelectTipoLetra values={values} handleChange={handleChange} />
                    </div>
                </div>

                <div className="mt-6">
                  <Label className="block text-sm font-medium text-gray-700 mb-2">Ejemplo para obtener la canción</Label>
                  <img
                    src="https://i.postimg.cc/DyznQv2p/0605-1.gif"
                    alt="Ejemplo de cómo obtener la canción"
                    className="w-full h-auto rounded-lg shadow-md"
                  />
                </div>
              </div>

              <br />
              <div className="bg-white shadow-md rounded-lg p-6 mb-8 max-w-4xl mx-auto">
                <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                  COMENTARIOS O DETALLES
                </h2>
                <div>
                  <Label htmlFor="Detalles" className="block text-sm font-medium text-gray-700 mb-1">Detalles</Label>
                  <textarea
                    id="Detalles"
                    name="Detalles"
                    rows="6"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.Detalles}
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.Detalles ? "border-red-500" : ""}`}
                  ></textarea>
                  {touched.Detalles && errors.Detalles && (
                    <p className="mt-2 text-sm text-red-600">{errors.Detalles}</p>
                  )}
                </div>
              </div>

              {isOpen && (
                <ModalFormularioPre
                  isOpen={isOpen}
                  toggleModal={toggleModal}
                  datos={values}
                  dataEvento={dataEvento}
                />
              )}

              <CarouselComponent values={values} setValues={setFieldValue} paquete={dataEvento?.paquete} />

              <br />
              <br />
              <div className="bg-white shadow-md rounded-lg p-6 mb-8 max-w-4xl mx-auto">
                <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                  ACCIONES FINALES
                </h2>
                <div className="flex flex-col md:flex-row justify-center items-center gap-4">
                  <button
                    type="button"
                    onClick={toggleModal}
                    className="w-full md:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                  >
                    Ver ejemplo de tu invitación
                  </button>
                  <button
                    type="submit"
                    disabled={loadingSend}
                    className="w-full md:w-auto bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                  >
                    Enviar Formulario
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
