'use client'

import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

export const MensajeXvComponentD2 = ({ peltaColores, datosSeccionContador }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    })
  }, [])

  const fontFamilyStyle = { fontFamily: datosSeccionContador.tipoLetra || "Playfair Display, serif" }

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionContador.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundColor: peltaColores.color1,
        color: datosSeccionContador.textColor || "#333",
      }}
      className="min-h-screen flex flex-col items-center justify-center w-full px-4 py-12 sm:px-6 lg:px-8"
    >
      <div className="flex flex-col pt-[10vh] w-full items-center">
        <h1
          className="font-serif sm:text-[52px] md:text-[92px]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              {index !== datosSeccionContador.titulo.split("\\n").length - 1 && <br />}
            </React.Fragment>
          ))}
        </h1>
        <p
          className="text-center pt-[5vh] font-serif text-xl sm:w-[90%] md:w-[60%]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {datosSeccionContador.descipcion.split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              {index !== datosSeccionContador.descipcion.split("\\n").length - 1 && <br />}
            </React.Fragment>
          ))}
        </p>
      </div>
      <div className="mt-16 w-full max-w-md" data-aos="zoom-in" data-aos-delay="400">
        <div
          style={{
            backgroundImage: `url(${datosSeccionContador.fotoIzquierda})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
          className="aspect-square rounded-full border-4 border-white shadow-2xl transform hover:scale-105 transition-transform duration-300"
        ></div>
      </div>
    </div>
  )
}

export default MensajeXvComponentD2