import React from 'react';
import { Input, Label } from "reactstrap";
import { ImageFondo } from "../../Images/ImageFondo";
import { SelectSobre } from "../../../../../components/functions/SelectSobre";

export const BackgroundColorsForm = ({ values, handleChange, handleInputChange, errors, touched }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-8">
      <h2 className="text-2xl font-bold text-center mb-6">
        COLORES DE FONDO
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <Label htmlFor="color1" className="block text-sm font-medium text-gray-700 mb-1">Color 1</Label>
          <Input
            id="color1"
            type="color"
            name="color1"
            onChange={(e) => { handleChange(e); handleInputChange(e); }}
            value={values.color1}
            className={`w-full h-10 rounded-md ${errors.color1 ? "border-red-500" : "border-gray-300"}`}
          />
          {touched.color1 && errors.color1 && (
            <p className="mt-2 text-sm text-red-600">{errors.color1}</p>
          )}
        </div>
        <div>
          <Label htmlFor="color2" className="block text-sm font-medium text-gray-700 mb-1">Color 2</Label>
          <Input
            id="color2"
            type="color"
            name="color2"
            onChange={(e) => { handleChange(e); handleInputChange(e); }}
            value={values.color2}
            className={`w-full h-10 rounded-md ${errors.color2 ? "border-red-500" : "border-gray-300"}`}
          />
          {touched.color2 && errors.color2 && (
            <p className="mt-2 text-sm text-red-600">{errors.color2}</p>
          )}
        </div>
      </div>
      <div className="mt-6">
        <ImageFondo
          label="Selecciona un Fondo"
          name="imgFondo"
          onChange={handleChange}
          value={values.imgFondo}
          className={`w-full ${errors.imgFondo ? "border-red-500" : "border-gray-300"}`}
        />
      </div>
      <SelectSobre values={values} handleChange={handleChange} />
    </div>
  );
}; 