import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaFacebookF, FaInstagram, FaFacebookMessenger, FaWhatsapp } from "react-icons/fa";
import { motion } from 'framer-motion';
import { CheckIcon, XIcon, ExternalLinkIcon, SparklesIcon } from 'lucide-react';
import './Home.css';

const plans = [
  {
    name: 'PLATA',
    originalPrice: "150,000",
    price: "64,900",
    color: 'bg-gradient-to-br from-gray-200 to-gray-400',
    features: [
      'Comparte tu invitación a cualquier celular',
      'Descripción de tu evento',
      'Cuenta regresiva',
      'Colores y temas de fondo',
      'Música de fondo',
      'Agradecimiento a los padres',
      'Dirección de la ceremonia y recepción por Google Maps',
      'Incluye 3 de tus fotos favoritas',      
    ],
    examples: [{text:'Dulces 16 Plata', url:"/en/event-auto?id=3626"}, {text:'Boda Plata', url:'/en/wedding-auto?id=3323'}]
  },
  {
    name: 'DIAMANTE',
    originalPrice: "450,000",
    price: "219,900",
    color: 'bg-gradient-to-br from-sky-300 to-sky-400',
    features: [
      'CONVIERTE TU INVITACIÓN EN ALGO ÚNICO, CON:',     
      'Invitaciones únicas para cada invitado, con su nombre, un mensaje especial y el número de pases asignados',
      'Fuentes de texto especiales',
      'Programa de actividades',
      'Código de vestimenta personalizado',
      'Sección de alojamiento y sugerencias',
      'Fondo diseñado al momento para tí',
      'Galería completa de hasta 10 fotos'
    ],
    examples: [{text:'XV Años Diamante', url:"/event-auto-diamond?idInvitado=49518"}, {text:'Boda Diamante', url:'/wedding-auto-diamond?idInvitado=49674'}],
    popular: true
  },
  {
    name: 'ORO',
    originalPrice: "300,000",
      price: "134,900",
    color: 'bg-gradient-to-br from-yellow-300 to-yellow-400',
    features: [
      'Lleva tu invitación al siguiente nivel con:',
      'Programa de actividades',
      'Código de vestimenta personalizado',
      'Fuentes de texto especiales',
      'fondos premium',
      'Mesa de regalos o lluvia de sobres',
      'Galería de 4 fotos adicionales'      
    ],
    examples: [{text:'XV Años Oro', url:"/en/event-auto?id=2223"}, {text:'Boda Oro', url:'/en/wedding-auto?id=3584'}]
  }
];


export const HomeCo = () => {
  const [timeLeft, setTimeLeft] = useState(24 * 59 * 57);
  const [expandedPackage, setExpandedPackage] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const handlePlanSelect = (planName) => {
    const whatsappMessage = `Hola, estoy interesado en el paquete ${planName}.`;
    const whatsappUrl = `https://wa.me/+12627398940?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleIncludeClick = (packageName) => {
    setExpandedPackage(expandedPackage === packageName ? null : packageName);
    
    if (expandedPackage !== packageName) {
      const element = document.getElementById(`features-${packageName}`);
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleWhatsappClick = () => {
    const whatsappMessage = "Hola, ¿me puedes dar más información?.";
    const whatsappUrl = `https://wa.me/+12627398940?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="mx-auto overflow-x-hidden bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Header Section */}
      <header className="text-center mx-auto bg-gradient-to-b from-white to-gray-100">
        <div className="max-w-4xl mx-auto">
                  <div className="mt-8 flex justify-center">
            <img src="https://i.postimg.cc/1tRMg6j4/Sin-t-tulo-4000-x-2500-px-3.png" alt="Invitaciones" className="w-72 sm:w-[40vh] rounded-3xl transform hover:scale-105 transition-transform duration-300" />
          </div>
          <h2 className="mt-8 text-[#6d6d6d] text-3xl md:text-5xl font-semibold">INVITACIONES DIGITALES</h2>
        </div>
      </header>

      {/* Ejemplos Section */}
      <section className="py-20 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {[
             { title: "XV Años & Sweet 16", img: "https://i.postimg.cc/kMQXMSdW/Quince_años_&_Sweet_16_(5).png", url: "/event-auto-diamond?idInvitado=49518" },
             { title: "Bodas", img: "https://i.postimg.cc/brCxSB2B/Quince-a-os-Sweet-16-3.png", url: "/wedding-auto-diamond?idInvitado=49674" },
             { title: "Cumpleaños", img: "https://i.postimg.cc/d3jq5b95/Quince_años_&_Sweet_16_(4).png", url: "/en/event-auto?id=4168" },
              
            ].map((example, idx) => (
              <motion.div
                key={example.title}
                className="flex flex-col items-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: idx * 0.2 }}
              >
                <h3 className="text-2xl font-allura text-[#6d6d6d] mb-4">{example.title}</h3>
                <a href={example.url} className="group">
                  <div className="relative rounded-3xl shadow-lg">
                    <img src={example.img} alt={example.title} className="w-full transition-transform duration-300 group-hover:scale-110" />
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <span className="text-white text-lg font-semibold">Ver Más</span>
                    </div>
                  </div>
                </a>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Packages Section */}
      <section className="py-20 bg-gradient-to-b from-gray-100 to-white">
        <div className="max-w-6xl mx-auto px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-8 text-center text-gray-900">Elige tu plan ideal</h1>
          <div className="text-2xl font-semibold mb-12 text-center flex items-center justify-center">
            <SparklesIcon className="inline-block mr-2 text-yellow-500" />
            <span>La oferta termina en: </span>
            <span className="ml-2 text-red-600">{formatTime(timeLeft)}</span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-7xl">
            {plans.map((plan, index) => (
              <motion.div
                key={plan.name}
                className={`bg-gray-50 rounded-xl 
                  overflow-hidden flex flex-col h-full relative
                  border-4 border-gradient-to-b from-gray-300 to-blue-700 rounded-3xl
                  bg-clip-border
                  ${plan.name === "DIAMANTE" ? 
                    "border-gradient-to-b from-gray-400 to-blue-500" :
                    plan.name === "ORO" ? 
                      "border-gradient-to-b from-yellow-400 to-blue-500" :
                      "border-gradient-to-b from-sky-300 to-blue-500"
                  }`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className={`${plan.color} p-3 text-center`}>
                  <h2 className="text-3xl font-bold text-white">{plan.name}</h2>
                  {plan.popular && <span className="bg-yellow-400 text-gray-800 text-xs font-semibold px-2 py-1 rounded-full uppercase mt-2 inline-block">Más Popular</span>}
                </div>
                <div className="p-3 flex-grow">
                  <div className="text-center mb-8">
                    <div className="bg-red-500 text-white text-sm font-bold px-4 py-1 rounded-md inline-block mt-3 mb-2">
                      Oferta Relámpago
                    </div>
                    <div className="flex items-center justify-center gap-2 mb-1">
                      <span className="text-red-600 text-2xl font-bold">
                        -{Math.round((1 - parseInt(plan.price.toString().replace(/\./g, '')) / parseInt(plan.originalPrice.toString().replace(/\./g, ''))) * 100)}%
                      </span>
                      <span className="text-black text-2xl font-semibold">
                        {plan.price.toLocaleString('es-CO', { 
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0 
                        })}
                        <sup className="text-sm ml-1">{plan.price.toString().split('.')[1]}</sup>
                      </span>
                    </div>
                    <div className="text-gray-400 text-xs">
                      Antes: <span className="line-through">
                        {plan.originalPrice.toLocaleString('es-CO', { 
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0 
                        })}
                        <sup className="text-xs">{plan.originalPrice.toString().split('.')[1]}</sup>
                      </span>
                    </div>
                  </div>
                      {/* Contact Button */}
      <div className="fixed bottom-4 right-4 z-50">
        <button 
          onClick={handleWhatsappClick} 
          className="flex animate-bounce items-center bg-green-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-green-400 transition duration-300"
        >
          <FaWhatsapp className="mr-2" />
          Contáctanos
        </button>
      </div>
                                <div className="mb-3 bg-gray-50 rounded-lg">
                    <p className="font-semibold mb-3 text-center text-gray-900">Ejemplos:</p>
                    <div className="">
                      {plan.examples.map((example, i) => (
                        <a
                          key={i}
                          href={example.url}
                          className="flex items-center gap-3 p-2.5 bg-white/5 rounded-lg 
                            hover:bg-white/10 transition-colors duration-300 group no-underline"
                        >
                          <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center group-hover:bg-blue-200 transition-colors duration-300">
                            <ExternalLinkIcon className="w-5 h-5 text-blue-600" />
                          </div>
                          <span className="text-gray-800 group-hover:text-blue-600 transition-colors duration-300">
                            {example.text}
                          </span>
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="px-2 text-white">
                    <button
                      onClick={() => handleIncludeClick(plan.name)}
                      className="mx-4 my-3 px-6 py-2.5 bg-gradient-to-r from-blue-600 to-blue-700
                        hover:from-blue-700 hover:to-blue-800 text-white rounded-full
                        transition-all duration-300 shadow-lg hover:shadow-blue-500/25
                        flex items-center justify-center gap-2 w-[80%] mx-auto"
                    >
                      <span className="text-sm font-medium">¿Qué incluye?</span>
                      <motion.span
                        animate={{ rotate: expandedPackage === plan.name ? 180 : 0 }}
                        transition={{ duration: 0.3 }}
                        className="text-blue-200"
                      >
                        ↓
                      </motion.span>
                    </button>

                    <motion.div
                      id={`features-${plan.name}`}
                      initial={{ height: 0, opacity: 0 }}
                      animate={{
                        height: expandedPackage === plan.name ? 'auto' : 0,
                        opacity: expandedPackage === plan.name ? 1 : 0
                      }}
                      transition={{ duration: 0.3 }}
                      className="overflow-hidden"
                    >
                      <div className="pt-4 space-y-2">
                        {plan.features.map((feature, i) => (
                          <motion.div
                            key={i}
                            initial={{ x: -20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: i * 0.1 }}
                            className="flex items-start gap-2"
                          >
                            <CheckIcon className="w-4 h-4 text-green-500 flex-shrink-0 mt-0.5" />
                            <span className="text-gray-800 text-sm">{feature}</span>
                          </motion.div>
                        ))}
                      </div>
                    </motion.div>
                  </div>
                </div>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full py-4 bg-gradient-to-r from-blue-500 to-blue-600
                    hover:from-blue-600 hover:to-blue-700 text-white font-semibold
                    transition-all duration-300 shadow-lg hover:shadow-blue-500/25
                    border-t border-white/10"
                  onClick={() => handlePlanSelect(plan.name)}
                >
                  ¡LO QUIERO!
                </motion.button>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="gray-800 text-white py-16">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <p className="text-xl mb-4">¿Qué esperas?</p>
          <h2 className="text-4xl font-bold mb-8">CONTÁCTANOS</h2>
          <div className="flex justify-center space-x-8 mb-12">
            <a href="https://www.facebook.com/profile.php?id=61566533287428&mibextid=LQQJ4d" className="text-white hover:text-blue-400 transition-colors duration-300">
              <FaFacebookF size={32} />
            </a>
            <a href="https://www.instagram.com/yourinvitationvip/" className="text-white hover:text-pink-400 transition-colors duration-300">
              <FaInstagram size={32} />
            </a>
            <a href="https://m.me/61566533287428?text=Hello%2C%20I%27d%20like%20an%20invitation%20please" className="text-white hover:text-green-400 transition-colors duration-300">
              <FaFacebookMessenger size={32} />
            </a>
          </div>
          <p className="text-gray-400">Your Invitation VIP | Copyright © 2024</p>
        </div>
      </footer>
    </div>
  );
};

export default HomeCo;
