import React from 'react';
import { Input, Label } from "reactstrap";
import { ImageFormComponent } from "../../Images/ImageFormComponent";

export const BasicInfoForm = ({
  values,
  handleChange,
  handleInputChange,
  errors,
  touched,
  setFieldValue
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <Label
          htmlFor="D1compradorInv"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Nombre del Comprador
        </Label>
        <Input
          id="D1compradorInv"
          type="text"
          maxLength="50"
          name="D1compradorInv"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.D1compradorInv}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1compradorInv ? "border-red-500" : ""}`}
        />
        {touched.D1compradorInv && errors.D1compradorInv && (
          <p className="mt-2 text-sm text-red-600">{errors.D1compradorInv}</p>
        )}
      </div>

      <div>
        <Label
          htmlFor="mail"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Correo del Comprador
        </Label>
        <Input
          id="mail"
          type="email"
          maxLength="50"
          name="mail"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.mail}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.mail ? "border-red-500" : ""}`}
        />
        {touched.mail && errors.mail && (
          <p className="mt-2 text-sm text-red-600">{errors.mail}</p>
        )}
      </div>

      <div>
        <Label
          htmlFor="D1esposa"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Nombre Novia
        </Label>
        <Input
          id="D1esposa"
          type="text"
          maxLength="50"
          name="D1esposa"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.D1esposa}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1esposa ? "border-red-500" : ""}`}
        />
        {touched.D1esposa && errors.D1esposa && (
          <p className="mt-2 text-sm text-red-600">{errors.D1esposa}</p>
        )}
      </div>

      <div>
        <Label
          htmlFor="D1esposo"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Nombre Novio
        </Label>
        <Input
          id="D1esposo"
          type="text"
          maxLength="50"
          name="D1esposo"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.D1esposo}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1esposo ? "border-red-500" : ""}`}
        />
        {touched.D1esposo && errors.D1esposo && (
          <p className="mt-2 text-sm text-red-600">{errors.D1esposo}</p>
        )}
      </div>

      <div>
        <Label
          htmlFor="D1fechaDeBoda"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Fecha y hora de la Boda
        </Label>
        <Input
          id="D1fechaDeBoda"
          type="datetime-local"
          name="D1fechaDeBoda"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.D1fechaDeBoda}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1fechaDeBoda ? "border-red-500" : ""}`}
        />
        {touched.D1fechaDeBoda && errors.D1fechaDeBoda && (
          <p className="mt-2 text-sm text-red-600">{errors.D1fechaDeBoda}</p>
        )}
      </div>

      <div className="md:col-span-2">
        <Label
          htmlFor="D2descipcion"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Mensaje especial para los Invitados
        </Label>
        <textarea
          id="D2descipcion"
          name="D2descipcion"
          rows="4"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.D2descipcion}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D2descipcion ? "border-red-500" : ""}`}
        />
        {touched.D2descipcion && errors.D2descipcion && (
          <p className="mt-2 text-sm text-red-600">{errors.D2descipcion}</p>
        )}
      </div>

      <ImageFormComponent
        label="Imagen de Portada, De Preferencia Vertical"
        field="weddingCoverBackground"
        element={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />

      <ImageFormComponent
        label="Foto para fondo del Contador"
        field="counterBackground"
        element={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />

      <ImageFormComponent
        label="Foto para fondo de la sección de Padres"
        field="parentsBackground"
        element={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />

      <ImageFormComponent
        label="Foto para fondo de la sección de Invitados"
        field="inviteBackground"
        element={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

export const BasicInfoInvForm = ({
  values,
  handleChange,
  handleInputChange,
  errors,
  touched,
  setFieldValue
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <Label
          htmlFor="D1compradorInv"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Nombre del Comprador
        </Label>
        <Input
          id="D1compradorInv"
          type="text"
          maxLength="50"
          name="D1compradorInv"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.D1compradorInv}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1compradorInv ? "border-red-500" : ""}`}
        />
        {touched.D1compradorInv && errors.D1compradorInv && (
          <p className="mt-2 text-sm text-red-600">{errors.D1compradorInv}</p>
        )}
      </div>

      <div>
        <Label
          htmlFor="mail"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Correo del Comprador
        </Label>
        <Input
          id="mail"
          type="email"
          maxLength="50"
          name="mail"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.mail}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.mail ? "border-red-500" : ""}`}
        />
        {touched.mail && errors.mail && (
          <p className="mt-2 text-sm text-red-600">{errors.mail}</p>
        )}
      </div>

      <div>
        <Label
          htmlFor="D1NombreQuince"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Nombre del Festejado/a
        </Label>
        <Input
          id="D1NombreQuince"
          type="text"
          maxLength="50"
          name="D1NombreQuince"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.D1NombreQuince}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1NombreQuince ? "border-red-500" : ""}`}
        />
        {touched.D1NombreQuince && errors.D1NombreQuince && (
          <p className="mt-2 text-sm text-red-600">{errors.D1NombreQuince}</p>
        )}
      </div>


      <div>
        <Label htmlFor="D1numeroWiddingAdmin" className="block text-sm font-medium text-gray-700 mb-1">Numero donde llegaran las confirmaciones</Label>
        <Input
          id="D1numeroWiddingAdmin"
          type="text"
          maxLength="50"
          name="D1numeroWiddingAdmin"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.D1numeroWiddingAdmin}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1numeroWiddingAdmin ? "border-red-500" : ""}`}
        />
        {touched.D1numeroWiddingAdmin && errors.D1numeroWiddingAdmin && (
          <p className="mt-2 text-sm text-red-600">{errors.D1numeroWiddingAdmin}</p>
        )}
      </div>

      <div>
        <Label
          htmlFor="D1fechaDeBoda"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Fecha y hora del Evento
        </Label>
        <Input
          id="D1fechaDeBoda"
          type="datetime-local"
          name="D1fechaDeBoda"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.D1fechaDeBoda}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D1fechaDeBoda ? "border-red-500" : ""}`}
        />
        {touched.D1fechaDeBoda && errors.D1fechaDeBoda && (
          <p className="mt-2 text-sm text-red-600">{errors.D1fechaDeBoda}</p>
        )}
      </div>

      <div className="md:col-span-2">
        <Label
          htmlFor="D2descipcion"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Mensaje especial para los Invitados
        </Label>
        <textarea
          id="D2descipcion"
          name="D2descipcion"
          rows="4"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.D2descipcion}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D2descipcion ? "border-red-500" : ""}`}
        />
        {touched.D2descipcion && errors.D2descipcion && (
          <p className="mt-2 text-sm text-red-600">{errors.D2descipcion}</p>
        )}
      </div>

      <ImageFormComponent
        label="Imagen de Portada, De Preferencia Vertical"
        field="coverBackground"
        element={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />

      <ImageFormComponent
        label="Foto para fondo del Contador"
        field="counterBackground"
        element={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />

      <ImageFormComponent
        label="Foto para fondo de la sección de Padres"
        field="parentsBackground"
        element={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />

      <ImageFormComponent
        label="Foto para fondo de la sección de Invitados"
        field="inviteBackground"
        element={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />
    </div>
  );
}; 