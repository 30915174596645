'use client'

import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export const PadresBodaComponent2 = ({ datosSeccionPadres, fotoPadres, colores }) => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            easing: 'ease-out'
        })
    }, [])

    const fontFamilyStyle = {
        fontFamily: datosSeccionPadres.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
    };

    const textStyle = {
        ...fontFamilyStyle,
        color: datosSeccionPadres.textColor || '#8b8178'
    };

    return (
        <div className="relative min-h-screen w-full overflow-hidden"
        style={{backgroundColor: colores?.color2 || '#f5f0e9'}}
        >
            {/* Background Image */}
            <div className="absolute inset-0">
              <img
                src={datosSeccionPadres.imgFondo || ""}
                alt="Wedding background"
                className="h-full w-full object-cover"
              />
            </div>

            {/* Content Container */}
            <div className="relative w-full">
              {/* Semi-circular Background */}
              <div className="relative bg-[#C2B6AA] rounded-b-[50%]"
              style={{backgroundColor: colores?.color1 || '#C2B6AA'}}
              >
                {/* Content */}
                <div className="px-8 pt-16 pb-32 text-center">
                  <div className="mx-auto max-w-sm space-y-8">
                    {/* Main Heading */}
                    <h1 
                        data-aos="fade-down"
                        className="font-serif text-2xl leading-relaxed"
                        style={textStyle}
                    >
                      {datosSeccionPadres.titulo?.split("\\n").map((linea, index) => (
                        <React.Fragment key={index}>
                          {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                            parte.startsWith("/") && parte.endsWith("/") ? (
                              <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                            ) : (
                              <span key={subIndex}>{parte}</span>
                            )
                          ))}
                          <br />
                        </React.Fragment>
                      )) || (<>Con la bendición de<br />nuestros Padres</>)}
                    </h1>

                    {/* Description if exists */}
                    {datosSeccionPadres.descripcion && (
                      <p 
                        data-aos="fade-down"
                        data-aos-delay="100"
                        className="text-lg"
                        style={textStyle}
                      >
                        {datosSeccionPadres.descripcion.split("\\n").map((linea, index) => (
                          <React.Fragment key={index}>
                            {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                              parte.startsWith("/") && parte.endsWith("/") ? (
                                <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                              ) : (
                                <span key={subIndex}>{parte}</span>
                              )
                            ))}
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                    )}

                    {/* Vertical Line */}
                    <div 
                        data-aos="fade-down"
                        data-aos-delay="200"
                        className="mx-auto h-6 w-px"
                        style={{backgroundColor: datosSeccionPadres.textColor || '#8b8178'}}
                    />

                    <div
                      className={`grid sm:grid-cols-1 md:grid-cols-${datosSeccionPadres.cantidad || "1"} w-full pt-0`}
                    >
                      <div
                        className="flex flex-col justify-center items-center"
                        data-aos="fade-right"
                      >
                        {/* First Group */}
                        <div 
                            data-aos="fade-right"
                            data-aos-delay="400"
                            className="space-y-2"
                        >
                          <p className="text-sm font-light" style={textStyle}>
                            {datosSeccionPadres.titulo1?.split("\\n").map((linea, index) => (
                              <React.Fragment key={index}>
                                {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                                  parte.startsWith("/") && parte.endsWith("/") ? (
                                    <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                                  ) : (
                                    <span key={subIndex}>{parte}</span>
                                  )
                                ))}
                                <br />
                              </React.Fragment>
                            ))}
                          </p>
                          <p className="font-serif text-xl" style={textStyle}>
                            {datosSeccionPadres.madre?.split("\\n").map((linea, index) => (
                              <React.Fragment key={index}>
                                {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                                  parte.startsWith("/") && parte.endsWith("/") ? (
                                    <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                                  ) : (
                                    <span key={subIndex}>{parte}</span>
                                  )
                                ))}
                                <br />
                              </React.Fragment>
                            ))}
                          </p>
                          {datosSeccionPadres.padre && (
                            <p className="font-serif text-xl" style={textStyle}>
                              {datosSeccionPadres.padre.split("\\n").map((linea, index) => (
                                <React.Fragment key={index}>
                                  {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                                    parte.startsWith("/") && parte.endsWith("/") ? (
                                      <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                                    ) : (
                                      <span key={subIndex}>{parte}</span>
                                    )
                                  ))}
                                  <br />
                                </React.Fragment>
                              ))}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* Validación para cantidad 2 o 3 */}
                      {datosSeccionPadres.cantidad >= 2 && (
                        <div
                          className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                          data-aos="fade-left"
                        >
                          {/* Second Group if exists */}
                          {datosSeccionPadres.titulo2 && (
                            <div 
                                data-aos="fade-left"
                                data-aos-delay="600"
                                className="space-y-2"
                            >
                              <p className="text-sm font-light" style={textStyle}>
                                {datosSeccionPadres.titulo2.split("\\n").map((linea, index) => (
                                  <React.Fragment key={index}>
                                    {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                                      parte.startsWith("/") && parte.endsWith("/") ? (
                                        <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                                      ) : (
                                        <span key={subIndex}>{parte}</span>
                                      )
                                    ))}
                                    <br />
                                  </React.Fragment>
                                ))}
                              </p>
                              {datosSeccionPadres.madrina && (
                                <p className="font-serif text-xl" style={textStyle}>
                                  {datosSeccionPadres.madrina.split("\\n").map((linea, index) => (
                                    <React.Fragment key={index}>
                                      {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                                        parte.startsWith("/") && parte.endsWith("/") ? (
                                          <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                                        ) : (
                                          <span key={subIndex}>{parte}</span>
                                        )
                                      ))}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </p>
                              )}
                              {datosSeccionPadres.padrino && (
                                <p className="font-serif text-xl" style={textStyle}>
                                  {datosSeccionPadres.padrino.split("\\n").map((linea, index) => (
                                    <React.Fragment key={index}>
                                      {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                                        parte.startsWith("/") && parte.endsWith("/") ? (
                                          <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                                        ) : (
                                          <span key={subIndex}>{parte}</span>
                                        )
                                      ))}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {/* Validación para cantidad 3 */}
                      {datosSeccionPadres.cantidad === 3 && (
                        <div
                          className="flex flex-col justify-center items-center sm:mt-10 md:mt-0"
                          data-aos="fade-left"
                        >
                          {/* Third Group if exists */}
                          {datosSeccionPadres.titulo3 && (
                            <div 
                                data-aos="fade-left"
                                data-aos-delay="800"
                                className="space-y-2"
                            >
                              <p className="text-sm font-light" style={textStyle}>
                                {datosSeccionPadres.titulo3.split("\\n").map((linea, index) => (
                                  <React.Fragment key={index}>
                                    {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                                      parte.startsWith("/") && parte.endsWith("/") ? (
                                        <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                                      ) : (
                                        <span key={subIndex}>{parte}</span>
                                      )
                                    ))}
                                    <br />
                                  </React.Fragment>
                                ))}
                              </p>
                              {datosSeccionPadres.madrina2 && (
                                <p className="font-serif text-xl" style={textStyle}>
                                  {datosSeccionPadres.madrina2.split("\\n").map((linea, index) => (
                                    <React.Fragment key={index}>
                                      {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                                        parte.startsWith("/") && parte.endsWith("/") ? (
                                          <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                                        ) : (
                                          <span key={subIndex}>{parte}</span>
                                        )
                                      ))}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </p>
                              )}
                              {datosSeccionPadres.padrino2 && (
                                <p className="font-serif text-xl" style={textStyle}>
                                  {datosSeccionPadres.padrino2.split("\\n").map((linea, index) => (
                                    <React.Fragment key={index}>
                                      {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                                        parte.startsWith("/") && parte.endsWith("/") ? (
                                          <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                                        ) : (
                                          <span key={subIndex}>{parte}</span>
                                        )
                                      ))}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Empty space to show background image */}
              <div className="h-64"></div>
            </div>
        </div>
    )
}
