import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";

export const ModalAgregarEvento = ({ modalAgregar, setModalAgregar, socio, admin }) => {
  const toggleModalAgregar = () => setModalAgregar(false);
  const [submiting, setSubmiting] = useState(false)

  // Esquema de validación usando Yup
  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required("Campo requerido"),
    nombreComprador: Yup.string().required("Campo requerido"),
    numComprador: Yup.string().required("Campo requerido"),
    tipoEvento: Yup.string().required("Campo requerido"),
    paquete: Yup.string().required("Campo requerido"),
    fechaInicio: Yup.date().required("Campo requerido"),
  });

  // Función para enviar los datos del formulario
  const enviarDatos = async (values) => {
    setSubmiting(true)
    console.log(values);
    try {
      // Realizar la solicitud POST a la URL especificada con los datos del formulario
      const response = await axios.post("/api/Eventos", values);
      setSubmiting(false)
      // Manejar la respuesta de la solicitud
      console.log("Respuesta del servidor:", response.data);

      // Cerrar el modal después de enviar los datos
      toggleModalAgregar();
    } catch (error) {
      // Manejar errores en caso de que la solicitud falle
      console.error("Error al enviar los datos:", error);
      setSubmiting(false)
    }
  };

  console.log( new Date().toISOString().split('T')[0], new Date().toLocaleDateString('en-CA', { timeZone: 'America/Mexico_City' }))

  return (
    <Modal
      isOpen={modalAgregar}
      toggle={toggleModalAgregar}
      backdropOpacity={0.5}
    >
      <ModalHeader toggle={toggleModalAgregar}>Agregar Evento  - {admin}</ModalHeader>
      <Formik
        initialValues={{
          nombre: "",
          nombreComprador: "",
          numComprador: "",
          tipoEvento: "",
          paquete: "",
          monto: "",
          total: "",
          diseño: "",
          socio: socio || "",
          detalles: "",
          fechaInicio: new Date().toLocaleDateString('en-CA', { timeZone: 'America/Mexico_City' }),
          fechaFin: "Nada",
          enableCeremonia: false,
          enableConfirmacion: false,
          enableContador: false,
          enableHospedaje: false,
          enableInvitados: false,
          enablePadres: false,
          enablePortada: false,
          enablePrograma: false,
          enableRegalos: false,
          enableUbicacion: false,
          enableVestimenta: false,
          enableEvento: false,
          enableCarta: false,
          enableInsta: false,
          ingles: false,
          vendedor:admin || "",
          mail:"",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          enviarDatos(values);
        }}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label for="nombre">Nombre</Label>
                <Input
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  invalid={errors.nombre && touched.nombre}
                />
                <ErrorMessage
                  name="nombre"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="nombreComprador">Nombre del Comprador</Label>
                <Input
                  type="text"
                  name="nombreComprador"
                  id="nombreComprador"
                  value={values.nombreComprador}
                  onChange={handleChange}
                  invalid={errors.nombreComprador && touched.nombreComprador}
                />
                <ErrorMessage
                  name="nombreComprador"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="numComprador">Número del Comprador</Label>
                <Input
                  type="text"
                  name="numComprador"
                  id="numComprador"
                  value={values.numComprador}
                  onChange={handleChange}
                  invalid={errors.numComprador && touched.numComprador}
                />
                <ErrorMessage
                  name="numComprador"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="diseño">Diseño</Label>
                <Input
                  type="select"
                  name="diseño"
                  id="diseño"
                  value={values.diseño}
                  onChange={handleChange}
                  invalid={errors.diseño && touched.diseño}
                >
                  <option value="">Seleccione un tipo de diseño</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </Input>
                <ErrorMessage
                  name="diseño"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="tipoEvento">Tipo de Evento</Label>
                <Input
                  type="select"
                  name="tipoEvento"
                  id="tipoEvento"
                  value={values.tipoEvento}
                  onChange={handleChange}
                  invalid={errors.tipoEvento && touched.tipoEvento}
                >
                  <option value="">Seleccione un tipo de evento</option>
                  <option value="XV">XV</option>
                  <option value="Boda">Boda</option>
                  <option value="Evento">Evento</option>
                  <option value="Cumpleaños">Cumpleaños</option>
                  <option value="Bautizo">Bautizo</option>
                  <option value="ComunionConfirmacion">
                    Comunión/Confirmación
                  </option>
                  <option value="Sexo">Sexo</option>
                </Input>
                <ErrorMessage
                  name="tipoEvento"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>

              <FormGroup>
                <Label for="paquete">Paquete</Label>
                <Input
                  type="select"
                  name="paquete"
                  id="paquete"
                  value={values.paquete}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value === "Plata") {
                      setFieldValue("enableCeremonia", true);
                      setFieldValue("monto", "150");
                      setFieldValue("total", "295");
                      setFieldValue("enableConfirmacion", true);
                      setFieldValue("enableContador", true);
                      setFieldValue("enablePadres", true);
                    }
                    if (e.target.value === "Oro") {
                      setFieldValue("enableCeremonia", true);
                      setFieldValue("monto", "300");
                      setFieldValue("total", "595");
                      setFieldValue("enableConfirmacion", true);
                      setFieldValue("enableContador", true);
                      setFieldValue("enablePadres", true);
                      setFieldValue("enablePrograma", true);
                      setFieldValue("enableRegalos", true);
                      setFieldValue("enableVestimenta", true);
                    }
                    if (e.target.value === "Diamante") {
                      setFieldValue("monto", "450");
                      setFieldValue("total", "895");
                      setFieldValue("enableCeremonia", true);
                      setFieldValue("enableConfirmacion", true);
                      setFieldValue("enableContador", true);
                      setFieldValue("enablePadres", true);
                      setFieldValue("enablePrograma", true);
                      setFieldValue("enableRegalos", true);
                      setFieldValue("enableVestimenta", true);
                      setFieldValue("enableHospedaje", true);
                      setFieldValue("enableInvitados", true);
                    }
                  }}
                  invalid={errors.paquete && touched.paquete}
                >
                  <option value="">Seleccione un tipo de evento</option>
                  <option value="Plata">Plata</option>
                  <option value="Oro">Oro</option>
                  <option value="Diamante">Diamante</option>
                </Input>
                <ErrorMessage
                  name="paquete"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="monto">Monto</Label>
                <Input
                  type="text"
                  name="monto"
                  id="monto"
                  value={values.monto}
                  onChange={handleChange}
                  invalid={errors.monto && touched.monto}
                />
                <ErrorMessage
                  name="monto"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="total">Precio Total</Label>
                <Input
                  type="text"
                  name="total"
                  id="total"
                  value={values.total}
                  onChange={handleChange}
                  invalid={errors.total && touched.total}
                />
                <ErrorMessage
                  name="total"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                  <Label for="ingles">Invitacion en Ingles</Label>
                  <Input
                    type="checkbox"
                    id="ingles"
                    name="ingles"
                    checked={values.ingles}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ErrorMessage
                    name="enableEvento"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              <FormGroup>
                <Label for="detalles">Extras o Detalles</Label>
                <Input
                  type="text"
                  name="detalles"
                  id="detalles"
                  value={values.detalles}
                  onChange={handleChange}
                  invalid={errors.detalles && touched.detalles}
                />
                <ErrorMessage
                  name="detalles"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              {/* <FormGroup>
                <Label for="fechaInicio">Fecha de Inicio</Label>
                <Input
                  type="date"
                  name="fechaInicio"
                  id="fechaInicio"
                  value={values.fechaInicio}
                  onChange={handleChange}
                  invalid={errors.fechaInicio && touched.fechaInicio}
                />
                <ErrorMessage
                  name="fechaInicio"
                  component="div"
                  className="text-danger"
                />
              </FormGroup> */}
              <div className="grid grid-cols-3">
              <FormGroup>
                <Label for="enableCeremonia">Ceremonia</Label>
                <Input
                    type="checkbox"
                    id="enableCeremonia"
                    name="enableCeremonia"
                    checked={values?.enableCeremonia}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enableCeremonia"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="enableConfirmacion">Confirmacion</Label>
                <Input
                    type="checkbox"
                    id="enableConfirmacion"
                    name="enableConfirmacion"
                    checked={values?.enableConfirmacion}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enableConfirmacion"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="enableContador">Contador</Label>
                <Input
                    type="checkbox"
                    id="enableContador"
                    name="enableContador"
                    checked={values?.enableContador}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enableContador"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="enableHospedaje">Hospedaje</Label>
                <Input
                    type="checkbox"
                    id="enableHospedaje"
                    name="enableHospedaje"
                    checked={values?.enableHospedaje}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enableHospedaje"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="enableInvitados">Invitados</Label>
                <Input
                    type="checkbox"
                    id="enableInvitados"
                    name="enableInvitados"
                    checked={values?.enableInvitados}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enableInvitados"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="enablePadres">Padres</Label>
                <Input
                    type="checkbox"
                    id="enablePadres"
                    name="enablePadres"
                    checked={values?.enablePadres}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enablePadres"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="enablePrograma">Programa</Label>
                <Input
                    type="checkbox"
                    id="enablePrograma"
                    name="enablePrograma"
                    checked={values?.enablePrograma}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enablePrograma"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="enableRegalos">Regalos</Label>
                <Input
                    type="checkbox"
                    id="enableRegalos"
                    name="enableRegalos"
                    checked={values?.enableRegalos}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enableRegalos"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              {/* <FormGroup>
                <Label for="enableUbicacion">Ubicacion</Label>
                <Input
                    type="checkbox"
                    id="enableUbicacion"
                    name="enableUbicacion"
                    checked={values?.enableUbicacion}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enableUbicacion"
                  component="div"
                  className="text-danger"
                />
              </FormGroup> */}
              <FormGroup>
                <Label for="enableVestimenta">Vestimenta</Label>
                <Input
                    type="checkbox"
                    id="enableVestimenta"
                    name="enableVestimenta"
                    checked={values?.enableVestimenta}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enableVestimenta"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label for="enableInsta">Instagram</Label>
                <Input
                    type="checkbox"
                    id="enableInsta"
                    name="enableInsta"
                    checked={values?.enableInsta}
                    onChange={handleChange}
                  />
                <ErrorMessage
                  name="enableInsta"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" disabled={submiting} color="primary">
                {submiting ? "Agregando..." : "Agregar"}
              </Button>
              <Button color="secondary" onClick={toggleModalAgregar}>
                Cancelar
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
