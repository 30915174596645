'use client'

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

export const ConfirmacionBodaComponent2 = ({datosSeccionConfirmacion, seccionImagenes, diamante}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [dragStart, setDragStart] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useState({
    nombre: '',
    asistencia: '',
    personas: '',
    comentarios: ''
  })

  const fontFamilyStyle = {
    fontFamily: datosSeccionConfirmacion?.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const textStyle = {
    ...fontFamilyStyle,
    color: '#000000'
  };

  const photos = [
    'https://images.unsplash.com/photo-1519741497674-611481863552?w=800&h=600&fit=crop',
    'https://images.unsplash.com/photo-1511285560929-80b456fea0bc?w=800&h=600&fit=crop', 
    'https://images.unsplash.com/photo-1465495976277-4387d4b0b4c6?w=800&h=600&fit=crop',
    'https://images.unsplash.com/photo-1460364157752-926555421a7e?w=800&h=600&fit=crop',
  ]

  const handleDragStart = (event) => {
    setIsDragging(true)
    const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX
    setDragStart(clientX)
  }

  const handleDragEnd = (event) => {
    if (!isDragging) return

    const clientX = 'changedTouches' in event ? event.changedTouches[0].clientX : event.clientX
    const delta = dragStart - clientX

    if (Math.abs(delta) > 100) { // Minimum drag distance
      if (delta > 0 && currentIndex < seccionImagenes.length - 1) {
        setCurrentIndex(prev => prev + 1)
      } else if (delta < 0 && currentIndex > 0) {
        setCurrentIndex(prev => prev - 1)
      }
    }

    setIsDragging(false)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = (event, type) => {
    event.preventDefault();
    setSubmitted(true);

    // Construir mensaje de WhatsApp
    const message = `¡Hola! Soy ${formData.nombre}.\n
${formData.asistencia === 'si' ? `¡Confirmo mi asistencia con ${formData.personas} ${formData.personas === '1' ? 'persona' : 'personas'}!` : 'Lamento no poder asistir.'}\n
${formData.comentarios ? `Mensaje: ${formData.comentarios}` : ''}`;

    // Codificar el mensaje para URL
    const encodedMessage = encodeURIComponent(message);
    
    // Número de WhatsApp desde los datos
    const phoneNumber = datosSeccionConfirmacion?.numeroConfirmacion || '0';
    
    // Abrir WhatsApp en nueva pestaña
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
    window.open(whatsappUrl);

    setTimeout(() => setSubmitted(false), 2000);
  }

  return (
    <div className="min-h-screen py-12" style={{
      backgroundImage: `url(${datosSeccionConfirmacion?.imgFondo})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: datosSeccionConfirmacion?.textColor || 'inherit',
      ...fontFamilyStyle
    }}>
      <div className="mx-auto max-w-md px-4">
        {/* Photo Gallery */}
        {seccionImagenes && seccionImagenes?.length > 0 && (
          <div className="mb-16">
            <div className="relative">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentIndex}
                  initial={{ x: 300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -300, opacity: 0 }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  className="relative"
                  onMouseDown={handleDragStart}
                  onMouseUp={handleDragEnd}
                  onMouseLeave={handleDragEnd}
                  onTouchStart={handleDragStart}
                  onTouchEnd={handleDragEnd}
                  style={{ touchAction: 'pan-y pinch-zoom' }}
                >
                  {/* Stacked photos effect */}
                  <div className="absolute -bottom-2 left-1 right-1 top-2 rotate-[-4deg] rounded-lg bg-white shadow-md" />
                  <div className="absolute -bottom-1 left-0.5 right-0.5 top-1 rotate-[-2deg] rounded-lg bg-white shadow-md" />
                  
                  {/* Main photo */}
                  <div className="relative rounded-lg bg-white p-4 shadow-xl">
                    <div className="overflow-hidden rounded-md">
                      <img
                        src={seccionImagenes[currentIndex]}
                        alt={`Wedding photo ${currentIndex + 1}`}
                        className="aspect-[4/5] w-full object-cover"
                      />
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>

              {/* Swipe indicator */}
              <div className="mt-8 text-center">
                <div className="mb-2 flex justify-center">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    className="h-8 w-8 text-gray-600"
                    style={textStyle}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M8 7h8m-8 5h8m-8 5h8"
                      style={textStyle}
                    />
                  </svg>
                </div>
                <p className="font-serif text-sm text-gray-600"  style={{...fontFamilyStyle, ...textStyle}}>
                  Desliza para ver más fotos
                </p>
              </div>

              {/* Photo counter */}
              <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 rounded-full bg-white/80 px-3 py-1 text-sm font-light text-gray-800">
                {currentIndex + 1} / {seccionImagenes.length}
              </div>
            </div>
          </div>
        )}

        {/* RSVP Form */}
        {!diamante && (
          <div className="rounded-lg bg-white p-6 shadow-xl">
          <h2 className="mb-6 text-center font-serif text-2xl font-semibold text-gray-800" style={fontFamilyStyle}>
          Confirmación de Asistencia
          </h2>
          <form onSubmit={(e) => handleSubmit(e, 'novia')} className="space-y-4">
            <div>
              <label htmlFor="nombre" className="mb-1 block text-sm font-medium text-gray-700" style={fontFamilyStyle}>
                Nombre de la Familia
              </label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-[#9E8B8E] focus:outline-none focus:ring-1 focus:ring-[#9E8B8E]"
                placeholder="Fam. González Molina"
                required
                style={fontFamilyStyle}
              />
            </div>
            <div>
              <label htmlFor="asistencia" className="mb-1 block text-sm font-medium text-gray-700" style={fontFamilyStyle}>
                ¿Asistirás?
              </label>
              <select
                id="asistencia"
                name="asistencia"
                value={formData.asistencia}
                onChange={handleInputChange}
                className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-[#9E8B8E] focus:outline-none focus:ring-1 focus:ring-[#9E8B8E]"
                required
                style={fontFamilyStyle}
              >
                <option value="">Selecciona una opción</option>
                <option value="si">¡Sí, por supuesto!</option>
                <option value="no">Lo siento, no podré asistir</option>
                <option value="talvez">Tal vez, confirmaré después</option>
              </select>
            </div>
            <div>
              <label htmlFor="personas" className="mb-1 block text-sm font-medium text-gray-700" style={fontFamilyStyle}>
                ¿Cuántas personas asistirán?
              </label>
              <select
                id="personas"
                name="personas"
                value={formData.personas}
                onChange={handleInputChange}
                className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-[#9E8B8E] focus:outline-none focus:ring-1 focus:ring-[#9E8B8E]"
                required
                style={fontFamilyStyle}
              >
                <option value="">Selecciona el número de personas</option>
                {[1, 2, 3, 4, 5, 6].map((num) => (
                  <option key={num} value={num.toString()}>
                    {num} {num === 1 ? 'persona' : 'personas'}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="comentarios" className="mb-1 block text-sm font-medium text-gray-700" style={fontFamilyStyle}>
                Comentarios y Felicitaciones
              </label>
              <textarea
                id="comentarios"
                name="comentarios"
                value={formData.comentarios}
                onChange={handleInputChange}
                rows={3}
                className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-[#9E8B8E] focus:outline-none focus:ring-1 focus:ring-[#9E8B8E]"
                placeholder="Escribe tus felicitaciones o comentarios aquí..."
                style={fontFamilyStyle}
              ></textarea>
            </div>
            <div className="grid gap-4 pt-4 sm:grid-cols-2">
              <button
                type="submit"
                className="w-full rounded-md bg-[#9E8B8E] px-4 py-2 text-white transition-colors hover:bg-[#8A7477] focus:outline-none focus:ring-2 focus:ring-[#9E8B8E] focus:ring-offset-2"
                disabled={submitted}
                style={fontFamilyStyle}
              >
                {submitted ? '¡Enviado!' : 'Confirmar'}
              </button>
            </div>
          </form>
        </div>
        )}
      </div>
    </div>
  )
}