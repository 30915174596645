import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import axios from "axios";
import { Chart, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { validateToken } from "../../../components/validateToken";

Chart.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [dataDiaria, setDataDiaria] = useState(null);
  const [dataMensual, setDataMensual] = useState(null);
  const [dataQuincenal, setDataQuincenal] = useState(null);
  const [dataVendedoras, setDataVendedoras] = useState(null);
  const [historialSemanal, setHistorialSemanal] = useState(null);
  const [ultimoDatoDiaria, setUltimoDatoDiaria] = useState(null);
  const [userName, setUserName] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedWeek, setSelectedWeek] = useState(getWeekNumber(new Date()));
  const [totalVentas, setTotalVentas] = useState(0);
  const [promedioVentasDiarias, setPromedioVentasDiarias] = useState(0);
  const [tendenciaVentas, setTendenciaVentas] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [weekDates, setWeekDates] = useState({ start: null, end: null });
  const [selectedDate, setSelectedDate] = useState('');
  const [eventosMontoMenorQueTotal, setEventosMontoMenorQueTotal] = useState([]);
  const [filterFechaFin, setFilterFechaFin] = useState('');

  useEffect(() => {
    const validateAndFetchData = async () => {
      const admin = await validateToken();
      if (admin) setUserName(admin);

      const fetchVentasDiarias = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-diarias", {
            params: { year: 2024, month: selectedMonth },
          });
          const data = response.data;
          console.log(data);
          setDataDiaria(data);
          setUltimoDatoDiaria(data[data.length - 1]); 
          
          const totalVentas = data.reduce((sum, day) => sum + day.totalTotales, 0);
          setTotalVentas(totalVentas);
          setPromedioVentasDiarias(totalVentas / data.length);

          setTendenciaVentas(data.map(day => day.totalTotales));
        } catch (error) {
          console.error("Error fetching daily sales data", error);
        }
      };

      const fetchVentasMensuales = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-mensuales", {
            params: { year: 2024 },
          });
          setDataMensual(response.data);
        } catch (error) {
          console.error("Error fetching monthly sales data", error);
        }
      };

      const fetchVentasQuincenales = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-quincenales", {
            params: { year: 2024 },
          });
          setDataQuincenal(response.data);
        } catch (error) {
          console.error("Error fetching biweekly sales data", error);
        }
      };

      const fetchVentasVendedoras = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-diarias-vendedoras", {
            params: { year: 2024, weekNumber: selectedWeek },
          });
          setDataVendedoras(response.data);
          console.error("seller", response.data);
        } catch (error) {
          console.error("Error fetching sales data by seller", error);
        }
      };

      const fetchHistorial = async () => {
        try {
          const response = await axios.get("/api/Eventos/historial-semanal", {
            params: { year: 2024, weekNumber: selectedWeek },
          });
          setHistorialSemanal(response.data);
          console.log("historial", response.data);
        } catch (error) {
          console.error("Error fetching weekly history data", error);
        }
      };

      const fetchEventosMontoMenorQueTotal = async () => {
        try {
          const response = await axios.get("/api/Eventos/monto-menor-que-total");
          setEventosMontoMenorQueTotal(response.data);
          console.log(response.data)
        } catch (error) {
          console.error("Error fetching events with monto less than total", error);
        }
      };

      fetchHistorial();
      fetchVentasDiarias();
      fetchVentasMensuales();
      fetchVentasQuincenales();
      fetchVentasVendedoras();
      fetchEventosMontoMenorQueTotal();
    };

    validateAndFetchData();
    setWeekDates(getWeekDates(2024, selectedWeek));
  }, [selectedMonth, selectedWeek]);

  if (!dataDiaria || !dataMensual || !dataQuincenal || !dataVendedoras || !historialSemanal) return <LoadingComponent />;

  const handleMonthChange = (event) => {
    setSelectedMonth(Number(event.target.value));
  };

  const handleWeekChange = (event) => {
    setSelectedWeek(Number(event.target.value));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const chartDataDiaria = {
    labels: dataDiaria.map(d => `Día ${d.dia}`),
    datasets: [
      {
        label: "Ventas Diarias",
        data: dataDiaria.map(d => ({
          x: `Día ${d.dia}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
          cantidadEventos: d?.cantidadEventos,
        })),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const chartDataMensual = {
    labels: dataMensual.map(d => `Mes ${d.mes}`),
    datasets: [
      {
        label: "Ventas Mensuales",
        data: dataMensual.map(d => ({
          x: `Mes ${d.mes}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
        })),
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };

  const chartDataQuincenal = {
    labels: [...new Set(dataQuincenal.map(d => `Mes ${d.mes}`))],
    datasets: dataQuincenal.map((d, index) => ({
      label: `Quincena ${d.quincena}`,
      data: [
        {
          x: `Mes ${d.mes}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
          fechaInicio: d.fechaInicio,
          fechaFin: d.fechaFin,
        },
      ],
      backgroundColor: `rgba(${255 - index * 40}, ${159 - index * 20}, 64, 0.6)`,
    })),
  };

  const tendenciaVentasData = {
    labels: dataDiaria.map(d => `Día ${d.dia}`),
    datasets: [
      {
        label: "Tendencia de Ventas",
        data: tendenciaVentas,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1
      }
    ]
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataPoint = context.raw;
            return [
              `Eventos: ${dataPoint?.cantidadEventos}`,
              `Montos: ${dataPoint.totalMontos}`,
              `Totales: ${dataPoint.y}`,
              `Rango: ${new Date(dataPoint.fechaInicio).toLocaleDateString()} - ${new Date(dataPoint.fechaFin).toLocaleDateString()}`
            ];
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gradient-to-br from-gray-50 to-gray-200">
      <button 
        className="md:hidden fixed top-4 left-4 z-50 bg-blue-600 hover:bg-blue-700 text-white p-3 rounded-full shadow-lg transition duration-300"
        onClick={toggleSidebar}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
      <aside
      style={{
        overflowY: 'scroll',
        scrollbarWidth: 'none', /* Firefox */
        msOverflowStyle: 'none', /* IE/Edge */
        '&::-webkit-scrollbar': { display: 'none' } /* Chrome/Safari/Opera */
      }}
      className={`w-full md:h-screen md:sticky md:top-0 md:w-[20%] bg-gradient-to-b from-blue-900 via-blue-800 to-blue-700 text-white ${isSidebarOpen ? 'block' : 'hidden'} md:block shadow-xl fixed md:relative z-40 h-full transition-all duration-300 ease-in-out`}>
        <div className="p-6 text-2xl font-bold border-b border-blue-600 flex items-center space-x-3">
          <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span>DIGITAL RSVP</span>
        </div>
        {userName && (
          <div className="p-4 text-center font-medium bg-blue-800 border-b border-blue-700">
            <div className="text-lg">EXCELENTE DÍA</div>
            <div className="text-xl font-bold mt-1">{userName} 🌟</div>
          </div>
        )}
        <nav className="mt-6">
          <ul className="space-y-1">
            {['Ventas'].map((item) => (
              <li key={item} className="mx-2">
                <button className="w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 ease-in-out flex items-center space-x-3">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                  <span>{item}</span>
                </button>
              </li>
            ))}
            <li className="mx-2">
              <a href="/admins-control" className="block">
                <button className="w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 ease-in-out flex items-center space-x-3">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path className="text-white" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  <span className="text-white">Administrar Usuarios</span>
                </button>
              </a>
            </li>
          </ul>
        </nav>
        {ultimoDatoDiaria && (
          <div className="p-6 bg-gradient-to-r from-blue-800 to-blue-700 mt-8 rounded-xl mx-3 shadow-lg">
            <div className="flex items-center space-x-3 mb-4 pb-2 border-b border-blue-600">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
              </svg>
              <h3 className="text-lg font-semibold">Última Venta</h3>
            </div>
            <div className="space-y-3">
              {[
                { label: 'Fecha', value: `Día ${ultimoDatoDiaria.dia}` },
                { label: 'Total de Ventas', value: ultimoDatoDiaria.cantidadEventos },
                { label: 'Total Monto', value: `$${ultimoDatoDiaria.totalMontos}` },
                { label: 'Total Total', value: `$${ultimoDatoDiaria.totalTotales}` }
              ].map((item) => (
                <div key={item.label} className="flex justify-between items-center">
                  <span className="text-gray-300">{item.label}:</span>
                  <span className="font-medium text-white">{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </aside>

      <main className="flex-grow p-6 md:w-[80%] md:p-8 mt-16 md:mt-0 space-y-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-4xl font-bold text-gray-800">
            Dashboard de Ventas
            <div className="h-1 w-20 bg-blue-600 mt-2 rounded-full"></div>
          </h1>
          <div className="text-sm text-gray-500">
            {new Date().toLocaleDateString('es-MX', { 
              weekday: 'long', 
              year: 'numeric', 
              month: 'long', 
              day: 'numeric' 
            })}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300">
            <label htmlFor="month" className="block text-lg font-semibold mb-3 text-gray-700">Selecciona el Mes:</label>
            <select 
              id="month" 
              value={selectedMonth} 
              onChange={handleMonthChange} 
              className="p-3 border border-gray-300 rounded-lg w-full focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
            >
              {[...Array(12).keys()].map(i => (
                <option key={i} value={i + 1}>
                  {new Date(2024, i).toLocaleString('default', { month: 'long' })}
                </option>
              ))}
            </select>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300">
            <label htmlFor="week" className="block text-lg font-semibold mb-3 text-gray-700">Selecciona la Semana:</label>
            <select 
              id="week" 
              value={selectedWeek} 
              onChange={handleWeekChange} 
              className="p-3 border border-gray-300 rounded-lg w-full focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
            >
              {[...Array(52).keys()].map(i => (
                <option key={i} value={i + 1}>Semana {i + 1}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            { title: 'Total Ventas', value: `$${totalVentas.toFixed(2)}`, color: 'blue' },
            { title: 'Promedio Diario', value: `$${promedioVentasDiarias.toFixed(2)}`, color: 'green' },
            { title: 'Eventos Totales', value: dataDiaria.reduce((sum, day) => sum + day.cantidadEventos, 0), color: 'purple' }
          ].map((stat, index) => (
            <div key={index} className={`bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300 border-l-4 border-${stat.color}-500`}>
              <h3 className="text-lg font-semibold text-gray-600 mb-2">{stat.title}</h3>
              <p className={`text-3xl font-bold text-${stat.color}-600`}>{stat.value}</p>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {[
            { title: 'Ventas Diarias', data: chartDataDiaria, component: Bar },
            { title: 'Ventas Mensuales', data: chartDataMensual, component: Bar },
            { title: 'Ventas Quincenales', data: chartDataQuincenal, component: Bar },
            { title: 'Tendencia de Ventas', data: tendenciaVentasData, component: Line }
          ].map((chart, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300 h-[500px]">
              <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
                <span className="h-8 w-2 bg-blue-500 rounded-full mr-3"></span>
                {chart.title}
              </h2>
              <div className="h-[400px]">
                <chart.component data={chart.data} options={options} />
              </div>
            </div>
          ))}
        </div>

        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-bold text-gray-800 flex items-center">
              <span className="h-8 w-2 bg-blue-500 rounded-full mr-3"></span>
              Eventos por Vendedor (Semana {selectedWeek})
            </h2>
            <p className="text-sm text-gray-600">
              {weekDates.start?.toLocaleDateString()} - {weekDates.end?.toLocaleDateString()}
            </p>
          </div>
          <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-50">
                  <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Vendedor</th>
                  <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Cantidad de Eventos</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(dataVendedoras).map(([vendedor, cantidad]) => (
                  <tr key={vendedor} className="hover:bg-gray-50 transition duration-200">
                    <td className="border border-gray-200 p-3 capitalize">{vendedor}</td>
                    <td className="border border-gray-200 p-3">{cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {historialSemanal && Object.entries(historialSemanal).map(([vendedor, datos], index) => {
            const filteredEventos = selectedDate 
              ? datos.eventos.filter(evento => evento.fecha.split('T')[0] === selectedDate)
              : datos.eventos;
              
            return (
              <div key={index} className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300">
                <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
                  <span className="h-8 w-2 bg-blue-500 rounded-full mr-3"></span>
                  Historial de {datos.nombre}
                </h2>
                <div className="space-y-4">
                  <div className="flex items-center space-x-4">
                    <input 
                      type="date" 
                      className="flex-grow p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                    />
                    {selectedDate && (
                      <button 
                        className="p-3 text-blue-500 hover:text-blue-700 hover:bg-blue-50 rounded-lg transition duration-300"
                        onClick={() => setSelectedDate('')}
                      >
                        Mostrar todos
                      </button>
                    )}
                  </div>
                  <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-50">
                          <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Fecha</th>
                          <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Nombre</th>
                          <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Tipo</th>
                          <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Evento Id</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredEventos.map((evento, eventoIndex) => (
                          <tr key={eventoIndex} className="hover:bg-gray-50 transition duration-200">
                            <td className="border border-gray-200 p-3">
                              {new Date(evento.fecha).toLocaleDateString('es-MX', {
                                year: 'numeric',
                                month: '2-digit', 
                                day: '2-digit'
                              })}
                            </td>
                            <td className="border border-gray-200 p-3">{evento.nombre}</td>
                            <td className="border border-gray-200 p-3">{evento.tipo}</td>
                            <td className="border border-gray-200 p-3">{evento.evento}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <p className="text-lg font-semibold text-gray-700">
                    Total de eventos: {filteredEventos.length}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300">
          <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
            <span className="h-8 w-2 bg-blue-500 rounded-full mr-3"></span>
            Eventos con Monto Menor que Total
          </h2>
          <div className="mb-6">
            <label htmlFor="filterFechaFin" className="block text-lg font-semibold mb-3 text-gray-700">
              Filtrar por FechaFin:
            </label>
            <select 
              id="filterFechaFin" 
              className="p-3 border border-gray-300 rounded-lg w-full md:w-1/3 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
              onChange={(e) => setFilterFechaFin(e.target.value)}
            >
              <option value="">Mostrar todos</option>
              {['Formateado', 'Formulario', 'Nada', 'Entregado'].map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-50">
                  {[
                    'Id', 'Nombre', 'Num Comprador', 'Tipo Evento', 'Paquete',
                    'Fecha Inicio', 'Fecha Fin', 'Monto', 'Detalles', 'Total',
                    'Socio', 'Enable Evento'
                  ].map(header => (
                    <th key={header} className="border border-gray-200 p-3 text-left font-semibold text-gray-600">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {eventosMontoMenorQueTotal
                  .filter(evento => !filterFechaFin || evento.fechaFin === filterFechaFin)
                  .map((evento, index) => {
                    const statusColors = {
                      'Formateado': 'bg-blue-50',
                      'Formulario': 'bg-yellow-50',
                      'Nada': 'bg-red-50',
                      'Entregado': 'bg-green-50'
                    };
                    const rowColor = statusColors[evento.fechaFin] || '';
                    return (
                      <tr key={index} className={`${rowColor} hover:bg-opacity-75 transition duration-200`}>
                        <td className="border border-gray-200 p-3">{evento.idEvento}</td>
                        <td className="border border-gray-200 p-3">{evento.nombre}</td>
                        <td className="border border-gray-200 p-3">{evento.numComprador}</td>
                        <td className="border border-gray-200 p-3">{evento.tipoEvento}</td>
                        <td className="border border-gray-200 p-3">{evento.paquete}</td>
                        <td className="border border-gray-200 p-3">{evento.fechaInicio}</td>
                        <td className="border border-gray-200 p-3">{evento.fechaFin}</td>
                        <td className="border border-gray-200 p-3">{evento.monto}</td>
                        <td className="border border-gray-200 p-3">{evento.detalles}</td>
                        <td className="border border-gray-200 p-3">{evento.total}</td>
                        <td className="border border-gray-200 p-3">{evento.socio}</td>
                        <td className="border border-gray-200 p-3">
                          <span className={`px-2 py-1 rounded-full ${evento.enableEvento ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                            {evento.enableEvento ? 'Sí' : 'No'}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <p className="mt-6 text-lg font-semibold text-gray-700">
            Total de eventos: {eventosMontoMenorQueTotal.filter(evento => !filterFechaFin || evento.fechaFin === filterFechaFin).length}
          </p>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;

function getWeekNumber(d) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  return weekNo;
}

function getWeekDates(year, weekNumber) {
  const simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  const dow = simple.getDay();
  const startDate = simple;
  if (dow <= 4)
    startDate.setDate(simple.getDate() - simple.getDay() + 1);
  else
    startDate.setDate(simple.getDate() + 8 - simple.getDay());
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 6);
  return { start: startDate, end: endDate };
}
