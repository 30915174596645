import React from 'react';
import { Input, Label } from "reactstrap";

export const GiftRegistryForm = ({ values, handleChange, handleInputChange, errors, touched }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-8 max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">MESA DE REGALOS</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <Label htmlFor="D8linkRegalo1" className="block text-sm font-medium text-gray-700 mb-1">
            Link de la Mesa de Regalos 1
          </Label>
          <Input
            id="D8linkRegalo1"
            type="text"
            name="D8linkRegalo1"
            onChange={(e) => { handleChange(e); handleInputChange(e); }}
            value={values.D8linkRegalo1}
            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D8linkRegalo1 ? "border-red-500" : ""}`}
          />
          {touched.D8linkRegalo1 && errors.D8linkRegalo1 && (
            <p className="mt-2 text-sm text-red-600">{errors.D8linkRegalo1}</p>
          )}
        </div>
        <div>
          <Label htmlFor="D8linkRegalo2" className="block text-sm font-medium text-gray-700 mb-1">
            Link de la Mesa de Regalos 2
          </Label>
          <Input
            id="D8linkRegalo2"
            type="text"
            name="D8linkRegalo2"
            onChange={(e) => { handleChange(e); handleInputChange(e); }}
            value={values.D8linkRegalo2}
            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D8linkRegalo2 ? "border-red-500" : ""}`}
          />
          {touched.D8linkRegalo2 && errors.D8linkRegalo2 && (
            <p className="mt-2 text-sm text-red-600">{errors.D8linkRegalo2}</p>
          )}
        </div>
        <div>
          <Label htmlFor="D8linkRegalo3" className="block text-sm font-medium text-gray-700 mb-1">
            Link de la Mesa de Regalos 3
          </Label>
          <Input
            id="D8linkRegalo3"
            type="text"
            name="D8linkRegalo3"
            onChange={(e) => { handleChange(e); handleInputChange(e); }}
            value={values.D8linkRegalo3}
            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D8linkRegalo3 ? "border-red-500" : ""}`}
          />
          {touched.D8linkRegalo3 && errors.D8linkRegalo3 && (
            <p className="mt-2 text-sm text-red-600">{errors.D8linkRegalo3}</p>
          )}
        </div>
      </div>
    </div>
  );
}; 