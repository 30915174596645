import React, { useState } from "react";
import axios from "axios";
import { FaUpload } from 'react-icons/fa';

const ImageUploadForm = ({ onUploadSuccess }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageType, setImageType] = useState("Icon");
  const [uploadDate, setUploadDate] = useState(new Date().toISOString().slice(0, 19).replace('T', ' '));

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedImage) {
      setUploadStatus("Por favor, selecciona una imagen.");
      return;
    }

    const formData = new FormData();
    formData.append("imageFile", selectedImage);
    formData.append("imageName", imageName);
    formData.append("imageType", imageType);
    formData.append("uploadDate", uploadDate);

    try {
      setUploadStatus("Cargando...");
      const response = await axios.post("/api/Images/UploadImage", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setUploadStatus("Imagen subida exitosamente.");
      console.log("Respuesta del servidor:", response.data);
      onUploadSuccess();
    } catch (error) {
      setUploadStatus("Error al subir la imagen.");
      console.error("Error:", error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white p-4 rounded shadow-lg">
      <h1 className="text-xl font-bold mb-4">Subir Imagen</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Selecciona una imagen:
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nombre de la imagen:
          </label>
          <input
            type="text"
            value={imageName}
            onChange={(e) => setImageName(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Tipo:
          </label>
          <select
            value={imageType}
            onChange={(e) => setImageType(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          >
            <option value="Icon">Iconos</option>
            <option value="Fondo">Fondo</option>
          </select>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          <FaUpload className="mr-2" /> Subir
        </button>
      </form>
      {uploadStatus && <p className="mt-4 text-center">{uploadStatus}</p>}
    </div>
  );
};

export default ImageUploadForm;
