import React from "react";
import { FaPhotoVideo } from "react-icons/fa";
import { FaChampagneGlasses } from "react-icons/fa6";
import { GiBigDiamondRing, GiMeshBall } from "react-icons/gi";
import { ImSpoonKnife } from "react-icons/im";
import { PiChurchLight } from "react-icons/pi";

export const ProgramaBodaComponent = ({ peltaColores, datosSeccionPrograma }) => {

  const fontFamilyStyle = { fontFamily: datosSeccionPrograma.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  const traducirTexto = (español, ingles) => datosSeccionPrograma.ingles ? ingles : español;

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionPrograma.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccionPrograma.textColor || "",
        ...fontFamilyStyle,
      }}
      className="sm:h-auto md:h-auto flex flex-col items-center sm:py-[5vh] md:pt-[15vh] w-full"
    >
      <div className="flex flex-col w-full justify-center items-center p-2">
        <h1 className="font-serif text-center sm:text-[32px] md:text-[42px]" data-aos="flip-up" style={fontFamilyStyle}>
          {datosSeccionPrograma.titulo || traducirTexto("Programa", "Program")}
        </h1>
        <br />
        <p className="text-center pt-[5vh] font-serif text-xl" data-aos="flip-up" style={fontFamilyStyle}>
          {datosSeccionPrograma.descripcion || traducirTexto("Hemos planeado un día lleno de diversión para disfrutar al máximo.", "We have planned a day full of fun to enjoy to the fullest.")}
        </p>

        <div className="grid sm:grid-cols-1 md:grid-cols-2 w-[80%] pt-[10vh]">
          {/* Ceremonia Religiosa */}
          {datosSeccionPrograma.ceremoniaReligiosa && (
            <div className="flex flex-col justify-center items-center" data-aos="fade-right">
              {datosSeccionPrograma.ceremoniaReligiosaImg ? (
                <img src={datosSeccionPrograma.ceremoniaReligiosaImg} className="w-[70px] h-[70px]" alt={traducirTexto("Ceremonia Religiosa", "Religious Ceremony")} />
              ) : (
                <PiChurchLight className="text-[60px]" />
              )}
              <br />
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {datosSeccionPrograma.ceremoniaReligiosaTitulo || traducirTexto("CEREMONIA RELIGIOSA", "RELIGIOUS CEREMONY")}
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]" style={fontFamilyStyle}>
                {datosSeccionPrograma.ceremoniaReligiosa.split("\\n").map((linea, index) => (
                  <React.Fragment key={index}>{linea}<br /></React.Fragment>
                ))}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>
          )}

          {/* Recepción */}
          {datosSeccionPrograma.recepcion && (
            <div className="flex flex-col justify-center items-center" data-aos="fade-right">
              {datosSeccionPrograma.recepcionImg ? (
                <img src={datosSeccionPrograma.recepcionImg} className="w-[70px] h-[70px]" alt={traducirTexto("Recepción", "Reception")} />
              ) : (
                <FaPhotoVideo className="text-[60px]" />
              )}
              <br />
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {datosSeccionPrograma.recepcionTitulo || traducirTexto("RECEPCIÓN", "RECEPTION")}
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]" style={fontFamilyStyle}>
                {datosSeccionPrograma.recepcion.split("\\n").map((linea, index) => (
                  <React.Fragment key={index}>{linea}<br /></React.Fragment>
                ))}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>
          )}

          {/* Otros bloques: Civil, Cena, Cóctel, Baile */}
          {/* Civil */}
          {datosSeccionPrograma.civil && (
            <div className="flex flex-col justify-center items-center" data-aos="fade-right">
              {datosSeccionPrograma.civilImg ? (
                <img src={datosSeccionPrograma.civilImg} className="w-[70px] h-[70px]" alt={traducirTexto("Ceremonia Civil", "Civil Ceremony")} />
              ) : (
                <GiBigDiamondRing className="text-[60px]" />
              )}
              <br />
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {datosSeccionPrograma.civilTitulo || traducirTexto("CEREMONIA CIVIL", "CIVIL CEREMONY")}
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]" style={fontFamilyStyle}>
                {datosSeccionPrograma.civil.split("\\n").map((linea, index) => (
                  <React.Fragment key={index}>{linea}<br /></React.Fragment>
                ))}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>
          )}

          {/* Cena */}
          {datosSeccionPrograma.cena && (
            <div className="flex flex-col justify-center items-center" data-aos="fade-right">
              {datosSeccionPrograma.cenaImg ? (
                <img src={datosSeccionPrograma.cenaImg} className="w-[70px] h-[70px]" alt={traducirTexto("Cena", "Dinner")} />
              ) : (
                <ImSpoonKnife className="text-[60px]" />
              )}
              <br />
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {datosSeccionPrograma.cenaTitulo || traducirTexto("CENA", "DINNER")}
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]" style={fontFamilyStyle}>
                {datosSeccionPrograma.cena.split("\\n").map((linea, index) => (
                  <React.Fragment key={index}>{linea}<br /></React.Fragment>
                ))}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>
          )}

          {/* Cóctel */}
          {datosSeccionPrograma.coctel && (
            <div className="flex flex-col justify-center items-center" data-aos="fade-right">
              {datosSeccionPrograma.coctelImg ? (
                <img src={datosSeccionPrograma.coctelImg} className="w-[70px] h-[70px]" alt={traducirTexto("Cóctel", "Cocktail")} />
              ) : (
                <FaChampagneGlasses className="text-[60px]" />
              )}
              <br />
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {datosSeccionPrograma.coctelTitulo || traducirTexto("CÓCTEL", "COCKTAIL")}
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]" style={fontFamilyStyle}>
                {datosSeccionPrograma.coctel.split("\\n").map((linea, index) => (
                  <React.Fragment key={index}>{linea}<br /></React.Fragment>
                ))}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>
          )}

          {/* Baile */}
          {datosSeccionPrograma.baile && (
            <div className="flex flex-col justify-center items-center" data-aos="fade-right">
              {datosSeccionPrograma.baileImg ? (
                <img src={datosSeccionPrograma.baileImg} className="w-[70px] h-[70px]" alt={traducirTexto("Baile", "Dance")} />
              ) : (
                <GiMeshBall className="text-[60px]" />
              )}
              <br />
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {datosSeccionPrograma.baileTitulo || traducirTexto("BAILE", "DANCE")}
              </p>
              <br />
              <p className="font-serif text-xl text-center w-[60%]" style={fontFamilyStyle}>
                {datosSeccionPrograma.baile.split("\\n").map((linea, index) => (
                  <React.Fragment key={index}>{linea}<br /></React.Fragment>
                ))}
              </p>
              <br />
              <hr className="bg-gray-700 h-1 w-[60%]" />
              <br />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};