import React, { useEffect, useRef } from "react";
import { FaWhatsapp } from "react-icons/fa";
import Slider from "react-slick";
import confetti from 'canvas-confetti';

export const ConfirmacionDiamXvComponent = ({
  peltaColores,
  images,
  messageWhats,
  datosSeccionConfirmacion,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2048,
    cssEase: "linear",
  };

  const componentRef = useRef(null);

  // Aplica el tipo de letra si está definido
  const fontFamilyStyle = {
    fontFamily: datosSeccionConfirmacion.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  useEffect(() => {
    if (datosSeccionConfirmacion.confetti === true) {
      return; // No inicializar el observer si confetti es true
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          confetti({
            particleCount: 100,
            spread: 75,
            origin: { y: 0.6 }
          });
          observer.disconnect(); // Desconectar después de lanzar el confetti
        }
      },
      { threshold: 0.5 } // Lanzar cuando al menos el 50% del componente esté visible
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, [datosSeccionConfirmacion.confetti]);

  const handleShare = (message) => {
    if (navigator.share) {
      navigator
        .share({
          text: message, // Enviar solo el mensaje
        })
        .then(() => console.log("Thanks for sharing!"))
        .catch(console.error);
    } else {
      console.error("Web Share API not supported");
    }
  };

  return (
    <div
      ref={componentRef}
      style={{
        backgroundImage: `url(${datosSeccionConfirmacion.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccionConfirmacion.textColor,
        ...fontFamilyStyle, // Aplicar el tipo de letra a todo el div
      }}
      className="sm:auto md:h-auto flex flex-col justify-center sm:py-[5vh] md:pt-[15vh] items-center w-full "
    >
      <h1
        className="text-center sm:text-[22px] w-[90%] md:text-[42px]"
        data-aos="flip-up"
        style={fontFamilyStyle} // Aplicar el tipo de letra al h1
      >
        {datosSeccionConfirmacion.tituloConfirmacion
          .split("\\n")
          .map((linea, index) => (
            <React.Fragment key={index}>
              {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                parte.startsWith("/") && parte.endsWith("/") ? (
                  <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                ) : (
                  <span key={subIndex}>{parte}</span>
                )
              ))}
              <br />
            </React.Fragment>
          ))}
      </h1>
      <br />
      <p
        className="text-center w-[70%] sm:text-[22px] md:text-[30px]"
        style={fontFamilyStyle} // Aplicar el tipo de letra al p
      >
        {datosSeccionConfirmacion.aviso
          .split("\\n")
          .map((linea, index) => (
            <React.Fragment key={index}>
              {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                parte.startsWith("/") && parte.endsWith("/") ? (
                  <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                ) : (
                  <span key={subIndex}>{parte}</span>
                )
              ))}
              <br />
            </React.Fragment>
          ))}
      </p>
      <br />
      <br />
      <Slider {...settings} className="md:w-[40%] sm:w-[80%]">
        {images?.map((imageUrl, index) => (
          <div data-aos="fade-down-right" className="rolImg" key={index}>
            <img
              src={imageUrl}
              alt={`Slide ${index + 1}`}
              className="w-auto rounded-xl max-h-[60vh]"
            />
          </div>
        ))}
      </Slider>
      <br />
      <br />
      {datosSeccionConfirmacion.numeroConfirmacion && (
        <div
          data-aos="fade-down-left"
          className="md:w-[50%] sm:w-[80%] mx-auto mt-8 p-8 bg-white rounded-lg shadow-md"
          style={fontFamilyStyle} // Aplicar el tipo de letra al div contenedor
        >
          <h2
            className="text-2xl font-bold mb-4 text-black justify-center flex"
            style={fontFamilyStyle} // Aplicar el tipo de letra al h2
          >
            {datosSeccionConfirmacion.ingles?"Save your spot!":"Confirmación de Asistencia"} {datosSeccionConfirmacion.textoConfirmacion}{" "}
            {window.location.hostname === 'yourinvitation.vip' ? (
              <button onClick={() => handleShare(messageWhats)} className="text-green-600 ml-2">
                Share
              </button>
            ) : (
              <FaWhatsapp className="text-green-600 ml-2" />
            )}
          </h2>
          <br />
          {datosSeccionConfirmacion.mensajeAsistencia && (
            <p className="text-center font-semibold" style={fontFamilyStyle}>
              {datosSeccionConfirmacion.mensajeAsistencia.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                    parte.startsWith("/") && parte.endsWith("/") ? (
                      <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                    ) : (
                      <span key={subIndex}>{parte}</span>
                    )
                  ))}
                  <br />
                </React.Fragment>
              ))}
            </p>
          )}
          <div className="flex justify-center items-center">
            <div className="w-full text-center p-4 bg-green-700 mt-5 rounded-lg">
              {window.location.hostname === 'yourinvitation.vip' ? (
                <button onClick={() => handleShare(messageWhats)} className="text-white text-center md:w-[60%] md:text-xl text-auto">
                  {datosSeccionConfirmacion.ingles? "Click to confirm your attendance 💌":"Confirma tu asistencia con nosotros 💌"}
                </button>
              ) : (
                <a
                  href={`https://api.whatsapp.com/send?phone=${
                    datosSeccionConfirmacion.numeroConfirmacion
                  }&text=${encodeURIComponent(messageWhats)}`}
                  className="text-white text-center md:w-[60%] md:text-xl text-auto"
                >
                  {datosSeccionConfirmacion.ingles? "Click to confirm your attendance 💌":"Confirma tu asistencia con nosotros 💌"}
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
