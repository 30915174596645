'use client'

import { useState } from 'react'

const menuItems = [
  { 
    name: 'Rockaccino', 
    image: '/placeholder.svg?height=200&width=200',
    description: 'Deliciosa mezcla de café, chocolate y leche con hielo.',
    price: '$45',
    size: '280 kcal',
    category: 'frappe'
  },
  { 
    name: 'Kfreeze', 
    image: '/placeholder.svg?height=200&width=200',
    description: 'Bebida helada de café con crema batida y toppings.',
    price: '$50',
    size: '320 kcal',
    category: 'frappe'
  },
  { 
    name: 'Mexicanos', 
    image: '/placeholder.svg?height=200&width=200',
    description: 'Café con un toque de canela y chocolate mexicano.',
    price: '$40',
    size: '180 kcal',
    category: 'cafe'
  },
  { 
    name: 'Yambé', 
    image: '/placeholder.svg?height=200&width=200',
    description: 'Bebida refrescante de té verde con frutas.',
    price: '$35',
    size: '120 kcal',
    category: 'bebida caliente'
  },
  { 
    name: 'Caffenio Tonic', 
    image: '/placeholder.svg?height=200&width=200',
    description: 'Mezcla única de café frío y agua tónica.',
    price: '$55',
    size: '90 kcal',
    category: 'cafe'
  },
  { 
    name: 'Lateada', 
    image: '/placeholder.svg?height=200&width=200',
    description: 'Café con leche cremoso y suave.',
    price: '$38',
    size: '150 kcal',
    category: 'bebida caliente'
  },
  { 
    name: 'Repostería', 
    image: '/placeholder.svg?height=200&width=200',
    description: 'Variedad de pasteles y postres frescos.',
    price: 'Desde $25',
    size: 'Varía',
    category: 'postres'
  },
  { 
    name: 'Vilatto', 
    image: '/placeholder.svg?height=200&width=200',
    description: 'Bebida especial de la casa con café y vainilla.',
    price: '$48',
    size: '220 kcal',
    category: 'cafe'
  },
  { 
    name: 'Alimentos', 
    image: '/placeholder.svg?height=200&width=200',
    description: 'Sándwiches, ensaladas y snacks variados.',
    price: 'Desde $30',
    size: 'Varía',
    category: 'postres'
  },
]

function Modal({ item, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-80 max-w-md transform transition-all duration-300 ease-in-out">
        <img src={item.image} alt={item.name} className="w-full h-48 object-cover rounded-lg mb-4" />
        <h2 className="text-2xl font-bold mb-2">{item.name}</h2>
        <p className="text-gray-600 mb-4">{item.description}</p>
        <div className="flex justify-between items-center mb-4">
          <span className="text-lg font-semibold">{item.price}</span>
          <span className="text-sm text-gray-500">{item.size}</span>
        </div>
        <button 
          onClick={onClose}
          className="w-full bg-pink-500 text-white py-2 rounded-lg hover:bg-pink-600 transition duration-300"
        >
          Cerrar
        </button>
      </div>
    </div>
  )
}

export const MenuCafe2 = ()=> {
  const [selectedItem, setSelectedItem] = useState(null)
  const [activeFilter, setActiveFilter] = useState('todos')

  const filters = ['todos', 'frappe', 'cafe', 'bebida caliente', 'postres']

  const filteredItems = activeFilter === 'todos' 
    ? menuItems 
    : menuItems.filter(item => item.category === activeFilter)

  return (
    <div className="min-h-screen bg-white">
      {/* Title */}
      <h1 className="text-3xl font-bold px-4 py-6 text-center bg-gradient-to-r from-pink-500 to-purple-500 text-white">Menú Moon Café</h1>

      {/* Filters */}
      <div className="flex justify-start space-x-2 mb-6 px-4 overflow-x-auto whitespace-nowrap pb-2">
        {filters.map((filter) => (
          <button
            key={filter}
            onClick={() => setActiveFilter(filter)}
            className={`px-3 py-1 rounded-full text-xs sm:text-sm font-medium transition-colors duration-300 flex-shrink-0 ${
              activeFilter === filter
                ? 'bg-pink-500 text-white'
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            {filter.charAt(0).toUpperCase() + filter.slice(1)}
          </button>
        ))}
      </div>

      {/* Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 px-4 mb-24">
        {filteredItems.map((item) => (
          <div 
            key={item.name} 
            className="flex flex-col items-center transform transition duration-300 hover:scale-105"
            onClick={() => setSelectedItem(item)}
          >
            <div className="bg-gray-50 rounded-2xl p-4 w-full aspect-square mb-2 shadow-md hover:shadow-lg transition-shadow duration-300">
              <img
                src={item.image}
                alt={item.name}
                className="w-full h-full object-contain"
              />
            </div>
            <span className="text-sm font-medium text-center">{item.name}</span>
          </div>
        ))}
      </div>

      {/* Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg">
        <div className="flex justify-around items-center px-4 py-3">
          {/* Instagram */}
          <a
            href="https://www.instagram.com/caffenio"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center text-gray-600 hover:text-pink-500 transition-colors duration-300"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
              <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
              <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
            </svg>
            <span className="text-xs mt-1">Instagram</span>
          </a>

          {/* Facebook */}
          <a
            href="https://www.facebook.com/caffenio"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center text-gray-600 hover:text-blue-600 transition-colors duration-300"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
              <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
            <span className="text-xs mt-1">Facebook</span>
          </a>

          {/* Location */}
          <a
            href="https://goo.gl/maps/caffenio"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center text-gray-600 hover:text-green-600 transition-colors duration-300"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
              <circle cx="12" cy="10" r="3"></circle>
            </svg>
            <span className="text-xs mt-1">Ubicación</span>
          </a>
        </div>
      </div>

      {/* Modal */}
      {selectedItem && (
        <Modal item={selectedItem} onClose={() => setSelectedItem(null)} />
      )}
    </div>
  )
}