import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import axios from "axios";
import { Chart, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { validateToken } from "../../../components/validateToken";

Chart.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const DashboardSocio = () => {
  const [dataDiaria, setDataDiaria] = useState(null);
  const [dataMensual, setDataMensual] = useState(null);
  const [dataQuincenal, setDataQuincenal] = useState(null);
  const [ultimoDatoDiaria, setUltimoDatoDiaria] = useState(null);
  const [userName, setUserName] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [totalVentas, setTotalVentas] = useState(0);
  const [promedioVentasDiarias, setPromedioVentasDiarias] = useState(0);
  const [tendenciaVentas, setTendenciaVentas] = useState([]);

  useEffect(() => {
    const validateAndFetchData = async () => {
      const admin = await validateToken();
      if (admin) setUserName(admin);

      const fetchVentasDiarias = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-diarias-socio", {
            params: { year: 2024, month: selectedMonth },
          });
          const data = response.data;
          setDataDiaria(data);
          setUltimoDatoDiaria(data[data.length - 1]); 
          
          // Calcular promedio de ventas diarias
          const totalVentas = data.reduce((sum, day) => sum + day.totalTotales, 0);
          setTotalVentas(totalVentas);
          setPromedioVentasDiarias(totalVentas / data.length);

          // Calcular tendencia de ventas
          setTendenciaVentas(data.map(day => day.totalTotales));
        } catch (error) {
          console.error("Error fetching daily sales data", error);
        }
      };

      const fetchVentasMensuales = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-mensuales-socio", {
            params: { year: 2024 },
          });
          setDataMensual(response.data);
        } catch (error) {
          console.error("Error fetching monthly sales data", error);
        }
      };

      const fetchVentasQuincenales = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-quincenales-socio", {
            params: { year: 2024 },
          });
          setDataQuincenal(response.data);
        } catch (error) {
          console.error("Error fetching biweekly sales data", error);
        }
      };

      fetchVentasDiarias();
      fetchVentasMensuales();
      fetchVentasQuincenales();
    };

    validateAndFetchData();
  }, [selectedMonth]);

  if (!dataDiaria || !dataMensual || !dataQuincenal) return <LoadingComponent />;

  const handleMonthChange = (event) => {
    setSelectedMonth(Number(event.target.value));
  };

  const chartDataDiaria = {
    labels: dataDiaria.map(d => `Día ${d.dia}`),
    datasets: [
      {
        label: "Ventas Diarias",
        data: dataDiaria.map(d => ({
          x: `Día ${d.dia}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
          cantidadEventos: d?.cantidadEventos,
        })),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const chartDataMensual = {
    labels: dataMensual.map(d => `Mes ${d.mes}`),
    datasets: [
      {
        label: "Ventas Mensuales",
        data: dataMensual.map(d => ({
          x: `Mes ${d.mes}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
        })),
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };

  const chartDataQuincenal = {
    labels: [...new Set(dataQuincenal.map(d => `Mes ${d.mes}`))],
    datasets: dataQuincenal.map((d, index) => ({
      label: `Quincena ${d.quincena}`,
      data: [
        {
          x: `Mes ${d.mes}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
          fechaInicio: d.fechaInicio,
          fechaFin: d.fechaFin,
        },
      ],
      backgroundColor: `rgba(${255 - index * 40}, ${159 - index * 20}, 64, 0.6)`,
    })),
  };

  const tendenciaVentasData = {
    labels: dataDiaria.map(d => `Día ${d.dia}`),
    datasets: [
      {
        label: "Tendencia de Ventas",
        data: tendenciaVentas,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1
      }
    ]
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataPoint = context.raw;
            return [
              `Eventos: ${dataPoint?.cantidadEventos}`,
              `Montos: ${dataPoint.totalMontos}`,
              `Totales: ${dataPoint.y}`,
              `Rango: ${new Date(dataPoint.fechaInicio).toLocaleDateString()} - ${new Date(dataPoint.fechaFin).toLocaleDateString()}`
            ];
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      <aside className="w-64 bg-gradient-to-b from-blue-800 to-blue-600 text-white hidden sm:block shadow-lg">
        <div className="p-6 text-2xl font-bold border-b border-blue-500">DIGITAL RSVP</div>
        {userName && <h2 className="p-4 text-center font-medium bg-blue-700">EXCELENTE DÍA 🤑 <br />{userName}</h2>}
        <nav className="mt-6">
          <ul className="space-y-2">
            <li className="p-4 hover:bg-blue-700 cursor-pointer transition duration-300 ease-in-out">Ventas Diarias</li>
            <li className="p-4 hover:bg-blue-700 cursor-pointer transition duration-300 ease-in-out">Ventas Mensuales</li>
            <li className="p-4 hover:bg-blue-700 cursor-pointer transition duration-300 ease-in-out">Ventas Quincenales</li>
            <a className="text-white" href="/admins-control"> <li className="p-4 hover:bg-blue-700 cursor-pointer transition duration-300 ease-in-out">Administrar Usuarios</li></a>
          </ul>
        </nav>
        {ultimoDatoDiaria && (
          <div className="p-4 bg-blue-700 mt-8 rounded-lg mx-2">
            <h3 className="text-lg font-semibold mb-2 border-b pb-2">Última Venta</h3>
            <p className="text-sm mb-1">Fecha: <span className="font-medium">{`Día ${ultimoDatoDiaria.dia}`}</span></p>
            <p className="text-sm mb-1">Total de Ventas: <span className="font-medium">{ultimoDatoDiaria.cantidadEventos}</span></p>
            <p className="text-sm mb-1">Total Monto: <span className="font-medium">${ultimoDatoDiaria.totalMontos}</span></p>
            <p className="text-sm">Total Total: <span className="font-medium">${ultimoDatoDiaria.totalTotales}</span></p>
          </div>
        )}
      </aside>

      <main className="flex-grow p-8">
        <h1 className="text-4xl font-bold mb-8 text-gray-800">Dashboard de Ventas</h1>
        <div className="mb-6 bg-white p-4 rounded-lg shadow">
          <label htmlFor="month" className="block text-lg font-semibold mb-2 text-gray-700">Selecciona el Mes:</label>
          <select id="month" value={selectedMonth} onChange={handleMonthChange} className="p-2 border rounded w-full md:w-64">
            {[...Array(12).keys()].map(i => (
              <option key={i} value={i + 1}>{new Date(2024, i).toLocaleString('default', { month: 'long' })}</option>
            ))}
          </select>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Total Ventas</h3>
            <p className="text-3xl font-bold text-blue-600">${totalVentas.toFixed(2)}</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Promedio Diario</h3>
            <p className="text-3xl font-bold text-green-600">${promedioVentasDiarias.toFixed(2)}</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Eventos Totales</h3>
            <p className="text-3xl font-bold text-purple-600">{dataDiaria.reduce((sum, day) => sum + day.cantidadEventos, 0)}</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Ventas Diarias</h2>
            <Bar data={chartDataDiaria} options={options} />
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Ventas Mensuales</h2>
            <Bar data={chartDataMensual} options={options} />
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Ventas Quincenales</h2>
            <Bar data={chartDataQuincenal} options={options} />
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Tendencia de Ventas</h2>
            <Line data={tendenciaVentasData} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default DashboardSocio;
