import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input } from "reactstrap";
import GaleriaTable from "../../components/ComponentsAuto/GaleriaTable";
import { ImageFondo } from "./FormsComponent/Images/ImageFondo";
import { validateToken } from "../../components/validateToken";
import toast from "react-hot-toast";
import SelectTipoLetra from "../../components/functions/SelectTipoLetra";
import { SelectIcons } from "../../components/functions/SelectIcons";
import { SelectSobre } from "../../components/functions/SelectSobre";
import { AddHistorial } from "../../components/functions/AddHistorial";

export const XvDetalles = () => {
  const [datosInvitacion, setDatosInvitacion] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataEvento, setDataEvento] = useState({});
  const [userName, setUserName] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idClient = searchParams.get("id");

  const fetchData = async () => {
    try {
      const responseEvent = await axios.get(`/api/Eventos/${idClient}`);
      setDataEvento(responseEvent.data);
      console.log(responseEvent);
      const response = await axios.get(
        `/api/Seccionportada/obtenerXv/${idClient}`
      );
      setDatosInvitacion(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Error");
    }
  };

  const actualizarCeremonia = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionceremonium/${values.ceremoniaId}`, values);
      toast.success("Ceremonia actualizada correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar la ceremonia.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarColores = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Colores/${values.colorId}`, values);
      toast.success("Colores actualizados correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar los colores.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarContador = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccioncontador/${values.contadorId}`, values);
      toast.success("Contador actualizado correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar el contador.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarInvitados = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccioninvitado/${values.invitadosId}`, values);
      toast.success("Invitados actualizados correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar los invitados.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarConfirmacion = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(
        `/api/Seccionconfirmacion/${values.confirmacionId}`,
        values
      );
      toast.success("Confirmación actualizada correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar la confirmación.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarHospedaje = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionhospedaje/${values.hospedajeId}`, values);
      toast.success("Hospedaje actualizado correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar el hospedaje.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarPadres = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionpadre/${values.padresId}`, values);
      toast.success("Padres actualizados correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar los padres.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarPortada = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionportadum/${values.portadaId}`, values);
      toast.success("Portada actualizada correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar la portada.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarVestimenta = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionvestimentum/${values.vestimentaId}`, values);
      toast.success("Vestimenta actualizada correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar la vestimenta.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarPrograma = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionprograma/${values.programaId}`, values);
      toast.success("Programa actualizado correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar el programa.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarRegalos = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionregalo/${values.regalosId}`, values);
      toast.success("Regalos actualizados correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar los regalos.");
    } finally {
      toast.dismiss();
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    validateToken();
    const validateAndFetchData = async () => {
      const admin = await validateToken();
      if (admin) setUserName(admin);
    };
    validateAndFetchData();
  }, []);

  return (
    <div className="w-full p-10 mx-auto mt-10 bg-white rounded-md shadow-md">
      {loading ? (
        <div>Cargando XV...</div>
      ) : (
        <div>
          <h2 className="text-3xl font-semibold mb-4">Detalles del evento</h2>
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Iconos</h3>
            <p className="mb-4">
              Accede a nuestra galería de iconos para personalizar tu
              invitación:
            </p>
            <a
              href="/iconos"
              className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              Ver Galería de Iconos
            </a>
          </div>
          <div className="sticky top-0 z-50 bg-white max-w-full shadow-md p-4">
            <AddHistorial evento={idClient} nombre={userName} />
          </div>
          {/* <CeremoniaForm
            initialValues={datosInvitacion}
          />  */}

          <br />
          <br />
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
            COLORES
          </h2>
          <Formik
            initialValues={datosInvitacion?.colores}
            onSubmit={(values) => {
              console.log(values);
              actualizarColores(values);
            }}
          >
            {({ handleChange, handleSubmit, values }) => (
              <Form
                onSubmit={handleSubmit}
                className="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="color1"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Color 1:
                    </label>
                    <Input
                      type="color"
                      id="color1"
                      name="color1"
                      value={values?.color1}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="color2"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Color 2:
                    </label>
                    <Input
                      type="color"
                      id="color2"
                      name="color2"
                      value={values?.color2}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Guardar Colores
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <br />
          <br />
          <h2 className="text-4xl font-semibold w-full text-center mb-8 mt-12">
            PORTADA
          </h2>
          <Formik
            initialValues={datosInvitacion?.portada}
            onSubmit={(values) => {
              console.log("Formulario de portada enviado:", values);
              actualizarPortada(values);
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form
                onSubmit={handleSubmit}
                className="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="cancion"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Canción URL Youtube:
                    </label>
                    <Input
                      type="text"
                      id="cancion"
                      name="cancion"
                      value={values?.cancion}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="fechaDeBoda"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Fecha de Evento:
                    </label>
                    <Input
                      type="text"
                      id="fechaDeBoda"
                      name="fechaDeBoda"
                      value={values?.fechaDeBoda}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="nombre"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Nombre:
                    </label>
                    <Input
                      type="text"
                      id="nombre"
                      name="nombre"
                      value={values?.nombre}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="titulo"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Título:
                    </label>
                    <Input
                      type="text"
                      id="titulo"
                      name="titulo"
                      value={values?.titulo}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="imgPortada"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Imagen de Portada:
                    </label>
                    <Input
                      type="text"
                      id="imgPortada"
                      name="imgPortada"
                      value={values?.imgPortada}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="textColor"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Color Texto:
                    </label>
                    <Input
                      type="color"
                      id="textColor"
                      name="textColor"
                      value={values?.textColor || "#ffffff"}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <SelectSobre values={values} handleChange={handleChange}/>

                  <div>
                    <SelectTipoLetra
                      values={values}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Guardar Portada
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <br />
          {dataEvento.enableContador ? (
            <>
              <br />
              <h2 className="text-4xl font-semibold w-full text-center mb-8 mt-12">
                CONTADOR
              </h2>
              <Formik
                initialValues={datosInvitacion?.contador}
                onSubmit={(values) => {
                  console.log("Formulario de Contador enviado:", values);
                  actualizarContador(values);
                }}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <Form
                    onSubmit={handleSubmit}
                    className="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label
                          htmlFor="contador"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Contador:
                        </label>
                        <Input
                          type="checkbox"
                          id="contador"
                          name="contador"
                          checked={values?.contador}
                          onChange={handleChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="descripcion"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Descripción:
                        </label>
                        <textarea
                          id="descripcion"
                          name="descripcion"
                          value={values?.descripcion}
                          onChange={handleChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="fechaNewDate"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Fecha:
                        </label>
                        <Input
                          type="text"
                          id="fechaNewDate"
                          name="fechaNewDate"
                          value={values?.fechaNewDate}
                          onChange={handleChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="fotoDerecha"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Foto Abajo de Contador (Derecha):
                        </label>
                        <Input
                          type="text"
                          id="fotoDerecha"
                          name="fotoDerecha"
                          value={values?.fotoDerecha}
                          onChange={handleChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="fotoIzquierda"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Foto Arriba de Contador (Izquierda):
                        </label>
                        <Input
                          type="text"
                          id="fotoIzquierda"
                          name="fotoIzquierda"
                          value={values?.fotoIzquierda}
                          onChange={handleChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="marcoEnable"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Marco Habilitado:
                        </label>
                        <Input
                          type="checkbox"
                          id="marcoEnable"
                          name="marcoEnable"
                          checked={values?.marcoEnable}
                          onChange={handleChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="titulo"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Título:
                        </label>
                        <Input
                          type="text"
                          id="titulo"
                          name="titulo"
                          value={values?.titulo}
                          onChange={handleChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="textColor"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Color de Texto:
                        </label>
                        <Input
                          type="color"
                          id="textColor"
                          name="textColor"
                          value={values?.textColor}
                          onChange={handleChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="imgFondo"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Imagen de fondo:
                        </label>
                        <Input
                          type="text"
                          id="imgFondo"
                          name="imgFondo"
                          value={values?.imgFondo}
                          onChange={handleChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="imgFondo"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Imagen de fondo:
                        </label>
                        <ImageFondo
                          label="Seleccione un fondo"
                          name="imgFondo"
                          onChange={handleChange}
                          value={values?.imgFondo}
                          className={`mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                            values?.imgFondo ? "input-error" : ""
                          }`}
                        />
                      </div>
                      <div>
                        <SelectTipoLetra
                          values={values}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="flex justify-end mt-6">
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Guardar Contador
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : null}

          <br />
          {dataEvento.enablePadres ? (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="p-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  PADRES Y PADRINOS
                </h2>
                <Formik
                  initialValues={datosInvitacion?.padres}
                  onSubmit={(values) => {
                    console.log(
                      "Formulario de padres y padrinos enviado:",
                      values
                    );
                    actualizarPadres(values);
                  }}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <label
                            htmlFor="cantidad"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Cantidad:
                          </label>
                          <Input
                            type="number"
                            id="cantidad"
                            name="cantidad"
                            value={values?.cantidad}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="descripcion"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Descripción:
                          </label>
                          <Input
                            type="text"
                            id="descripcion"
                            name="descripcion"
                            value={values?.descripcion}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="titulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título:
                          </label>
                          <Input
                            type="text"
                            id="titulo"
                            name="titulo"
                            value={values?.titulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <label
                            htmlFor="titulo1"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título 1:
                          </label>
                          <Input
                            type="text"
                            id="titulo1"
                            name="titulo1"
                            value={values?.titulo1}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="madre"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Madre:
                          </label>
                          <Input
                            type="text"
                            id="madre"
                            name="madre"
                            value={values?.madre}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="padre"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Padre:
                          </label>
                          <Input
                            type="text"
                            id="padre"
                            name="padre"
                            value={values?.padre}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <label
                            htmlFor="titulo2"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título 2:
                          </label>
                          <Input
                            type="text"
                            id="titulo2"
                            name="titulo2"
                            value={values?.titulo2}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="padrino"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Padrino:
                          </label>
                          <Input
                            type="text"
                            id="padrino"
                            name="padrino"
                            value={values?.padrino}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="madrina"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Madrina:
                          </label>
                          <Input
                            type="text"
                            id="madrina"
                            name="madrina"
                            value={values?.madrina}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <label
                            htmlFor="titulo3"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título 3:
                          </label>
                          <Input
                            type="text"
                            id="titulo3"
                            name="titulo3"
                            value={values?.titulo3}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="padrino2"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Segundo Padrino:
                          </label>
                          <Input
                            type="text"
                            id="padrino2"
                            name="padrino2"
                            value={values?.padrino2 || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="madrina2"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Segunda Madrina:
                          </label>
                          <Input
                            type="text"
                            id="madrina2"
                            name="madrina2"
                            value={values?.madrina2 || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <label
                            htmlFor="textColor"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Color de Texto:
                          </label>
                          <Input
                            type="color"
                            id="textColor"
                            name="textColor"
                            value={values?.textColor || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <Input
                            type="text"
                            id="imgFondo"
                            name="imgFondo"
                            value={values?.imgFondo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de Fondo:
                          </label>
                          <ImageFondo
                            label="Seleccione un fondo"
                            name="imgFondo"
                            onChange={handleChange}
                            value={values?.imgFondo}
                            className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                              values?.imgFondo ? "input-error" : ""
                            }`}
                          />
                        </div>
                        <div>
                          <SelectTipoLetra
                            values={values}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="flex justify-end mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Guardar Padres y Padrinos
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : null}

          <br />
          {dataEvento.enableCeremonia ? (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="p-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  CEREMONIA
                </h2>
                <Formik
                  initialValues={datosInvitacion?.ceremonia}
                  onSubmit={(values) => {
                    actualizarCeremonia(values);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                  }) => (
                    <Form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label
                            htmlFor="ceremoniaEnable"
                            className="flex items-center"
                          >
                            <Input
                              type="checkbox"
                              id="ceremoniaEnable"
                              name="ceremoniaEnable"
                              checked={values?.ceremoniaEnable}
                              onChange={handleChange}
                              className="mr-2"
                            />
                            <span className="text-sm font-medium text-gray-700">
                              Ceremonia Habilitada
                            </span>
                          </label>
                          <ErrorMessage
                            name="ceremoniaEnable"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="ceremoniaImg"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de Ceremonia:
                          </label>
                          <Input
                            type="text"
                            id="ceremoniaImg"
                            name="ceremoniaImg"
                            value={values?.ceremoniaImg}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="ceremoniaImg"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="ceremoniaTitulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Titulo de Ceremonia:
                          </label>
                          <Input
                            type="text"
                            id="ceremoniaTitulo"
                            name="ceremoniaTitulo"
                            value={values?.ceremoniaTitulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="ceremoniaTitulo"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="ceremoniaFecha"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Fecha de Ceremonia:
                          </label>
                          <Input
                            type="text"
                            id="ceremoniaFecha"
                            name="ceremoniaFecha"
                            value={values?.ceremoniaFecha}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="ceremoniaFecha"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="ceremoniaHora"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Hora de Ceremonia:
                          </label>
                          <Input
                            type="text"
                            id="ceremoniaHora"
                            name="ceremoniaHora"
                            value={values?.ceremoniaHora}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="ceremoniaHora"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="ceremoniaLugarMap"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Direccion de Ceremonia (Mapa):
                          </label>
                          <Input
                            type="text"
                            id="ceremoniaLugarMap"
                            name="ceremoniaLugarMap"
                            value={values?.ceremoniaLugarMap}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="ceremoniaLugarMap"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="ceremoniaNombreLugar"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Nombre del Lugar de Ceremonia:
                          </label>
                          <Input
                            type="text"
                            id="ceremoniaNombreLugar"
                            name="ceremoniaNombreLugar"
                            value={values?.ceremoniaNombreLugar}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="ceremoniaNombreLugar"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="ceremoniaUrl"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            URL de Ceremonia:
                          </label>
                          <Input
                            type="text"
                            id="ceremoniaUrl"
                            name="ceremoniaUrl"
                            value={values?.ceremoniaUrl}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="ceremoniaUrl"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="dobleEnable"
                            className="flex items-center"
                          >
                            <Input
                              type="checkbox"
                              id="dobleEnable"
                              name="dobleEnable"
                              checked={values?.dobleEnable}
                              onChange={handleChange}
                              className="mr-2"
                            />
                            <span className="text-sm font-medium text-gray-700">
                              Doble Habilitado
                            </span>
                          </label>
                          <ErrorMessage
                            name="dobleEnable"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="recepcionEnable"
                            className="flex items-center"
                          >
                            <Input
                              type="checkbox"
                              id="recepcionEnable"
                              name="recepcionEnable"
                              checked={values?.recepcionEnable}
                              onChange={handleChange}
                              className="mr-2"
                            />
                            <span className="text-sm font-medium text-gray-700">
                              Recepción Habilitada
                            </span>
                          </label>
                          <ErrorMessage
                            name="recepcionEnable"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="recepcionImg"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de Recepción:
                          </label>
                          <Input
                            type="text"
                            id="recepcionImg"
                            name="recepcionImg"
                            value={values?.recepcionImg}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="recepcionImg"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="recepcionTitulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Titulo de Recepción:
                          </label>
                          <Input
                            type="text"
                            id="recepcionTitulo"
                            name="recepcionTitulo"
                            value={values?.recepcionTitulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="recepcionTitulo"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="recepcionFecha"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Fecha de Recepción:
                          </label>
                          <Input
                            type="text"
                            id="recepcionFecha"
                            name="recepcionFecha"
                            value={values?.recepcionFecha}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="recepcionFecha"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="recepcionHora"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Hora de Recepción:
                          </label>
                          <Input
                            type="text"
                            id="recepcionHora"
                            name="recepcionHora"
                            value={values?.recepcionHora}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="recepcionHora"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="recepcionLugarMap"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Direccion de Recepción (Mapa):
                          </label>
                          <Input
                            type="text"
                            id="recepcionLugarMap"
                            name="recepcionLugarMap"
                            value={values?.recepcionLugarMap}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="recepcionLugarMap"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="recepcionNombreLugar"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Nombre del Lugar de Recepción:
                          </label>
                          <Input
                            type="text"
                            id="recepcionNombreLugar"
                            name="recepcionNombreLugar"
                            value={values?.recepcionNombreLugar}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="recepcionNombreLugar"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="recepcionUrl"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            URL de Recepción:
                          </label>
                          <Input
                            type="text"
                            id="recepcionUrl"
                            name="recepcionUrl"
                            value={values?.recepcionUrl}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="recepcionUrl"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="textColor"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Color de Texto:
                          </label>
                          <Input
                            type="color"
                            id="textColor"
                            name="textColor"
                            value={values?.textColor}
                            onChange={handleChange}
                            className="mt-1 block w-full h-10"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <Input
                            type="text"
                            id="imgFondo"
                            name="imgFondo"
                            value={values?.imgFondo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <ImageFondo
                            label="Seleccione un fondo"
                            name="imgFondo"
                            onChange={handleChange}
                            value={values?.imgFondo}
                            className={`mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                              values?.imgFondo ? "input-error" : ""
                            }`}
                          />
                        </div>
                        <div>
                          <SelectTipoLetra
                            values={values}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Guardar Ceremonia
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : null}

          <br />
          {dataEvento.enableConfirmacion ? (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="p-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  CONFIRMACIÓN
                </h2>
                <Formik
                  initialValues={datosInvitacion?.confirmacion}
                  onSubmit={(values) => {
                    console.log("Formulario de confirmación enviado:", values);
                    actualizarConfirmacion(values);
                  }}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label
                            htmlFor="aviso"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Aviso:
                          </label>
                          <textarea
                            id="aviso"
                            name="aviso"
                            value={values?.aviso || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            rows="3"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="enableNum"
                            className="flex items-center"
                          >
                            <Field
                              type="checkbox"
                              id="enableNum"
                              name="enableNum"
                              checked={values?.enableNum}
                              onChange={handleChange}
                              className="mr-2"
                            />
                            <span className="text-sm font-medium text-gray-700">
                              Desactivar Número Confirmación
                            </span>
                          </label>
                        </div>
                        <div>
                          <label
                            htmlFor="numeroConfirmacion"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Número de Confirmación:
                          </label>
                          <Input
                            type="text"
                            id="numeroConfirmacion"
                            name="numeroConfirmacion"
                            value={values?.numeroConfirmacion || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                        <label htmlFor="confetti" className="flex items-center">
                          <Input
                            type="checkbox"
                            id="confetti"
                            name="confetti"
                            checked={values?.confetti === true}
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  name: 'confetti',
                                  value: e.target.checked
                                }
                              });
                            }}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                          <span className="ml-2 text-sm text-gray-700">Desactivar Confetti</span>
                        </label>
                      </div>
                        <div>
                          <label
                            htmlFor="textColor"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Color de Texto:
                          </label>
                          <Input
                            type="color"
                            id="textColor"
                            name="textColor"
                            value={values?.textColor || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="textWhatsApp"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Texto de Cuadro de WhatsApp:
                          </label>
                          <Input
                            type="text"
                            id="textWhatsApp"
                            name="textWhatsApp"
                            value={values?.textWhatsApp || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="textoConfirmacion"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Mensaje de Confirmación en WhatsApp:
                          </label>
                          <Input
                            type="text"
                            id="textoConfirmacion"
                            name="textoConfirmacion"
                            value={values?.textoConfirmacion}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="tituloConfirmacion"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título de Confirmación:
                          </label>
                          <Input
                            type="text"
                            id="tituloConfirmacion"
                            name="tituloConfirmacion"
                            value={values?.tituloConfirmacion}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <Input
                            type="text"
                            id="imgFondo"
                            name="imgFondo"
                            value={values?.imgFondo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de Fondo:
                          </label>
                          <ImageFondo
                            label="Seleccione un fondo"
                            name="imgFondo"
                            onChange={handleChange}
                            value={values?.imgFondo}
                            className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                              values?.imgFondo ? "input-error" : ""
                            }`}
                          />
                        </div>
                        <div>
                          <SelectTipoLetra
                            values={values}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Guardar Confirmación
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : null}

          <br />
          {dataEvento.enableHospedaje ? (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="p-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  HOSPEDAJE
                </h2>
                <Formik
                  initialValues={datosInvitacion?.hospedaje}
                  onSubmit={(values) => {
                    console.log("Formulario de hospedaje enviado:", values);
                    actualizarHospedaje(values);
                  }}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label
                            htmlFor="descripcion"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Descripción:
                          </label>
                          <Input
                            type="text"
                            id="descripcion"
                            name="descripcion"
                            value={values?.descripcion}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="disponibleDos"
                            className="flex items-center"
                          >
                            <Input
                              type="checkbox"
                              id="disponibleDos"
                              name="disponibleDos"
                              checked={values?.disponibleDos}
                              onChange={handleChange}
                              className="mr-2"
                            />
                            <span className="text-sm font-medium text-gray-700">
                              Disponible Dos
                            </span>
                          </label>
                        </div>
                        <div>
                          <label
                            htmlFor="hotel1Titulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título del Hotel 1:
                          </label>
                          <Input
                            type="text"
                            id="hotel1Titulo"
                            name="hotel1Titulo"
                            value={values?.hotel1Titulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hotel1lugar"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Lugar del Hotel 1:
                          </label>
                          <Input
                            type="text"
                            id="hotel1lugar"
                            name="hotel1lugar"
                            value={values?.hotel1lugar}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hotel1url"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            URL del Hotel 1:
                          </label>
                          <Input
                            type="text"
                            id="hotel1url"
                            name="hotel1url"
                            value={values?.hotel1url}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hotel2Titulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título del Hotel 2:
                          </label>
                          <Input
                            type="text"
                            id="hotel2Titulo"
                            name="hotel2Titulo"
                            value={values?.hotel2Titulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hotel2lugar"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Lugar del Hotel 2:
                          </label>
                          <Input
                            type="text"
                            id="hotel2lugar"
                            name="hotel2lugar"
                            value={values?.hotel2lugar}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hotel2url"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            URL del Hotel 2:
                          </label>
                          <Input
                            type="text"
                            id="hotel2url"
                            name="hotel2url"
                            value={values?.hotel2url}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <Input
                            type="text"
                            id="imgFondo"
                            name="imgFondo"
                            value={values?.imgFondo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de Fondo:
                          </label>
                          <ImageFondo
                            label="Seleccione un fondo"
                            name="imgFondo"
                            onChange={handleChange}
                            value={values?.imgFondo}
                            className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                              values?.imgFondo ? "input-error" : ""
                            }`}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="textColor"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Color de Texto:
                          </label>
                          <Input
                            type="color"
                            id="textColor"
                            name="textColor"
                            value={values?.textColor || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <SelectTipoLetra
                            values={values}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Guardar Hospedaje
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : null}

          <br />
          {dataEvento.enablePrograma ? (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="p-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  PROGRAMA
                </h2>
                <Formik
                  initialValues={datosInvitacion?.programa}
                  onSubmit={(values) => {
                    console.log("Formulario de programa enviado:", values);
                    actualizarPrograma(values);
                  }}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <label
                            htmlFor="titulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Titulo Principal:
                          </label>
                          <Input
                            type="text"
                            id="titulo"
                            name="titulo"
                            value={values?.titulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="descripcion"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Descripcion:
                          </label>
                          <Input
                            type="text"
                            id="descripcion"
                            name="descripcion"
                            value={values?.descripcion}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {[
                        {
                          name: "ceremoniaReligiosa",
                          label: "Seccion",
                          number: 1,
                        },
                        { name: "recepcion", label: "Seccion", number: 2 },
                        { name: "baileXv", label: "Seccion", number: 3 },
                        { name: "coctel", label: "Seccion", number: 4 },
                        { name: "cena", label: "Seccion", number: 5 },
                        { name: "baile", label: "Seccion", number: 6 },
                      ].map((item) => (
                        <div
                          key={item.name}
                          className="grid grid-cols-1 md:grid-cols-3 gap-6"
                        >
                          <div>
                            <label
                              htmlFor={item.name}
                              className="block text-sm font-medium text-gray-700 mb-1"
                            >
                              {`${item.label} (${item.number}):`}
                            </label>
                            <Input
                              type="text"
                              id={item.name}
                              name={item.name}
                              value={values?.[item.name]}
                              onChange={handleChange}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <SelectIcons
                              label={`Selecciona un icono para ${item.label} (${item.number})`}
                              name={`${item.name}Img`}
                              onChange={handleChange}
                              value={values?.[`${item.name}Img`]}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor={`${item.name}Titulo`}
                              className="block text-sm font-medium text-gray-700 mb-1"
                            >
                              {`${item.label} Titulo (${item.number}):`}
                            </label>
                            <Input
                              type="text"
                              id={`${item.name}Titulo`}
                              name={`${item.name}Titulo`}
                              value={values?.[`${item.name}Titulo`]}
                              onChange={handleChange}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      ))}

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <label
                            htmlFor="textColor"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Color de Texto:
                          </label>
                          <Input
                            type="color"
                            id="textColor"
                            name="textColor"
                            value={values?.textColor || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <Input
                            type="text"
                            id="imgFondo"
                            name="imgFondo"
                            value={values?.imgFondo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de Fondo:
                          </label>
                          <ImageFondo
                            label="Seleccione un fondo"
                            name="imgFondo"
                            onChange={handleChange}
                            value={values?.imgFondo}
                            className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                              values?.imgFondo ? "input-error" : ""
                            }`}
                          />
                        </div>
                        <div>
                          <SelectTipoLetra
                            values={values}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="flex justify-end mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Guardar Programa
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : null}

          <br />
          {dataEvento.enableRegalos ? (
            <>
              <br />
              <h2 className="text-4xl font-semibold w-full text-center mb-4 mt-8">
                REGALOS
              </h2>
              <Formik
                initialValues={datosInvitacion?.regalos}
                onSubmit={(values) => {
                  console.log("Formulario de Mesa de Regalos enviado:", values);
                  actualizarRegalos(values);
                }}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <Form
                    onSubmit={handleSubmit}
                    className="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4"
                  >
                    <div className="grid grid-cols-1 gap-6">
                      <div className="mb-4">
                        <label
                          htmlFor="cantidadRegalos"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Cantidad:
                        </label>
                        <Input
                          type="number"
                          id="cantidadRegalos"
                          name="cantidadRegalos"
                          value={values?.cantidadRegalos}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="titulo"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Título:
                        </label>
                        <Input
                          type="text"
                          id="titulo"
                          name="titulo"
                          value={values?.titulo}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="descripcion"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Descripción:
                        </label>
                        <Input
                          type="textarea"
                          id="descripcion"
                          name="descripcion"
                          value={values?.descripcion}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                      {[1, 2, 3].map((num) => (
                        <div key={num} className="mb-4">
                          <label
                            htmlFor={`link${num}`}
                            className="block text-gray-700 text-sm font-bold mb-2"
                          >
                            Enlace {num}:
                          </label>
                          <Input
                            type="text"
                            id={`link${num}`}
                            name={`link${num}`}
                            value={values?.[`link${num}`]}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          <div className="mt-2">
                            <label className="inline-flex items-center">
                              <Input
                                type="checkbox"
                                id={`link${num}Enable`}
                                name={`link${num}Enable`}
                                checked={values?.[`link${num}Enable`]}
                                onChange={handleChange}
                                className="form-checkbox h-5 w-5 text-blue-600"
                              />
                              <span className="ml-2 text-gray-700">
                                Habilitar
                              </span>
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                      {[1, 2, 3].map((num) => (
                        <div key={num} className="mb-4">
                          <label
                            htmlFor={`imgLink${num}`}
                            className="block text-gray-700 text-sm font-bold mb-2"
                          >
                            Imagen Enlace {num}:
                          </label>
                          <Input
                            type="text"
                            id={`imgLink${num}`}
                            name={`imgLink${num}`}
                            value={values?.[`imgLink${num}`]}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      ))}
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-6">
                      <div className="mb-4">
                        <label
                          htmlFor="textColor"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Color Texto:
                        </label>
                        <Input
                          type="color"
                          id="textColor"
                          name="textColor"
                          value={values?.textColor}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="imgFondo"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Imagen de fondo:
                        </label>
                        <Input
                          type="text"
                          id="imgFondo"
                          name="imgFondo"
                          value={values?.imgFondo}
                          onChange={handleChange}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="imgFondo"
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Fondo Imagen:
                        </label>
                        <ImageFondo
                          label="Seleccione un fondo"
                          name="imgFondo"
                          onChange={handleChange}
                          value={values?.imgFondo}
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            values?.imgFondo ? "input-error" : ""
                          }`}
                        />
                      </div>
                    </div>

                    <div className="mb-6 mt-6">
                      <SelectTipoLetra
                        values={values}
                        handleChange={handleChange}
                      />
                    </div>

                    <div className="flex items-center justify-end">
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Guardar Mesa de Regalos
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            ""
          )}

          <br />
          {dataEvento.enableVestimenta ? (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="p-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  VESTIMENTA
                </h2>
                <Formik
                  initialValues={datosInvitacion?.vestimenta}
                  onSubmit={(values) => {
                    console.log("Formulario de Vestimenta enviado:", values);
                    actualizarVestimenta(values);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                  }) => (
                    <Form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label
                            htmlFor="vestimenta"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Vestimenta:
                          </label>
                          <Input
                            type="text"
                            id="vestimenta"
                            name="vestimenta"
                            placeholder="Formal"
                            value={values?.vestimenta}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="vestimenta"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="messageVestimenta"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Mensaje sobre la vestimenta:
                          </label>
                          <Input
                            type="text"
                            id="messageVestimenta"
                            name="messageVestimenta"
                            value={values?.messageVestimenta}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="messageVestimenta"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="urlVestimentaHombre"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            URL de vestimenta para hombre:
                          </label>
                          <Input
                            type="text"
                            id="urlVestimentaHombre"
                            name="urlVestimentaHombre"
                            value={values?.urlVestimentaHombre}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="urlVestimentaHombre"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="urlVestimentaMujer"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            URL de vestimenta para mujer:
                          </label>
                          <Input
                            type="text"
                            id="urlVestimentaMujer"
                            name="urlVestimentaMujer"
                            value={values?.urlVestimentaMujer}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="urlVestimentaMujer"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label htmlFor="textoHombre" className="block text-sm font-medium text-gray-700 mb-1">
                            Texto para Hombre:
                          </label>
                          <Input
                            type="text"
                            id="textoHombre"
                            name="textoHombre"
                            value={values?.textoHombre}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage name="textoHombre" component="div" className="text-red-500 text-sm mt-1" />
                        </div>
                        <div>
                          <label htmlFor="textoMujer" className="block text-sm font-medium text-gray-700 mb-1">
                            Texto para Mujer:
                          </label>
                          <Input
                            type="text"
                            id="textoMujer"
                            name="textoMujer"
                            value={values?.textoMujer}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage name="textoMujer" component="div" className="text-red-500 text-sm mt-1" />
                        </div>
                        <div>
                          <label htmlFor="colores" className="block text-sm font-medium text-gray-700 mb-1">
                            Colores:
                          </label>
                          <Input
                            type="text"
                            id="colores"
                            name="colores"
                            value={values?.colores}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage name="colores" component="div" className="text-red-500 text-sm mt-1" />
                        </div>
                        <div>
                          <label
                            htmlFor="textColor"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Color de texto:
                          </label>
                          <Input
                            type="color"
                            id="textColor"
                            name="textColor"
                            value={values?.textColor}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="textColor"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <Input
                            type="text"
                            id="imgFondo"
                            name="imgFondo"
                            value={values?.imgFondo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <ImageFondo
                            label="Seleccione un fondo"
                            name="imgFondo"
                            onChange={handleChange}
                            value={values?.imgFondo}
                            className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                              values?.imgFondo ? "input-error" : ""
                            }`}
                          />
                        </div>
                      </div>
                      <div>
                        <SelectTipoLetra
                          values={values}
                          handleChange={handleChange}
                        />
                      </div>
                      <div className="flex justify-end mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Guardar Vestimenta
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : null}

          <br />
          {dataEvento.enableInvitados ? (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="p-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  INVITADOS
                </h2>
                <Formik
                  initialValues={datosInvitacion?.invitados}
                  onSubmit={(values) => {
                    if (values.limiteAccesos == null) {
                      values.limiteAccesos = 150;
                    }
                    console.log("Formulario de Invitados enviado:", values);
                    actualizarInvitados(values);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                  }) => (
                    <Form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label
                            htmlFor="titulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título:
                          </label>
                          <Input
                            type="text"
                            id="titulo"
                            name="titulo"
                            value={values?.titulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="titulo"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="entradasText"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Texto de Entradas:
                          </label>
                          <Input
                            type="text"
                            id="entradasText"
                            name="entradasText"
                            value={values?.entradasText}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="entradasText"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="eventoTitulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título del Evento:
                          </label>
                          <Input
                            type="text"
                            id="eventoTitulo"
                            name="eventoTitulo"
                            value={values?.eventoTitulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="eventoTitulo"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="colorFondoName"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Color de Fondo:
                          </label>
                          <Input
                            type="color"
                            id="colorFondoName"
                            name="colorFondoName"
                            value={values?.colorFondoName}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="colorFondoName"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <Input
                            type="text"
                            id="imgFondo"
                            name="imgFondo"
                            value={values?.imgFondo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de Fondo:
                          </label>
                          <ImageFondo
                            label="Seleccione un fondo"
                            name="imgFondo"
                            onChange={handleChange}
                            value={values?.imgFondo}
                            className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                              values?.imgFondo ? "input-error" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="imgFondo"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="limiteAccesos"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Límite de Invitados:
                          </label>
                          <Input
                            type="number"
                            id="limiteAccesos"
                            name="limiteAccesos"
                            value={values?.limiteAccesos}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage
                            name="limiteAccesos"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div>
                          <SelectTipoLetra
                            values={values}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Guardar Invitados
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : null}

          <GaleriaTable idEvento={idClient} />
        </div>
      )}
    </div>
  );
};
