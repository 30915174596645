import React from 'react';
import { Input, Label } from "reactstrap";

export const VestimentaForm = ({ 
  values, 
  handleChange, 
  handleInputChange, 
  errors, 
  touched 
}) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-8">
      <h2 className="text-2xl font-bold text-center mb-6">
        VESTIMENTA
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <Label 
            htmlFor="Vestimenta" 
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Vestimenta del evento
          </Label>
          <Input
            id="Vestimenta"
            type="text"
            name="Vestimenta"
            onChange={(e) => { handleChange(e); handleInputChange(e); }}
            value={values.Vestimenta}
            placeholder="Formal.."
            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.Vestimenta ? "border-red-500" : ""}`}
          />
          {touched.Vestimenta && errors.Vestimenta && (
            <p className="mt-2 text-sm text-red-600">{errors.Vestimenta}</p>
          )}
        </div>
        <div>
          <Label 
            htmlFor="VestimentaAviso" 
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Aviso de Vestimenta
          </Label>
          <Input
            id="VestimentaAviso"
            type="text"
            name="VestimentaAviso"
            onChange={(e) => { handleChange(e); handleInputChange(e); }}
            value={values.VestimentaAviso}
            placeholder="No llevar color negro.."
            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.VestimentaAviso ? "border-red-500" : ""}`}
          />
          {touched.VestimentaAviso && errors.VestimentaAviso && (
            <p className="mt-2 text-sm text-red-600">{errors.VestimentaAviso}</p>
          )}
        </div>
      </div>
    </div>
  );
}; 