import React from 'react';
import { Input, Label } from "reactstrap";

export const CeremonyAndReceptionForm = ({ values, handleChange, handleInputChange, errors, touched }) => {
  return (
    <>
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold text-center mb-6">
          INFORMACIÓN DE LA CEREMONIA
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <Label htmlFor="D4ceremoniaFecha" className="block text-sm font-medium text-gray-700 mb-1">
              Fecha y hora de la Ceremonia
            </Label>
            <Input
              id="D4ceremoniaFecha"
              type="datetime-local"
              name="D4ceremoniaFecha"
              onChange={(e) => { handleChange(e); handleInputChange(e); }}
              value={values.D4ceremoniaFecha}
              className={`w-full rounded-md ${errors.D4ceremoniaFecha ? "border-red-500" : "border-gray-300"}`}
            />
            {touched.D4ceremoniaFecha && errors.D4ceremoniaFecha && (
              <p className="mt-2 text-sm text-red-600">{errors.D4ceremoniaFecha}</p>
            )}
          </div>
          <div>
            <Label htmlFor="D4ceremoniaNombreLugar" className="block text-sm font-medium text-gray-700 mb-1">
              Lugar de la Ceremonia
            </Label>
            <Input
              id="D4ceremoniaNombreLugar"
              type="text"
              name="D4ceremoniaNombreLugar"
              onChange={(e) => { handleChange(e); handleInputChange(e); }}
              value={values.D4ceremoniaNombreLugar}
              className={`w-full rounded-md ${errors.D4ceremoniaNombreLugar ? "border-red-500" : "border-gray-300"}`}
            />
            {touched.D4ceremoniaNombreLugar && errors.D4ceremoniaNombreLugar && (
              <p className="mt-2 text-sm text-red-600">{errors.D4ceremoniaNombreLugar}</p>
            )}
          </div>
          <div>
            <Label htmlFor="D4ceremoniaUrl" className="block text-sm font-medium text-gray-700 mb-1">
              URL del lugar de la Ceremonia
            </Label>
            <Input
              id="D4ceremoniaUrl"
              type="text"
              name="D4ceremoniaUrl"
              onChange={(e) => { handleChange(e); handleInputChange(e); }}
              value={values.D4ceremoniaUrl}
              className={`w-full rounded-md ${errors.D4ceremoniaUrl ? "border-red-500" : "border-gray-300"}`}
            />
            {touched.D4ceremoniaUrl && errors.D4ceremoniaUrl && (
              <p className="mt-2 text-sm text-red-600">{errors.D4ceremoniaUrl}</p>
            )}
          </div>
          <div>
            <Label className="block text-sm font-medium text-gray-700 mb-1">Ejemplo URL</Label>
            <img
              src="https://i.postimg.cc/CLz26d5q/Dise-o-sin-t-tulo.gif"
              alt="Ejemplo URL"
              className="w-full h-auto rounded-md shadow-sm"
            />
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold text-center mb-6">
          RECEPCIÓN
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <Label htmlFor="D4recepcionFecha" className="block text-sm font-medium text-gray-700 mb-1">
              Fecha y hora de la Recepción
            </Label>
            <Input
              id="D4recepcionFecha"
              type="datetime-local"
              name="D4recepcionFecha"
              onChange={(e) => { handleChange(e); handleInputChange(e); }}
              value={values.D4recepcionFecha}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D4recepcionFecha ? "border-red-500" : ""}`}
            />
            {touched.D4recepcionFecha && errors.D4recepcionFecha && (
              <p className="mt-2 text-sm text-red-600">{errors.D4recepcionFecha}</p>
            )}
          </div>
          <div>
            <Label htmlFor="D4recepcionNombreLugar" className="block text-sm font-medium text-gray-700 mb-1">
              Lugar de la Recepción
            </Label>
            <Input
              id="D4recepcionNombreLugar"
              type="text"
              name="D4recepcionNombreLugar"
              onChange={(e) => { handleChange(e); handleInputChange(e); }}
              value={values.D4recepcionNombreLugar}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D4recepcionNombreLugar ? "border-red-500" : ""}`}
            />
            {touched.D4recepcionNombreLugar && errors.D4recepcionNombreLugar && (
              <p className="mt-2 text-sm text-red-600">{errors.D4recepcionNombreLugar}</p>
            )}
          </div>
          <div>
            <Label htmlFor="D4recepcionUrl" className="block text-sm font-medium text-gray-700 mb-1">
              URL del lugar de la Recepción
            </Label>
            <Input
              id="D4recepcionUrl"
              type="text"
              name="D4recepcionUrl"
              onChange={(e) => { handleChange(e); handleInputChange(e); }}
              value={values.D4recepcionUrl}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${errors.D4recepcionUrl ? "border-red-500" : ""}`}
            />
            {touched.D4recepcionUrl && errors.D4recepcionUrl && (
              <p className="mt-2 text-sm text-red-600">{errors.D4recepcionUrl}</p>
            )}
          </div>
          <div>
            <Label className="block text-sm font-medium text-gray-700 mb-1">Ejemplo URL</Label>
            <img
              src="https://i.postimg.cc/CLz26d5q/Dise-o-sin-t-tulo.gif"
              alt="Ejemplo URL"
              className="w-full h-auto rounded-md shadow-sm"
            />
          </div>
        </div>
      </div>
    </>
  );
}; 